import { useRef, useState } from 'react';

import dictService from '@stores/dictStore/dictStore.service';
import { OkpoModel } from '@stores/organizationsStore/organizationsStoreData';

export const useOkpos = (debounceDelay = 200) => {
  const [loading, setLoading] = useState<boolean>(true);
  const [data, setData] = useState<OkpoModel[]>([]);
  const timeout = useRef<any>();

  const updateFilter = (value: string) => {
    clearTimeout(timeout.current);
    timeout.current = setTimeout(
      () =>
        dictService
          .okpoDict(value)
          .then((value) => {
            if (!!value) setData(value);
          })
          .finally(() => setLoading(false)),
      debounceDelay,
    );

    setLoading(true);
  };

  return { data, loading, setFilter: updateFilter };
};
