import { Card, Col, Row, Spin } from 'antd';
import CheckableTag from 'antd/lib/tag/CheckableTag';
import { observer } from 'mobx-react';
import moment from 'moment';
import React, { Component } from 'react';
import { Link, RouteComponentProps } from 'react-router-dom';

import AdaptiveCard from '@components/fw/AdaptiveElements/AdaptiveCard';
import AdaptivePageHeader from '@components/fw/AdaptiveElements/AdaptivePageHeader';
import ScrolledContainer from '@components/fw/ScrolledContainer';
import DispatcherItemCard from '@components/routed/DispatcherPage/DispatcherItemCard';

import { FilterContentModel } from '@stores/FilterStore/filterStore';
import userReportsfilterStoreService from '@stores/FilterStore/userReportsfilterStore.service';
import userStore from '@stores/userStore';

interface AnalyticsState {
  isBusy: boolean;
  wagonFilter: FilterContentModel[];
  containerFilter: FilterContentModel[];
}

class Analytics extends Component<RouteComponentProps, AnalyticsState> {
  constructor(props: RouteComponentProps) {
    super(props);
    this.state = {
      isBusy: false,
      wagonFilter: [],
      containerFilter: [],
    };
  }

  componentDidMount() {
    this.setState({ isBusy: true });
    Promise.all([userReportsfilterStoreService.getList('itinerary_wagons'), userReportsfilterStoreService.getList('itinerary_containers')])
      .then((ans) => {
        this.setState({ wagonFilter: ans[0], containerFilter: ans[1] });
      })
      .finally(() => this.setState({ isBusy: false }));
  }

  render() {
    document.title = 'Аналитика';
    const { isBusy } = this.state;

    return (
      <>
        <AdaptivePageHeader title={'Аналитика'} />
        <ScrolledContainer style={{ height: 'calc(100vh - var(--header-height))' }}>
          <Spin spinning={isBusy}>
            <AdaptiveCard>
              <Card
                hoverable={false}
                title={<span className={'chapter'}>Вагоны</span>}
                headStyle={{ borderBottom: 'none' }}
                style={{ border: 'none' }}>
                <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
                  <Col xs={24} sm={24} md={12} xl={8}>
                    <DispatcherItemCard
                      history={this.props.history}
                      link={'/dispatcher/itineraries/wagons'}
                      name={'Рейсы'}
                      date={moment().subtract(Math.random() * 30, 'minute')}
                      body={this.state.wagonFilter.map((filter) => {
                        return (
                          <CheckableTag onClick={(e) => e.stopPropagation()} className={'filtertag'} key={filter.filterId} checked>
                            <Link to={`/dispatcher/itineraries/wagons?filterId=${filter.filterId}`} key={filter.filterId}>
                              {filter.name}
                            </Link>
                          </CheckableTag>
                        );
                      })}
                    />
                  </Col>
                  {false && (
                    <Col xs={24} sm={24} md={12} xl={8}>
                      <DispatcherItemCard
                        history={this.props.history}
                        link={'/dispatcher/itineraries-econ/wagons'}
                        name={'Экономика рейсов'}
                        date={moment().subtract(Math.random() * 30, 'minute')}
                      />
                    </Col>
                  )}
                  <Col xs={24} sm={24} md={12} xl={8}>
                    <DispatcherItemCard
                      history={this.props.history}
                      link={'/dispatcher/hold/wagons'}
                      name={'Простои по операциям'}
                      date={moment().subtract(Math.random() * 30, 'minute')}
                    />
                  </Col>
                  {userStore.canAccess('dispatcher/bros/wagons') && (
                    <Col xs={24} sm={24} md={12} xl={8}>
                      <DispatcherItemCard
                        history={this.props.history}
                        link={'/dispatcher/bros/wagons'}
                        name={'Брошенные'}
                        date={moment().subtract(Math.random() * 30, 'minute')}
                      />
                    </Col>
                  )}
                </Row>
              </Card>
              <Card
                hoverable={false}
                title={<span className={'chapter'}>Контейнеры</span>}
                headStyle={{ borderBottom: 'none' }}
                style={{ border: 'none' }}>
                <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
                  <Col xs={24} sm={24} md={12} xl={8}>
                    <DispatcherItemCard
                      history={this.props.history}
                      link={'/dispatcher/itineraries/containers'}
                      name={'Рейсы'}
                      date={moment().subtract(Math.random() * 30, 'minute')}
                      body={this.state.containerFilter.map((filter) => {
                        return (
                          <CheckableTag onClick={(e) => e.stopPropagation()} className={'filtertag'} key={filter.filterId} checked>
                            <Link to={`/dispatcher/itineraries/containers?filterId=${filter.filterId}`} key={filter.filterId}>
                              {filter.name}
                            </Link>
                          </CheckableTag>
                        );
                      })}
                    />
                  </Col>
                  {false && (
                    <Col xs={24} sm={24} md={12} xl={8}>
                      <DispatcherItemCard
                        history={this.props.history}
                        link={'/dispatcher/itineraries-econ/containers'}
                        name={'Экономика рейсов'}
                        date={moment().subtract(Math.random() * 30, 'minute')}
                      />
                    </Col>
                  )}
                  <Col xs={24} sm={24} md={12} xl={8}>
                    <DispatcherItemCard
                      history={this.props.history}
                      link={'/dispatcher/hold/containers'}
                      name={'Простои по операциям'}
                      date={moment().subtract(Math.random() * 30, 'minute')}
                    />
                  </Col>
                </Row>
              </Card>
            </AdaptiveCard>
          </Spin>
        </ScrolledContainer>
      </>
    );
  }
}

export default observer(Analytics);
