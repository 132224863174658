import { action, makeObservable, observable } from 'mobx';

import { BillingProfile } from '../companiesStore/companyData';
import userStore from '../userStore/userStore';
import { CompanyNds, CountryModel, WagonOrContainer } from './CompanyData';
import service from './companyStore.service';

class CompanyStore {
  @observable isBusy: boolean = false;
  @observable countries: CountryModel[] = [];
  @observable billingProfiles: BillingProfile[] = [];
  @observable nds: CompanyNds = new CompanyNds();

  constructor() {
    makeObservable(this);
  }

  @action async getCountries(type: WagonOrContainer, billingProfileId?: string) {
    if (!userStore.userData || !userStore.userData.companyId) return;
    this.isBusy = true;
    await service
      .getCountries(type, billingProfileId)
      .finally(() => (this.isBusy = false))
      .then((result) => {
        this.countries = result;
      });
  }
  @action async loadBillingProfiles(companyId: string) {
    return await service.getBillingProfiles(companyId).then((ans) => {
      this.billingProfiles = ans;
      return ans;
    });
  }

  @action
  async getNdsTypes(companyId: string) {
    if (!companyId) return;

    return await service.getNdsTypes(companyId).then((data) => {
      this.nds.set(data);
      return data;
    });
  }
}

export default new CompanyStore();
