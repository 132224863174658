import { Form } from 'antd';
import React from 'react';

import OptionsSizeOverage from '@components/routed/OptionsPage/OptionsSizeOverage';

import styles from '../field.module.scss';
import { FieldOverageSelectProps } from './field-overage-select.types';

export const FieldOverageSelect = ({ name, ...props }: FieldOverageSelectProps) => {
  return (
    <Form.Item className={styles.fieldFlexAuto} name={name}>
      <OptionsSizeOverage {...props} />
    </Form.Item>
  );
};
