import { CompanyNdsType } from '@stores/companyStore/CompanyData';

export type SupplierBidRegistryDirection = {
  supplierBidRegistryId: string;
  supplierBidDirectionId: string;
  loadStationCode: number;
  loadStationName: string;
  destStationCode: number;
  destStationName: string;
  freightEtsngCode: string;
  freightEtsngName: string;
  priceWithoutNds: number;
  wagonModel: string;
};

export type SupplierBidRegistryDto = {
  supplierBidRegistryId: string;
  state?: SupplierBidRegistryState;
  companyId: string;
  organizationId: string;
  contractId: string;
  serviceType: SupplierBidRegistryServiceType;
  bidKind?: SupplierBidRegistryKind;
  bidTeuType?: SupplierBidRegistryTeuType;
  dateBegin: Date;
  dateEnd: Date;
  rps: string;
  wagonProps: string[];
  wagonModels: string[];
  wagonVolumeMax?: number;
  wagonVolumeMin?: number;
  wagonLoadMax?: number;
  wagonLoadMin?: number;
  oversize: string;
  loadStationCode?: number;
  created: Date;
  creatorId: string;
  executorId: string;
  executorOrganizationId: string;
  loadTariffIcId?: SupplierBidRegistryTariffIc;
  emptyTariffIcId?: SupplierBidRegistryTariffIc;
  wagonGroupCode?: number;
  wagonTypeCode?: number;
  freightWeight?: number;
  wagonCount?: number;
  volume?: number;
  isHalfWagonInnovative?: boolean;
  affiliationCode?: number;
  paidCountryCodes?: number[];
  ndsType?: CompanyNdsType;
  extraServices?: number[];
  loadNormDays?: number;
  destNormDays?: number;
  doubleOperationNormDays?: number;
  comment: string;
} & { validity: [Date, Date] };

export enum SupplierBidRegistryState {
  draft = 'draft',
  onagr = 'onagr',
  agr = 'agr',
}

export enum SupplierBidRegistryKind {
  itinerary = 'itinerary',
  time = 'time',
}

export enum SupplierBidRegistryTeuType {
  ps = 'ps',
  ton = 'ton',
}

export enum SupplierBidRegistryServiceType {
  wagons = 'wagons',
  expedition = 'expedition',
  wagons_expedition = 'wagons_expedition',
}

export enum SupplierBidRegistryTariffIc {
  client = 'client',
  forwar = 'forwar',
}

export type SortedDirections = Record<string, SupplierBidRegistryDirection[]>;
