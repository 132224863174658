import { Form, notification } from 'antd';
import { useWatch } from 'antd/es/form/Form';
import { isEmpty } from 'lodash';
import { observer } from 'mobx-react';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';

import { Flex } from '@components/fw/Flex';
import FreightList from '@components/routed/ClaimInfo/main/FreightList';

import { SupplierBidRegistryDto, SupplierBidRegistryServiceType, supplierBidRegistryStore } from '@stores/supplierBidRegistryStore';
import userStore from '@stores/userStore';
import usersStore from '@stores/usersStore/usersStore';

import { showErrorNotify } from '@utils/notify';

import { BidKind, BidTeuType } from '../../supplier-bid-registry-create-page.types';
import { BasicTabBasicsFields as BasicFields } from '../basic-tab-basics-fields';
import { BasicTabRollingStockFields as RollingStockFields } from '../basic-tab-rolling-stock-fields';
import { BasicTabTariffFields as TariffFields } from '../basic-tab-tariff-fields';
import { BasicTabTransportationParametersFields as TransportationParametersFields } from '../basic-tab-transportation-parameters-fields';
import { BasicTabProps as Props } from './basic-tab.types';

export const SupplierBidRegistryCreateBasicTab = observer(({ form }: Props) => {
  const history = useHistory();
  const [isShowRollingStockFields, setIsShowRollingStockFields] = useState<boolean>(false);
  const [isShowPaymentTariffFields, setIsPaymentShowTariffFields] = useState<boolean>(false);

  const { supplierBidRegistry } = supplierBidRegistryStore;

  const serviceType = useWatch('serviceType', form);

  useEffect(() => {
    if (serviceType === SupplierBidRegistryServiceType.expedition) {
      setIsShowRollingStockFields(false);
      setIsPaymentShowTariffFields(false);
    }

    if (serviceType === SupplierBidRegistryServiceType.wagons_expedition) {
      setIsShowRollingStockFields(true);
      setIsPaymentShowTariffFields(true);
    }

    if (serviceType === SupplierBidRegistryServiceType.wagons) {
      setIsShowRollingStockFields(true);
      setIsPaymentShowTariffFields(false);
    }

    if (!serviceType) {
      setIsShowRollingStockFields(false);
      setIsPaymentShowTariffFields(false);
    }
  }, [serviceType]);

  const onFinish = async (values: SupplierBidRegistryDto) => {
    try {
      const body = { ...values, dateBegin: values.validity[0], dateEnd: values.validity[1] };
      delete body.validity;

      if (!isEmpty(supplierBidRegistry)) {
        await supplierBidRegistryStore.updateSupplierBidRegistry({
          ...body,
          supplierBidRegistryId: supplierBidRegistry.supplierBidRegistryId,
        });
        supplierBidRegistryStore.supplierBidRegistry.ndsType = values.ndsType;
        return notification.success({ message: 'Ставка успешно обновлена' });
      }

      const id = await supplierBidRegistryStore.createSupplierBidRegistry(body);
      history.push(`/supplier-list-of-rates/edit/${id}`);
      await supplierBidRegistryStore.getSupplierBidRegistryById(id);
      await supplierBidRegistryStore.getDirections(id);
      notification.success({ message: 'Ставка успешно создана' });
    } catch (e: any) {
      showErrorNotify('Ошибка создания ставки', e.message);
    }
  };

  const initialValues = {
    freightList: [{ gng: '', etsng: '' }],
    bidKind: BidKind.itinerary,
    bidTeuType: BidTeuType.ps,
    ...supplierBidRegistry,
    wagonProps: supplierBidRegistry.wagonProps ?? [],
    wagonModels: supplierBidRegistry.wagonModels ?? [],
    extraServices: supplierBidRegistry.extraServices ?? [],
    executorId: userStore.userData.companyId ? userStore.userData.userId : null,
    validity: [
      supplierBidRegistry.dateBegin ? moment(supplierBidRegistry.dateBegin) : moment(),
      supplierBidRegistry.dateEnd ? moment(supplierBidRegistry.dateEnd) : moment().endOf('month'),
    ],
  };

  return (
    <Form form={form} initialValues={initialValues} scrollToFirstError={true} onFinish={onFinish}>
      <Flex gap="var(--gap)" wrap="wrap" vertical>
        <BasicFields form={form} users={usersStore.users} />
        <RollingStockFields form={form} isShow={isShowRollingStockFields} isEdit={!isEmpty(supplierBidRegistry)} />
        <TransportationParametersFields disabled={!isEmpty(supplierBidRegistry)} />
        <h4>Груз</h4>
        <FreightList
          primitiveResult={true}
          arrayName="freightList"
          gngName="gng"
          etsngName="etsng"
          form={form}
          disabled={!isEmpty(supplierBidRegistry)}
        />
        <TariffFields isShow={isShowPaymentTariffFields} />
      </Flex>
    </Form>
  );
});
