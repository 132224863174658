import { Spin } from 'antd';
import { observer } from 'mobx-react';
import React, { Component } from 'react';

import Station from '@components/fw/GetFullInfoByCode/Station';

import dictService from '@stores/dictStore/dictStore.service';
import optionsStore from '@stores/optionsStore';

interface TartiffRequestParametersProps {}

interface TartiffRequestParametersState {
  isBusy: boolean;
  etsngCodeInitDict: any[];
  gngCodeInitDict: any[];
}

class TariffRequestParameters extends Component<TartiffRequestParametersProps, TartiffRequestParametersState> {
  constructor(props: TartiffRequestParametersProps) {
    super(props);

    this.state = {
      isBusy: true,
      etsngCodeInitDict: [],
      gngCodeInitDict: [],
    };
  }

  async componentDidMount() {
    this.setState({ isBusy: true });
    const r = optionsStore.request;
    if (r && r.loadStationCode && r.destStationCode && r.freightEtsngCode) {
      Promise.all([this.loadEtsng(r.freightEtsngCode), r.freightGngCode ? this.loadGng(r.freightGngCode, r.freightEtsngCode) : null])
        .then()
        .finally(() => this.setState({ isBusy: false }));
    } else {
      this.setState({ isBusy: false });
    }
  }

  async loadEtsng(etsngCode) {
    if (etsngCode) {
      this.setState({ etsngCodeInitDict: await dictService.freightDict(etsngCode.toString()) });
    }
  }

  async loadGng(gngCode, etsngCode) {
    if (gngCode)
      this.setState({
        gngCodeInitDict: await dictService.getFreightGngDictionary(gngCode.toString(), etsngCode.toString()),
      });
  }

  render() {
    const r = optionsStore.request;
    const response = optionsStore.result;
    const { isBusy, etsngCodeInitDict, gngCodeInitDict } = this.state;
    //const borderStationInitDict = optionsStore.borderStationInitDict;
    const borderStations = r.tariffData ? r.tariffData.borderStationCodes : [];

    const etsngName = etsngCodeInitDict.length > 0 ? etsngCodeInitDict[0].fullName : null;
    const gngName = gngCodeInitDict.length > 0 ? gngCodeInitDict[0].fullName : null;
    const wagonTypeGroupName = optionsStore.wagonTypeGroupDictionary.find((d) => d.groupCode == r.wagonTypeGroupCode);
    const wagonTypeName = optionsStore.wagonTypesDictionary.find((d) => d.wagonTypeCode == r.wagonTypeCode);
    const directPath = response.countryDetails.filter((c) => !c.isEmpty);

    return (
      <Spin spinning={isBusy}>
        <div style={{ padding: '1rem' }}>
          <h4>Параметры расчета</h4>
          <div style={{ overflow: 'auto' }}>
            <div className="tariffCalculationTable parameters">
              <div>
                <div>Ст. отправления</div>
                <div>Ст. назначения</div>
                <div>Вид отправки</div>
                <div>ЕТСНГ</div>
                {r.freightGngCode && <div>ГНГ</div>}
                <div>Вес Груза</div>
                <div>Род ПС</div>
                <div>Принадлежность</div>
                <div>Грузоподъемость</div>
              </div>
              <div>
                <div>
                  <Station view={'code'} code={r.loadStationCode.toString()} />
                </div>
                <div>
                  <Station view={'code'} code={r.destStationCode.toString()} />
                </div>
                <div>{r.scaleShippingTypeCode}</div>
                <div>{r.freightEtsngCode}</div>
                {r.freightGngCode && <div>{r.freightGngCode}</div>}
                <div>{r.freightWeight}</div>
                <div>{r.wagonTypeGroupCode}</div>
                <div>{r.wagonAffiliationTypeCode}</div>
                <div>{r.freightCapacity ? r.freightCapacity : '--'}</div>
              </div>
              <div>
                <div>{response.loadStationName}</div>
                <div>{response.destStationName}</div>
                <div>
                  {r.scaleShippingTypeCode
                    ? optionsStore.scaleShippingType.find((t) => t.shippingTypeCode == r.scaleShippingTypeCode).shippingTypeName
                    : '---'}
                </div>
                <div>{etsngName}</div>
                {r.freightGngCode && <div>{gngName}</div>}
                <div></div>
                <div>
                  {wagonTypeGroupName ? wagonTypeGroupName.groupName : '---'}, {wagonTypeName ? wagonTypeName.wagonTypeName : '---'}
                </div>
                <div>
                  {r.wagonAffiliationTypeCode
                    ? optionsStore.wagonAffiliationTypeDictionary.find((d) => d.affiliationCode == r.wagonAffiliationTypeCode)
                        .affiliationName
                    : '---'}
                </div>
                <div></div>
              </div>
            </div>
          </div>
          <h4>Маршрут следования</h4>
          {!!borderStations && borderStations.length ? <span>Проложен через:</span> : null}
          {!!borderStations &&
            borderStations.map((item) => (
              <span key={item.stationCode}>
                <span style={{ color: 'lightGray' }}> {item.stationCodeCheckSum.toString()} </span>
                {item.fullName ? item.fullName : item.stationName}
                <span style={{ color: 'sandybrown' }}> {item['roadShortName']}</span>
                <span style={{ color: 'sandybrown' }}> {item['countryName']}</span>
                <span>;</span>
              </span>
            ))}
          <div style={{ overflow: 'auto' }}>
            <div className={'way'}>
              <div>Страна</div>
              <div>Код станции{`\n`}отправления</div>
              <div>Наименов станции{`\n`}отправления</div>
              <div>Дорога станции{`\n`}отправления</div>
              <div>Код станции{`\n`}назначения</div>
              <div>Наименов станции{`\n`}назначения</div>
              <div>Дорога станции{`\n`}назначения</div>
              <div>Расстояние</div>
              {directPath.map((p) => {
                return (
                  <React.Fragment key={p.countryName}>
                    <div>{p.countryName}</div>
                    <div>
                      <Station view={'code'} code={p.importStationCode.toString()} />
                    </div>
                    <div>{p.importStationName}</div>
                    <div>{p.importStationRoadName}</div>
                    <div>
                      <Station view={'code'} code={p.exportStationCode.toString()} />
                    </div>
                    <div>{p.exportStationName}</div>
                    <div>{p.exportStationRoadName}</div>
                    <div>{p.tariffDistance}</div>
                  </React.Fragment>
                );
              })}
            </div>
          </div>
        </div>
      </Spin>
    );
  }
}

export default observer(TariffRequestParameters);
