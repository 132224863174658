import { Select, SelectProps } from 'antd';
import React, { Component } from 'react';

import designStore from '@stores/designStore';
import { InputPosition } from '@stores/designStore/designData';

import BorderWrapper from './BorderWrapper';
import styles from './FloatLabel.module.scss';

interface State {
  focus: boolean;
  id: string;
}

export interface FloatSelectProps extends SelectProps {
  position?: InputPosition;
  randomId?: boolean;
}

class FloatSelect extends Component<FloatSelectProps, State> {
  constructor(props: FloatSelectProps) {
    super(props);

    this.state = {
      focus: false,
      id: 'Select-' + Math.random() + Math.random(),
    };
  }

  componentDidMount() {
    this.setState({ id: 'Select-' + Math.random() + Math.random() });
  }

  render() {
    const props = GET_REDUCED_PROPS(this.props);
    const { value, placeholder, position, randomId = true } = this.props;
    const isMultiple = ['multiple', 'tag'].includes(this.props.mode);
    const isStringLabel = typeof this.props.placeholder === 'string';
    const id = randomId ? this.state.id : this.props.id;
    const labelClass = this.state.focus || value === 0 || (value && (!isMultiple || value.length)) ? styles.labelfloat : styles.label;

    return (
      <BorderWrapper
        disabled={this.props.disabled}
        className={styles.floatlabel}
        position={position}
        height={isMultiple ? 'adaptive' : null}
        onFocusChange={(e) => this.setState({ focus: e })}>
        <Select
          {...props}
          id={id}
          onFocus={() => {
            if (designStore.isMobile) {
              const el = document.getElementById(id);
              if (el) {
                el.scrollIntoView();
              }
            }
          }}
          bordered={false}
          placeholder={null}
          placement={'bottomLeft'}
          className={styles.select}
          size={'large'}
          getPopupContainer={(trigger) => trigger.parentElement}>
          {this.props.children}
        </Select>
        <span className={labelClass} style={{ pointerEvents: isStringLabel ? 'none' : null }}>
          {placeholder}
        </span>
      </BorderWrapper>
    );
  }
}
export default FloatSelect;

const GET_REDUCED_PROPS = (props: FloatSelectProps) => {
  if (!props) return {};
  return Object.entries(props).reduce((prev, [key, value]) => {
    if (key !== 'randomId') prev[key] = value;
    return prev;
  }, {} as FloatSelectProps);
};
