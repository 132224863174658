import { Modal } from 'antd';
import DataGrid, { Column, Export, FilterRow, GroupPanel, Grouping, HeaderFilter, Pager, Paging } from 'devextreme-react/data-grid';
import { dxToolbarOptions } from 'devextreme/ui/toolbar';
import { observer } from 'mobx-react';
import React, { useRef } from 'react';
import { useHistory } from 'react-router-dom';

import AdaptiveCard from '@components/fw/AdaptiveElements/AdaptiveCard';
import { INDEX_COLUMN } from '@components/routed/DispatcherReport/parts/DispetcherReportParts';

import { registerOfBetsStore } from '@stores/registerOfBetsStore';

import { toolbarPreparing } from './lib/toolbar-preparing';
import { RegisterOfBetsTableProps } from './types/table-props';

export const RegisterOfBetsTable = observer(({ dataSource }: RegisterOfBetsTableProps) => {
  const dataGridRef = useRef<DataGrid>(null);

  const history = useHistory();

  const onExporting = () => {};

  const onContextMenuPreparing = (e) => {
    // TODO: Добавить access
    // if (!userStore.canAccess('claims/edit')) return;
    if (e.target === 'content') {
      if (e.row && e.row.rowType === 'data') {
        const row = e.row.data;
        if (!e.items) e.items = [];
        e.items.push(
          {
            text: 'Изменить',
            onClick: () => {
              history.push(`/list-of-rates/edit/${row.bidregistryid}`);
            },
          },
          {
            text: 'Удалить',
            onClick: () => {
              const modalInstance = Modal.confirm({
                title: 'Удаление ставки',
                content: 'Уверены, что хотите удалить ставку?',
                centered: true,
                onOk: () => {
                  modalInstance.destroy();
                  registerOfBetsStore.deleterBidRegistry(row.bidregistryid).then(() => dataSource.reload());
                },
                onCancel: () => modalInstance.destroy(),
              });
            },
          },
        );
      }
    }
  };

  const onRestore = () => {
    dataGridRef.current.instance.clearFilter();
    dataGridRef.current.instance.clearSorting();
    dataGridRef.current.instance.clearSelection();
    dataGridRef.current.instance.clearGrouping();
  };

  const onRefresh = () => dataSource.reload();

  const onToolbarPreparing = (e: { toolbarOptions?: dxToolbarOptions }) => toolbarPreparing(onRestore, onRefresh, e);

  return (
    <AdaptiveCard>
      <DataGrid
        ref={dataGridRef}
        allowColumnReordering={true}
        dataSource={dataSource}
        height={TABLE_HEIGHT}
        onContextMenuPreparing={onContextMenuPreparing}
        onToolbarPreparing={onToolbarPreparing}
        onExporting={onExporting}
        selection={{ mode: 'single' }}
        allowColumnResizing={true}
        remoteOperations={true}
        showBorders={true}
        columnResizingMode={'widget'}
        columnAutoWidth={true}
        showColumnLines={true}
        columnMinWidth={30}
        showRowLines={true}
        rowAlternationEnabled={true}>
        <Export enabled={true} excelWrapTextEnabled={true} />
        <FilterRow visible={true} />
        <HeaderFilter visible={true} />
        <GroupPanel visible={true} />
        <Grouping autoExpandAll={false} />
        <Paging enabled={true} defaultPageSize={25} />
        <Pager
          allowedPageSizes={[25, 50, 100]}
          showPageSizeSelector={true}
          showNavigationButtons={true}
          showInfo={true}
          infoText={'Всего: {2}'}
        />
        {INDEX_COLUMN}
        <Column
          allowFixing={true}
          defaultSortIndex={0}
          defaultSortOrder={'desc'}
          dataField={'createdate'}
          caption={'Дата создания ставки'}
          dataType={'date'}
        />
        <Column allowFixing={true} dataField={'state'} caption={'Статус'} dataType={'string'} />
        <Column allowFixing={true} dataField={'protocolnumber'} caption={'Номер протокола'} dataType={'string'} />
        <Column allowFixing={true} dataField={'orgname'} caption={'Организация'} dataType={'string'} />
        <Column allowFixing={true} dataField={'contarctnumber'} caption={'Договор'} dataType={'string'} />
        <Column allowFixing={true} dataField={'bidservicetype'} caption={'Услуга'} dataType={'string'} />
        <Column allowFixing={true} dataField={'bidkind'} caption={'Вид ставки'} dataType={'string'} />
        <Column allowFixing={true} dataField={'bidtype'} caption={'Тип ставки'} dataType={'string'} />
        <Column allowFixing={true} dataField={'datebegin'} caption={'Период действия ставки ОТ'} dataType={'date'} />
        <Column allowFixing={true} dataField={'dateend'} caption={'Период действия ставки ДО'} dataType={'date'} />
        <Column allowFixing={true} dataField={'bidrps'} caption={'Наименование РПС'} dataType={'string'} />
        <Column allowFixing={true} dataField={'loadstationname'} caption={'Станция отправления (ТР)'} dataType={'string'} />
        <Column allowFixing={true} dataField={'deststationnames'} caption={'Станция / назначения (ТР)'} dataType={'string'} />
        <Column allowFixing={true} dataField={'etsngnames'} caption={'Груз (ЕТСНГ)'} dataType={'string'} />
        <Column allowFixing={true} dataField={'bidwitoutvat'} caption={'Ставка без НДС'} dataType={'string'} />
        <Column allowFixing={true} dataField={'bidvat'} caption={'НДС'} dataType={'string'} />
        <Column allowFixing={true} dataField={'bidwitvat'} caption={'Ставка с НДС'} dataType={'string'} />
      </DataGrid>
    </AdaptiveCard>
  );
});

const TABLE_HEIGHT = 'calc(100vh - var(--padding))';
