import { Checkbox, Col, Form, Row, Select } from 'antd';
import { FormInstance } from 'antd/es/form';
import React, { Component } from 'react';

import FloatInputNumber from '@components/fw/FloatLabel/FloatInputNumber';
import FloatSelect from '@components/fw/FloatLabel/FloatSelect';

import dictStore from '@stores/dictStore/dictStore';
import { TariffEscortJsonModel } from '@stores/historyTariffInfo/historyTariffInfoStore';

interface OptionsEscortState {
  visible: boolean;
  initialValues;
}

interface OptionsEscortProps {
  disabled?: boolean;
  value?: TariffEscortJsonModel[];
  onChange?(e: TariffEscortJsonModel[]): void;
}

export const escortType = [
  { type: 'conductor', name: 'Проводник' },
  { type: 'heaterWagon', name: 'Теплушка' },
  { type: 'passengerWagon', name: 'Пассажирский (для проводников)' },
  { type: 'coverWagon', name: 'Вагон прикрытия' },
];

export const countries = [
  { name: 'Узбекистан', code: '09' },
  { name: 'Россия', code: '01' },
  { name: 'Литва', code: '12' },
  { name: 'Таджикистан', code: '16' },
  { name: 'Беларусь', code: '02' },
  { name: 'Грузия', code: '06' },
  { name: 'Азербайджан', code: '07' },
  { name: 'Украина', code: '03' },
  { name: 'Армения', code: '08' },
  { name: 'Молдавия', code: '05' },
  { name: 'Эстония', code: '14' },
  { name: 'Казахстан', code: '04' },
  { name: 'Киргизия', code: '11' },
  { name: 'Латвия', code: '13' },
  { name: 'Туркменистан', code: '10' },
];

class OptionsEscortEditor extends Component<OptionsEscortProps, OptionsEscortState> {
  formRef: FormInstance;

  constructor(props: OptionsEscortProps) {
    super(props);

    this.state = {
      visible: false,
      initialValues: this.initValues,
    };
  }

  componentDidMount() {
    if (this.props.value && this.props.value.length) {
      this.setState({ visible: true, initialValues: this.initValues }, () => {
        setTimeout(() => this.forceUpdate(), 100);
      });
    }
  }

  get initValues() {
    const initialValues = {};
    if (this.props.value) {
      escortType.forEach((t) => {
        const find = this.props.value.find((e) => e.type === t.type);
        if (find) {
          Object.keys(find).forEach((k) => {
            initialValues[t.type + ':' + k] = find[k];
            initialValues[t.type + ':selected'] = true;
          });
        }
      });
    }
    return initialValues;
  }

  render() {
    const visible = this.state.visible;
    const wagonAffiliationDictionary = dictStore.wagonAffiliationDictionary;
    return (
      <>
        <Checkbox style={{ marginTop: 14 }} checked={visible} onClick={this.handleOpen}>
          Сопровождение
        </Checkbox>
        {visible && (
          <Form
            layout="horizontal"
            size={'large'}
            ref={(ref) => {
              this.formRef = ref;
            }}
            name={'escort-form'}
            onFinish={(e) => this.handleConfirm(e)}
            onChange={() => this.handleFormChange()}
            initialValues={this.state.initialValues}>
            {escortType.map((t) => {
              const isShort = t.type === 'conductor';
              const selected = this.formRef && this.formRef.getFieldValue(t.type + ':selected');
              return (
                <Row key={t.type} gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
                  <Col xs={24} sm={24} md={24} lg={6} style={{ paddingBottom: '6px' }}>
                    <Form.Item name={t.type + ':selected'} valuePropName={'checked'}>
                      <Checkbox onChange={() => this.handleChangeState(t.type)}>{t.name}</Checkbox>
                    </Form.Item>
                  </Col>
                  <Col xs={24} sm={24} md={24} lg={6} style={{ paddingBottom: '6px' }}>
                    {!isShort && (
                      <Form.Item name={t.type + ':affiliationCode'} rules={[{ required: selected, message: ' ' }]}>
                        <FloatSelect placeholder={'Принадлежность'} disabled={!selected}>
                          {wagonAffiliationDictionary.map((item, index) => {
                            return (
                              <Select.Option value={item.affiliationCode} key={`wagonAffiliation_${index}`}>
                                {item.affiliationName}
                              </Select.Option>
                            );
                          })}
                        </FloatSelect>
                      </Form.Item>
                    )}
                  </Col>
                  <Col xs={24} sm={24} md={24} lg={6} style={{ paddingBottom: '6px' }}>
                    {!isShort && (
                      <Form.Item name={t.type + ':countryCode'} rules={[{ required: selected, message: ' ' }]}>
                        <FloatSelect placeholder={'Страна'} disabled={!selected}>
                          {countries.map((item, index) => {
                            return (
                              <Select.Option value={item.code} key={`countryCode${index}`}>
                                {item.name}
                              </Select.Option>
                            );
                          })}
                        </FloatSelect>
                      </Form.Item>
                    )}
                  </Col>
                  <Col xs={24} sm={24} md={24} lg={6} style={{ paddingBottom: '6px' }}>
                    {!isShort && (
                      <Form.Item name={t.type + ':axelCount'} rules={[{ required: selected, message: ' ' }]}>
                        <FloatInputNumber placeholder={'Оси'} disabled={!selected} max={10} min={2} step={1} />
                      </Form.Item>
                    )}
                  </Col>
                </Row>
              );
            })}
          </Form>
        )}
      </>
    );
  }

  handleOpen = () => {
    this.setState({ visible: !this.state.visible, initialValues: this.initValues });
    if (this.formRef) {
      this.formRef.resetFields();
    }
  };

  handelClose = () => {
    this.setState({ visible: false });
    this.formRef.resetFields();
    if (this.props.onChange) {
      this.props.onChange([]);
    }
  };

  handleFormChange = () => {
    this.formRef.submit();
  };

  handleChangeState = (type: string) => {
    if (type != 'conductor') {
      let values = this.state.initialValues;
      let resetArr = [];
      const countryCode = type + ':countryCode';
      if (!this.formRef.getFieldValue(countryCode)) {
        values[countryCode] = '01';
        resetArr.push(countryCode);
      }
      const affiliationCode = type + ':affiliationCode';
      if (!this.formRef.getFieldValue(affiliationCode)) {
        values[affiliationCode] = 0;
        resetArr.push(affiliationCode);
      }
      const axelCount = type + ':axelCount';
      if (!this.formRef.getFieldValue(axelCount)) {
        values[axelCount] = 4;
        resetArr.push(axelCount);
      }
      if (resetArr.length) {
        this.setState({ initialValues: values });
        this.formRef.resetFields(resetArr);
      } else {
        this.forceUpdate();
      }
    } else {
      this.forceUpdate();
    }
  };

  handleConfirm = (e) => {
    const keys = Object.keys(e);
    const selected = keys.filter((k) => e[k] === true).map((k) => k.split(':')[0]);
    let result: TariffEscortJsonModel[] = [];
    selected.forEach((escortType) => {
      result.push({
        countryCode: e[escortType + ':countryCode'],
        type: escortType,
        affiliationCode: e[escortType + ':affiliationCode'],
        axelCount: e[escortType + ':axelCount'],
      });
    });
    this.setState({ initialValues: e });
    if (this.props.onChange) {
      this.props.onChange(result);
    }
    //this.handelClose();
  };
}

export default OptionsEscortEditor;
