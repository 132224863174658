import { Col, Form, FormInstance, Row, Select } from 'antd';
import moment from 'moment';
import React, { Component } from 'react';

import AdaptiveCard from '@components/fw/AdaptiveElements/AdaptiveCard';
import FloatCheckbox from '@components/fw/FloatLabel/FloatCheckbox';
import FloatInputNumber from '@components/fw/FloatLabel/FloatInputNumber';
import FloatSelect from '@components/fw/FloatLabel/FloatSelect';
import RemoteSelect from '@components/fw/RemoteSelect';
import { stationWithParagraphsTemplate } from '@components/routed/OptionsPage/OptionsStationSelectorBtn';

import dictService from '@stores/dictStore/dictStore.service';
import { StationModel } from '@stores/dictStore/dictStoreData';

interface ComponentProps {
  initStationCode: string;
  form: FormInstance;
}

interface ComponentState {
  initData: StationModel[];
  loading: boolean;
}

class ContractExtRent extends Component<ComponentProps, ComponentState> {
  constructor(props: ComponentProps) {
    super(props);

    this.state = {
      initData: [],
      loading: false,
    };
  }

  componentDidMount() {
    const initStationCode = this.props.initStationCode;
    if (initStationCode) {
      this.setState({ loading: true });
      dictService
        .stationWithParagraphDict(initStationCode, moment().toISOString(true))
        .then((station) => {
          this.setState({ initData: station }, () => {
            this.setState({ loading: false });
          });
        })
        .catch(() => this.setState({ loading: false }));
    } else {
      this.setState({ initData: [] });
    }
  }

  render() {
    const type = this.props.form.getFieldValue(['rentParams', 'type']);
    return (
      <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
        <Col xs={24} lg={24}>
          <AdaptiveCard title={'Параметры аренды '}>
            <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
              <Col xs={24} sm={24} md={8}>
                <Form.Item rules={[{ required: true }]} name={['rentParams', 'type']}>
                  <FloatSelect placeholder={'Тип ПС'} onChange={() => this.forceUpdate()}>
                    <Select.Option value={'wagon'}>Вагон</Select.Option>
                    <Select.Option value={'container'}>Контейнер</Select.Option>
                  </FloatSelect>
                </Form.Item>
              </Col>
              <Col xs={24} sm={24} md={8}>
                <Form.Item name={['rentParams', 'price']}>
                  <FloatInputNumber max={99999999999} min={0} step={0.01} placeholder={'Стоимость в сутки'} />
                </Form.Item>
              </Col>
              <Col xs={24} sm={24} md={8}>
                {type !== 'container' && (
                  <Form.Item name={['rentParams', 'withExclusion']} valuePropName={'checked'}>
                    <FloatCheckbox>Исключение: нахождение ПС в НРП</FloatCheckbox>
                  </Form.Item>
                )}
              </Col>
              <Col xs={24} md={24}>
                {!this.state.loading && (
                  <Form.Item
                    rules={[{ required: true }]}
                    name={['rentParams', 'changeStationCode']}
                    fieldKey={['rentParams', 'changeStationCode']}>
                    <RemoteSelect
                      float={true}
                      initData={this.state.initData}
                      primitiveResult={true}
                      placeholder={'Станция сдачи'}
                      valueExpr={'stationCode'}
                      displayExpr={'fullName'}
                      aValueExpr={moment().toISOString(true)}
                      dataSource={dictService.stationWithParagraphDict}
                      itemTemplate={stationWithParagraphsTemplate}
                    />
                  </Form.Item>
                )}
              </Col>
            </Row>
          </AdaptiveCard>
        </Col>
      </Row>
    );
  }
}

export default ContractExtRent;
