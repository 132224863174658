import { LoadOptions } from 'devextreme/data/load_options';
import { action, computed, makeObservable, observable } from 'mobx';
import moment, { Moment } from 'moment';

import dictStore from '@stores/dictStore';
import userStore from '@stores/userStore';

import { cropCurrencyName } from './registerOfBetsStore.lib';
import service from './registerOfBetsStore.service';
import { BidRegistryDto, CurrentCurrency } from './registerOfBetsStore.types';

class RegisterOfBetsStoreClass {
  @observable private _isLoading: boolean = false;
  @observable private _reportRange: [Moment, Moment] = [moment().startOf('month'), moment().endOf('month')];
  @observable private _item: BidRegistryDto = {} as BidRegistryDto;
  @observable private _currency: CurrentCurrency | null;

  constructor() {
    makeObservable(this);
  }

  @action
  async createBidRegistry(body: BidRegistryDto) {
    this._isLoading = true;
    try {
      return await service.createBidRegistry(body);
    } catch (e) {
      throw e;
    } finally {
      this._isLoading = false;
    }
  }

  @action
  async updateBidRegistry(body: BidRegistryDto) {
    this._isLoading = true;
    try {
      await service.updateBidRegistry(body);
    } catch (e) {
      throw e;
    } finally {
      this._isLoading = false;
    }
  }

  @action
  async getReport(loadOptions: LoadOptions) {
    this._isLoading = true;
    try {
      return await service.getReport(this.isoReportRange[0], this.isoReportRange[1], loadOptions);
    } catch (e) {
      throw e;
    } finally {
      this._isLoading = false;
    }
  }

  @action
  async getBidRegistryById(id: string) {
    this._isLoading = true;
    try {
      this._item = await service.getBidRegistryById(id);
    } catch (e) {
      throw e;
    } finally {
      this._isLoading = false;
    }
  }

  @action
  async changeRegistryState(state) {
    this._isLoading = true;
    try {
      await service.changeRegistryState(this.bidRegistryId, state);
      await this.getBidRegistryById(this.bidRegistryId);
    } catch (e) {
      throw e;
    } finally {
      this._isLoading = false;
    }
  }

  @action
  async deleterBidRegistry(id: string) {
    this._isLoading = true;

    try {
      await service.deleteBidRegistry(id);
    } catch (e) {
      throw e;
    } finally {
      this._isLoading = false;
    }
  }

  @action.bound
  clearBidRegistry() {
    this._item = {} as BidRegistryDto;
  }

  @action.bound
  setReportRange(range: [Moment, Moment]) {
    this._reportRange = range;
  }

  @action
  setCurrency(currencyCode: string) {
    const code = currencyCode ?? userStore.userData?.companyCurrencyCode;
    const name = dictStore.currencyList.find(({ currencyCode }) => currencyCode === code)?.currencyName ?? '';
    const shortName = cropCurrencyName(name);

    this._currency = {
      code,
      name,
      shortName,
    };
  }

  @computed
  get isLoading(): boolean {
    return this._isLoading;
  }

  @computed
  get reportRange(): [Moment, Moment] {
    return this._reportRange;
  }

  @computed
  get bidRegistry() {
    return this._item;
  }

  @computed
  get bidRegistryId() {
    return this.bidRegistry.bidRegistryId;
  }

  @computed
  get bidRegistryServiceType() {
    return this.bidRegistry.serviceType;
  }

  @computed
  get currency() {
    return this._currency;
  }

  get isoReportRange() {
    return this._reportRange.map((date) => date.toISOString());
  }
}

export const registerOfBetsStore = new RegisterOfBetsStoreClass();
