import { renderPriceWithNDS } from '@components/routed/RegisterOfBetsCreatePage/ui/directions-table/lib/columns/columns.ui';

import companyStore from '@stores/companyStore/companyStore';

export const COLUMNS = [
  {
    title: 'Маршрут',
    dataIndex: 'bidRegistryId',
    key: 'bidRegistryId',
    render: (_, { loadStationName, destStationName }) => {
      return `${loadStationName}-${destStationName}`;
    },
  },
  {
    title: 'Ставка без НДС',
    dataIndex: 'priceWithoutNds',
    key: 'priceWithoutNds',
    width: 130,
  },
  {
    title: 'НДС',
    dataIndex: 'ndsType',
    key: 'ndsType',
    render: (ndsType) => companyStore.nds.labels[ndsType],
    width: 120,
  },
  {
    title: 'Ставка с НДС',
    dataIndex: 'ndsType',
    key: 'record.nds',
    render: renderPriceWithNDS,
    width: 130,
  },
];
