import { isEmpty } from 'lodash';
import { action, computed, makeObservable, observable } from 'mobx';

import { BidRegistryServiceType } from '@components/routed/RegisterOfBetsCreatePage/ui/basic-tab-basics-fields/basic-tab-basics-fields.types';

import { TariffCalcResponse } from '@stores/optionsStore/optionsStoreData';
import { registerOfBetsStore } from '@stores/registerOfBetsStore';

import {
  flatCountryPaidTerritories,
  flatRollingStock,
  packingCountryPaidTerritories,
  packingRollingStock,
  sortByFreightEtsng,
  sortCountryPaidTerritoriesModelToTree,
  totalSums,
  totalingSums,
} from './bidRegistryCommercialStore.lib';
import service from './bidRegistryCommercialStore.service';
import { BidRegistryCommercial, ClaimCountryInteractionsTree, Tariff, TariffGroup } from './bidRegistryCommercialStore.types';

class BidRegistryCommercialStoreClass {
  @observable private _isLoading: boolean = false;
  @observable private _commercial: BidRegistryCommercial = {} as BidRegistryCommercial;
  @observable private _flatRollingStocks;
  @observable private _tariffGroup: TariffGroup[];
  @observable private _flatCountryPaidTree;
  @observable private _total: Record<string, any> = {};
  @observable private _extraServices;

  constructor() {
    makeObservable(this);
  }

  @action
  async getCommercial(bidRegistryId: string) {
    this._isLoading = true;
    try {
      this._commercial = await service.getCommercial(bidRegistryId);
      this._extraServices = { extraServices: this._commercial.extraServices };
      this._flatRollingStocks = { ...this.flatSortRollingStock };
      this._flatCountryPaidTree = flatCountryPaidTerritories(this.countryPaidTerritories, this.tariffGroup);
    } catch (e) {
      throw e;
    } finally {
      this._isLoading = false;
    }
  }

  @action
  async updateCommercial(values) {
    this._isLoading = true;
    try {
      const isWagons = registerOfBetsStore.bidRegistryServiceType === BidRegistryServiceType.wagons;
      const isExpedition = registerOfBetsStore.bidRegistryServiceType === BidRegistryServiceType.expedition;
      const isWagonsExpedition = registerOfBetsStore.bidRegistryServiceType === BidRegistryServiceType.wagons_expedition;

      let body = { ...this._commercial };

      if (isWagons) {
        const wagonProvision = packingRollingStock(values);
        body = { ...body, wagonProvision };
      }
      if (isExpedition) {
        const { extraServices, ...countryPaid } = values;
        const countryPaidTerritories = packingCountryPaidTerritories(countryPaid);
        body = { ...body, countryPaidTerritories, extraServices };
      }
      if (isWagonsExpedition) {
        const { extraServices, ...otherValues } = values;
        const wagonProvision = packingRollingStock(otherValues);
        const countryPaidTerritories = packingCountryPaidTerritories(otherValues);
        body = { ...body, countryPaidTerritories, extraServices, wagonProvision };
      }

      await service.updateCommercial(body);
    } catch (e) {
      throw e;
    } finally {
      this._isLoading = false;
    }
  }

  @computed
  get isLoading() {
    return this._isLoading;
  }

  @computed
  get commercial() {
    return this._commercial;
  }

  @computed
  get allRollingStock() {
    return this._commercial.wagonProvision;
  }

  @computed
  get sortAllRollingStock() {
    return sortByFreightEtsng(this.allRollingStock);
  }

  @computed
  get flatSortRollingStock() {
    if (isEmpty(this._commercial)) {
      return null;
    }

    return flatRollingStock(this.sortAllRollingStock);
  }

  @computed
  get flatRollingStocks() {
    return this._flatRollingStocks;
  }

  @action
  changeFieldValueFlatRollingStocks(field, value) {
    this._flatRollingStocks = { ...this._flatRollingStocks, [field]: value };
  }

  @computed
  get tariffGroup() {
    return this._commercial.tariffGroup;
  }

  set tariffGroup(tariffGroup) {
    this._commercial.tariffGroup = tariffGroup;
  }

  @computed
  get countryPaidTerritories() {
    return this._commercial.countryPaidTerritories;
  }

  @computed
  get flatCountryPaidTree() {
    return this._flatCountryPaidTree;
  }

  @computed
  get extraServices() {
    if (!this._commercial.extraServices) {
      return;
    }
    return { extraServices: this._commercial.extraServices?.map(({ serviceId, price, ndsType }) => ({ serviceId, price, ndsType })) };
  }

  @computed
  get sortCountryPaid() {
    return sortCountryPaidTerritoriesModelToTree(this.countryPaidTerritories, this.tariffGroup);
  }

  @action
  changeFieldValueFlatCountryPaidTree(field, value) {
    this._flatCountryPaidTree = { ...this._flatCountryPaidTree, [field]: value };
  }

  @action
  changeTariffDetail(tariffId: string, newTariff: TariffCalcResponse) {
    this.tariffGroup = this.tariffGroup.reduce((initialValue, tariffGroup) => {
      const index = tariffGroup.tariffList.findIndex((tariff) => tariff.userTariffRequestId === tariffId);
      if (index !== -1) {
        tariffGroup.tariffList.splice(index, 1, newTariff as unknown as Tariff);
      }

      initialValue.push(tariffGroup);
      return initialValue;
    }, []);
  }

  @action
  async totalCommercial({ extraServices, ...values }) {
    const { countries, wagons } = totalingSums(values);
    this._total.comission = totalSums(
      sortCountryPaidTerritoriesModelToTree(countries, this.tariffGroup) as ClaimCountryInteractionsTree[],
      extraServices,
      wagons,
    );
  }

  @computed
  get total() {
    return this._total;
  }

  @action.bound
  clearStore() {
    this._commercial = {} as BidRegistryCommercial;
    this._flatRollingStocks = undefined;
    this._flatCountryPaidTree = undefined;
    this._total = undefined;
  }
}

export const BidRegistryCommercialStore = new BidRegistryCommercialStoreClass();
