import Icon, { EditOutlined, PlusOutlined } from '@ant-design/icons';
import { Button, Col, Form, Input, Modal, Popconfirm, Popover, Row, Select, Spin, Tag } from 'antd';
import { FormInstance } from 'antd/es/form';
import { observer } from 'mobx-react';
import React, { Component } from 'react';

import { ReactComponent as iconQuestion } from '@assets/icons/icon-question.svg';

import CompanyCatalogEditor from '@components/fw/CompanyCatalogEditor';
import FloatInputNumber from '@components/fw/FloatLabel/FloatInputNumber';
import FloatSelect from '@components/fw/FloatLabel/FloatSelect';
import MarkEditor from '@components/fw/MarkEditor';

import companyCatalogStore from '@stores/companyCatalogStore/companyCatalogStore';
import companyStore from '@stores/companyStore/companyStore';
import designStore from '@stores/designStore/designStore';
import markStore from '@stores/markStore';
import { Container, ContainerAddRequest } from '@stores/trackingStore/container/ContainerTypes';
import trackingContainerStore from '@stores/trackingStore/container/trackingContainerStore';
import userStore from '@stores/userStore/userStore';

import { showSuccessNotify } from '@utils/notify';

interface ContainerAddProps {
  onDataReady?: () => void;
  size?: 'regular' | 'small' | 'hidden';
}

interface ContainerAddState {
  visible: boolean;
  containers: Container[];
  selectedBillingProfileId: string;
}

@observer
class ContainerAdd extends Component<ContainerAddProps, ContainerAddState> {
  formRef: FormInstance;

  constructor(props: ContainerAddProps) {
    super(props);

    this.state = {
      visible: false,
      containers: [],
      selectedBillingProfileId: null,
    };
  }

  get hasWrong() {
    return this.state.containers.find((w) => !w.isvalid);
  }

  componentDidMount(): void {}

  showModal = () => {
    this.setState({ visible: true });
    if (userStore.userData.roleCode != 'admin') {
      companyCatalogStore.getCompanyCatalogs(userStore.userData.companyId);
      markStore.getNotes(userStore.userData.companyId);
      companyStore.getCountries('container');
    }
  };

  onChangeCatalogs = (e: string[]) => {
    const find = companyCatalogStore.companyCatalogs.find((c) => e.includes(c.id) && !!c.billingProfileId);
    if (find && find.billingProfileId != this.state.selectedBillingProfileId) {
      this.setState({ selectedBillingProfileId: find.billingProfileId });
      companyStore.getCountries('wagon', find.billingProfileId).then(() => {
        this.formRef.setFieldsValue({ countryId: null });
      });
    }
    if (!find && this.state.selectedBillingProfileId != null) {
      this.setState({ selectedBillingProfileId: null });
      companyStore.getCountries('wagon').then(() => {
        this.formRef.setFieldsValue({ countryId: null });
      });
    }
  };

  handleOk = (e) => {
    this.formRef.submit();
  };

  handleCancel = (e) => {
    this.setState({
      containers: [],
      visible: false,
    });
  };

  notesRender = (group: number) => {
    const notes = markStore.notes;
    const tagRender = (props) => {
      const { label, value, closable, onClose } = props;
      const note = notes.find((n) => n.id === value);
      if (!note) return null;
      const onPreventMouseDown = (event: React.MouseEvent<HTMLSpanElement>) => {
        event.preventDefault();
        event.stopPropagation();
      };
      return (
        <Tag
          color={note.description}
          onMouseDown={onPreventMouseDown}
          closable={closable}
          onClose={onClose}
          style={{ marginRight: 3, marginTop: 10 }}>
          {note.title}
        </Tag>
      );
    };
    return (
      <Row gutter={16}>
        <Col xs={group === 1 ? 20 : 24}>
          <Form.Item name={'notes' + group} style={{ height: 52 }}>
            <FloatSelect
              mode={'multiple'}
              placeholder={'Метки' + group}
              allowClear
              optionFilterProp="children"
              tagRender={tagRender}
              filterSort={(optionA, optionB) =>
                (optionA?.label ?? '')
                  .toString()
                  .toLowerCase()
                  .localeCompare((optionB?.label ?? '').toString().toLowerCase())
              }
              filterOption={(input, option) => (option?.label ?? '').toString().toLowerCase().includes(input.toLowerCase())}>
              {notes.map((item) => {
                return (
                  <Select.Option value={item.id} label={item.title} key={item.id}>
                    <Tag color={item.description} style={{ cursor: 'pointer' }}>
                      {item.title}
                    </Tag>
                  </Select.Option>
                );
              })}
            </FloatSelect>
          </Form.Item>
        </Col>
        {group === 1 && (
          <Col xs={4}>
            <Popover
              trigger={'click'}
              overlayStyle={{ minWidth: '30rem' }}
              content={<MarkEditor companyId={userStore.userData.companyId} />}>
              <Button size={'large'} icon={<EditOutlined />} />
            </Popover>
          </Col>
        )}
      </Row>
    );
  };

  handleTexAreaChange(event) {
    event.persist();

    const containers: Container[] = [];
    const existsContainers = this.state.containers;
    let lines: string[] = event.target.value.replace(/[^a-zA-Z0-9]/g, ' ').split(' ');
    lines = lines.filter((l) => l && l.length > 7);
    lines.forEach((l) => {
      l.toUpperCase();
      let container = existsContainers.find((w) => w.number === l);
      if (!container) {
        container = new Container(l);
        container.loading = true;
        trackingContainerStore
          .checkOnTrack(l)
          .then((ans) => {
            container.isontrack = ans;
          })
          .finally(() => {
            container.loading = false;
            this.forceUpdate();
          });
        if (!containers.find((w) => w.number === l)) {
          containers.push(container);
        }
      }
    });
    this.setState({ containers: containers });
  }

  handleSubmit = (event: ContainerAddRequest) => {
    if (trackingContainerStore.isBusyAction) return;
    event.numbers = this.state.containers.map((c) => c.number);
    trackingContainerStore.add(event).then(() => {
      this.setState({ visible: false });
      this.props.onDataReady();
      showSuccessNotify('Добавление контейнеров', 'Выбранные контейнеры добавлены');
    });
  };

  render() {
    const isBusy = trackingContainerStore.isBusyAction;
    const containers = this.state.containers;
    const catalogs = companyCatalogStore.companyCatalogs;
    const countries = companyStore.countries;
    const hasWrong = this.hasWrong;
    const size = this.props.size ? this.props.size : 'regular';
    const isSmall = size == 'small';
    const isHidden = size == 'hidden';
    if (!userStore.canAccess('billing/orders/containers/edit')) return null;
    return (
      <>
        {!isHidden && (
          <Button
            onClick={this.showModal}
            icon={<PlusOutlined />}
            size={'small'}
            type={isSmall ? 'text' : 'link'}
            className={isSmall ? 'dx-button-mode-contained dx-button' : undefined}
            title={'добавить на слежение'}>
            {!isSmall && <>добавить</>}
          </Button>
        )}
        <Modal
          title="Постановка контейнеров на слежение"
          width={designStore.isMobile ? '100%' : '50%'}
          open={this.state.visible}
          onOk={this.handleOk}
          onCancel={this.handleCancel}
          destroyOnClose={true}
          footer={[
            <Button key="back" onClick={this.handleCancel}>
              Отмена
            </Button>,
            <Button key="submit" type="primary" onClick={this.handleOk}>
              Добавить
            </Button>,
          ]}>
          <Spin spinning={isBusy}>
            <Form layout={'vertical'} ref={(ref) => (this.formRef = ref)} onFinish={this.handleSubmit} hideRequiredMark>
              <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
                <Col xs={24} md={12}>
                  <Form.Item
                    label="Введите список номеров:"
                    name="list"
                    rules={[
                      { required: true, message: 'Укажите контейнеры' },
                      {
                        validator: (rule, value, callback) => {
                          if (hasWrong) {
                            return Promise.reject('Проверьте невалидные номера');
                          }
                          return Promise.resolve();
                        },
                      },
                    ]}>
                    <Input.TextArea rows={4} onChange={this.handleTexAreaChange.bind(this)} />
                  </Form.Item>
                </Col>
                <Col xs={24} md={12}>
                  {containers.map((value, index) => {
                    return (
                      <div key={index} style={!value.isvalid ? { color: 'red' } : value.isontrack ? { color: 'green' } : {}}>
                        {value.number}
                        {value.isontrack && (
                          <Popover
                            style={{ width: '200px' }}
                            trigger="hover"
                            content={
                              <div style={{ width: '200px' }}>
                                <b>{value.number}</b> уже добавлен. Изменений по нему не будет
                              </div>
                            }>
                            <Icon component={iconQuestion} style={{ cursor: 'pointer' }} />
                          </Popover>
                        )}
                      </div>
                    );
                  })}
                </Col>
                <Col xs={24} md={12}>
                  <Row gutter={16}>
                    <Col xs={20}>
                      <Form.Item name="catalogs">
                        <FloatSelect
                          mode={'multiple'}
                          placeholder="Подразделения"
                          allowClear
                          optionFilterProp="children"
                          onChange={this.onChangeCatalogs}>
                          {catalogs.map((item) => {
                            return (
                              <Select.Option value={item.id} key={item.id}>
                                {item.title}
                              </Select.Option>
                            );
                          })}
                        </FloatSelect>
                      </Form.Item>
                    </Col>
                    <Col xs={4}>
                      <Popover
                        trigger={'click'}
                        overlayStyle={{ minWidth: '30rem' }}
                        content={<CompanyCatalogEditor companyId={userStore.userData.companyId} />}>
                        <Button size={'large'} icon={<EditOutlined />} />
                      </Popover>
                    </Col>
                    <Col xs={24}>
                      <Form.Item name="countryId" rules={[{ required: true, message: 'Укажите страну дислокации' }]}>
                        <FloatSelect placeholder="Страна">
                          {countries.map((item) => {
                            return (
                              <Select.Option value={item.code} key={item.code}>
                                {item.fullName}
                              </Select.Option>
                            );
                          })}
                        </FloatSelect>
                      </Form.Item>
                    </Col>
                    <Col xs={24}>
                      <Form.Item name="trackingDayCount">
                        <FloatInputNumber placeholder="Убрать со слежения через (дней)" max={720} min={1} />
                      </Form.Item>
                    </Col>
                  </Row>
                </Col>
                <Col xs={24} md={12}>
                  <Row gutter={16}>
                    <Col xs={24}>{this.notesRender(1)}</Col>
                    <Col xs={24}>{this.notesRender(2)}</Col>
                    <Col xs={24}>{this.notesRender(3)}</Col>
                  </Row>
                </Col>
              </Row>
            </Form>
          </Spin>
        </Modal>
      </>
    );
  }
}

export default ContainerAdd;
