import { useWatch } from 'antd/es/form/Form';
import React, { useEffect, useState } from 'react';

import { FieldFloatInputNumber } from '@components/fw/Fields/float-input-number';
import { FieldFloatSelect } from '@components/fw/Fields/float-select';
import { FieldOverageSelect } from '@components/fw/Fields/overage-select';
import { Flex } from '@components/fw/Flex';

import dictService from '@stores/dictStore/dictStore.service';
import { SupplierBidRegistryServiceType } from '@stores/supplierBidRegistryStore';

import { getRpsFeatureOptions, getRpsOptions, getRpsWagonModelOptions } from './basic-tab-rolling-stock-fields.lib';
import { BasicTabRollingStockFieldsProps } from './basic-tab-rolling-stock-fields.types';

export const BasicTabRollingStockFields = ({ isShow, form, isEdit }: BasicTabRollingStockFieldsProps) => {
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [rpsList, setRpsList] = useState<string[]>([]);
  const [rpsFeatureList, setRpsFeatureList] = useState<string[]>([]);
  const [rpsWagonModelList, setRpsWagonModelList] = useState<string[]>([]);

  const rps = useWatch('rps', form);
  const wagonProps = useWatch('wagonProps', form);

  useEffect(() => {
    setIsLoading(true);

    dictService
      .getRpsDict()
      .then((res) => setRpsList(res))
      .finally(() => setIsLoading(false));
  }, []);

  useEffect(() => {
    if (rps) {
      setIsLoading(true);

      dictService
        .getRpsFeaturesDict(rps)
        .then((res) => setRpsFeatureList(res))
        .finally(() => setIsLoading(false));
    }
  }, [rps]);

  useEffect(() => {
    if (rps && wagonProps?.length) {
      setIsLoading(true);

      dictService
        .getRpsWagonModelsByFeatures(rps, wagonProps)
        .then((res) => setRpsWagonModelList(res))
        .finally(() => setIsLoading(false));
    }
  }, [wagonProps]);

  if (!isShow) {
    return (
      <Flex gap="var(--gap) 32px" wrap="wrap" justify="space-between">
        <FieldFloatSelect
          rules={[{ required: true, message: 'Это поле обязательное с этим типом услуги' }]}
          loading={isLoading}
          name="rps"
          options={getRpsOptions(rpsList)}
          placeholder="Наименование РПС"
          allowClear
          disabled={isEdit}
        />
      </Flex>
    );
  }

  return (
    <>
      <h4>Выбор подвижного состава</h4>
      <Flex gap="var(--gap) 32px" wrap="wrap" justify="space-between">
        <FieldFloatSelect
          rules={[{ required: true, message: 'Это поле обязательное с этим типом услуги' }]}
          loading={isLoading}
          name="rps"
          options={getRpsOptions(rpsList)}
          onChange={() => {
            form.setFieldValue('wagonProps', []);
            form.setFieldValue('wagonModels', []);
          }}
          placeholder="Наименование РПС"
          allowClear
          disabled={isEdit}
        />
        <FieldFloatSelect
          loading={isLoading}
          name="wagonProps"
          options={getRpsFeatureOptions(rpsFeatureList)}
          placeholder="Обобщенная характеристика"
          onChange={() => form.setFieldValue('wagonModels', [])}
          disabled={!rps || isEdit}
          mode={'multiple'}
          allowClear
        />
        <FieldFloatSelect
          loading={isLoading}
          name="wagonModels"
          options={getRpsWagonModelOptions(rpsWagonModelList)}
          mode="multiple"
          placeholder="Модель вагона"
          disabled={!rps || !wagonProps?.length || isEdit}
          allowClear
        />
        <FieldOverageSelect name="oversize" disabled={false} />
      </Flex>
      <Flex gap="var(--gap) 32px" wrap="wrap" justify="space-between">
        <FieldFloatInputNumber name="wagonLoadMin" placeholder="Грузоподъемность ОТ" />
        <FieldFloatInputNumber name="wagonLoadMax" placeholder="Грузоподъемность ДО" />
        <FieldFloatInputNumber name="wagonVolumeMin" placeholder="Объем кузова ОТ" />
        <FieldFloatInputNumber name="wagonVolumeMax" placeholder="Объем кузова ДО" />
      </Flex>
    </>
  );
};
