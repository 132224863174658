import { REPORTS } from '@/words';
import Icon, {
  AreaChartOutlined,
  AuditOutlined,
  CloseOutlined,
  FileTextOutlined,
  LogoutOutlined,
  MailOutlined,
  MenuOutlined,
  NotificationOutlined,
  ProfileOutlined,
} from '@ant-design/icons';
import { Badge, Button, Layout, Menu } from 'antd';
import SubMenu from 'antd/es/menu/SubMenu';
import { observer } from 'mobx-react';
import React, { Component } from 'react';
import ReactGA from 'react-ga';
import { Link, RouteComponentProps } from 'react-router-dom';

import { ReactComponent as admin } from '@assets/icons/admin.svg';
import { ReactComponent as billing } from '@assets/icons/billing.svg';
import { ReactComponent as claims } from '@assets/icons/claims.svg';
import { ReactComponent as container } from '@assets/icons/container.svg';
import { ReactComponent as dispatcher } from '@assets/icons/dispatcher.svg';
import { ReactComponent as documents } from '@assets/icons/documents.svg';
import { ReactComponent as messageIcon } from '@assets/icons/icon-message.svg';
import { ReactComponent as systemIcon } from '@assets/icons/icon-system.svg';
import { ReactComponent as userIcon } from '@assets/icons/icon-user.svg';
import { ReactComponent as map } from '@assets/icons/map.svg';
import { ReactComponent as options } from '@assets/icons/options.svg';
import { ReactComponent as reys } from '@assets/icons/reys.svg';
import { ReactComponent as sends } from '@assets/icons/sends.svg';
import { ReactComponent as wagon } from '@assets/icons/wagon.svg';

import ScrolledContainer from '@components/fw/ScrolledContainer';
import SupportModal from '@components/fw/SupportModal/SupportModal';
import VersionButton from '@components/fw/Version/VersionButton';

import companyTariffStore from '@stores/companyTariffStore/companyTariffStore';
import designStore from '@stores/designStore/designStore';
import routingStore from '@stores/fw/routingStore/routingStore';
import logStore from '@stores/logStore/logStore';
import messengerStore from '@stores/messengerStore/messengerStore';
import userStore from '@stores/userStore';

import { logout } from '@utils/authority';

import styles from './Sidebar.module.scss';

interface SidebarState {
  canChangeCollapsed: boolean;
}

const { Sider } = Layout;

const menus = [
  <Menu.Item key="claims">
    <Icon component={claims} />
    <span>Заявки</span>
    <Link to="/claims" />
  </Menu.Item>,
  <Menu.Item key="sends">
    <Icon component={sends} />
    <span>Отправки</span>
    <Link to="/sends" />
  </Menu.Item>,
];

@observer
class Sidebar extends Component<RouteComponentProps, SidebarState> {
  constructor(props: RouteComponentProps) {
    super(props);
    this.state = {
      canChangeCollapsed: true,
    };
  }

  gtagProcess = (e) => {
    ReactGA.event({
      category: 'Sidebar',
      action: e.key,
    });
  };

  componentDidMount() {
    companyTariffStore.loadCompanies();
  }

  setCollapsed = (e: boolean) => {
    if (!this.state.canChangeCollapsed) return;
    designStore.setSidebar(e);
    this.setState({ canChangeCollapsed: false });
    setTimeout(() => this.setState({ canChangeCollapsed: true }), 500);
  };

  render() {
    const collapsed = designStore.sidebarCollapsed;
    const path = routingStore.location.pathname;
    const pathItem = this.firstLevelUrl(path);
    const availableItems = userStore.sidebarAvailableItems;
    const unreadMessages = messengerStore.unreadMessages;
    const user = userStore.userData;
    const width = '288px';
    const isMobile = designStore.isMobile;
    const hasCompany = !!userStore.userData && !!userStore.userData.companyId;
    return (
      <Sider
        onMouseLeave={() => {
          if (isMobile) {
            this.setCollapsed(true);
          }
        }}
        zeroWidthTriggerStyle={{
          background: 'transparent',
          display: collapsed ? null : 'none',
          zIndex: 1000,
          top: 15,
          left: 10,
          position: 'absolute',
        }}
        collapsedWidth={isMobile ? 0 : 80}
        onCollapse={(e, r) => {
          if (r === 'clickTrigger') {
            this.setCollapsed(false);
          }
        }}
        breakpoint={'md'}
        onBreakpoint={(e) => {
          designStore.setSidebar(e);
          designStore.setMobile(e);
        }}
        trigger={isMobile ? <Button icon={<MenuOutlined />} /> : null}
        collapsible
        collapsed={collapsed}
        width={isMobile ? '100%' : width}
        style={{ height: 'calc(100vh - 40px)' }}>
        <div className={styles.logo}>
          <span>
            <img
              src={collapsed ? designStore.logoMin : designStore.logoMax}
              onClick={designStore.toggleSidebar}
              className={collapsed ? styles.logoMin : styles.logoMax}
            />
          </span>
          {isMobile && (
            <Button
              type={'text'}
              onClick={designStore.toggleSidebar}
              size={'large'}
              icon={<CloseOutlined />}
              style={{ position: 'absolute', top: 10, right: 10 }}
            />
          )}
        </div>
        <div className={'scrollbar'}>
          <ScrolledContainer style={{ height: 'calc(100vh - 180px)', overflow: 'none' }}>
            <Menu
              theme="dark"
              mode="inline"
              selectedKeys={pathItem}
              onClick={(e) => {
                this.gtagProcess(e);
                if (isMobile) {
                  designStore.setSidebar(true);
                }
              }}>
              <SubMenu icon={<Icon component={userIcon} />} key="admin_sub" title={user.userName}>
                {userStore.canAccess('administration') && !hasCompany && (
                  <Menu.Item icon={<Icon component={admin} />} key="administration">
                    <span>Администрирование</span>
                    <Link to="/administration" />
                  </Menu.Item>
                )}
                {userStore.canAccess('administration') && hasCompany && (
                  <Menu.Item icon={<Icon component={admin} />} key="companySettings">
                    <span>Моя компания</span>
                    <Link to="/companySettings" />
                  </Menu.Item>
                )}
                <Menu.Item icon={<Icon component={systemIcon} />} key="dashboard">
                  <span>Система</span>
                  <Link to="/dashboard/about" />
                </Menu.Item>
                {userStore.canAccess('distribution') && (
                  <Menu.Item icon={<MailOutlined />} key="distribution">
                    <span>Рассылки</span>
                    <Link to="/distribution" />
                  </Menu.Item>
                )}
                <Menu.Item key="profile" icon={<ProfileOutlined />}>
                  <span>Профиль</span>
                  <Link to={'/profile'} />
                </Menu.Item>
                <Menu.Item key="logout" onClick={this.handleExit} icon={<LogoutOutlined />}>
                  Выход
                </Menu.Item>
              </SubMenu>

              {userStore.canAccess(['main/widgets', 'main/company', 'main/shippingSendsArrival', 'main/immerse']) && (
                <Menu.Item icon={<AreaChartOutlined />} key="main">
                  Главная
                  <Link to="/main" />
                </Menu.Item>
              )}

              <Menu.Item icon={<Icon component={map} />} key="map">
                Карта
                <Link to="/map" />
              </Menu.Item>

              {this.renderMenu(availableItems)}

              {userStore.canAccess('tariff') && (
                <SubMenu icon={<Icon component={options} />} key="options_sub" title={'Расчет тарифа'}>
                  <Menu.Item key="options">
                    Тариф общий
                    <Link to="/options" />
                  </Menu.Item>
                  {userStore.userData.otherTariffEnable && userStore.canAccess('tariff/fgk') && (
                    <Menu.Item key="fgk">
                      Ставка ФГК
                      <Link to="/fgk" />
                    </Menu.Item>
                  )}
                  {userStore.userData.otherTariffEnable &&
                    companyTariffStore.tariffCompanies.map((c) => (
                      <Menu.Item key={c.companyId}>
                        Ставка {c.companyName}
                        <Link to={'/companyTariff/' + c.companyId} />
                      </Menu.Item>
                    ))}
                </SubMenu>
              )}

              {userStore.canAccess('dispatcher') && (
                <SubMenu icon={<Icon component={documents} />} key="my-reports" title={REPORTS}>
                  {userStore.canAccess('dispatcher') && (
                    <Menu.Item key="my-reports/reports">
                      <Link to="/my-reports/reports" />
                      Оперативные
                    </Menu.Item>
                  )}
                  {(userStore.canAccess('dispatcher/itineraries') || userStore.canAccess('dispatcher/bros/wagons')) && (
                    <Menu.Item key="my-reports/itineraries">
                      <Link to="/my-reports/itineraries" />
                      Аналитика
                    </Menu.Item>
                  )}
                  {userStore.canAccess('dispatcher/loadregistry') && (
                    <Menu.Item key="my-reports/loading-register">
                      <Link to="/my-reports/loading-register" />
                      Реестр погрузки
                    </Menu.Item>
                  )}
                  {userStore.canAccess('dispatcher/equipment') && (
                    <Menu.Item key="my-reports/equipment">
                      <Link to="/my-reports/equipment" />
                      Комплектация
                    </Menu.Item>
                  )}
                  {userStore.canAccess('dispatcher/loadregistry') && (
                    <Menu.Item key="my-reports/holds">
                      <Link to="/my-reports/holds" />
                      Штрафы
                    </Menu.Item>
                  )}
                </SubMenu>
              )}

              {userStore.canAccess('rent') && (
                <Menu.Item icon={<Icon component={container} />} key="leasing">
                  <span>Аренда</span>
                  <Link to="/leasing" />
                </Menu.Item>
              )}

              {userStore.canAccess(['list-of-rates', 'ils_claim', 'transport-register', 'supplier-list-of-rates']) && (
                <SubMenu icon={<Icon component={documents} />} key="commerce" title={'Коммерция'}>
                  {userStore.canAccess('list-of-rates') && (
                    <Menu.Item icon={<Icon component={AuditOutlined} />} key="list-of-rates">
                      <span>Реестр ставок</span>
                      <Link to="/list-of-rates" />
                    </Menu.Item>
                  )}
                  {userStore.canAccess('supplier-list-of-rates') && (
                    <Menu.Item icon={<Icon component={AuditOutlined} />} key="supplier-list-of-rates">
                      <span>Ставки поставщика</span>
                      <Link to="/supplier-list-of-rates" />
                    </Menu.Item>
                  )}
                  {userStore.canAccess('transport-register') && (
                    <Menu.Item icon={<Icon component={reys} />} key="transport-register">
                      <span>Реестр перевозок</span>
                      <Link to="/transport-register" />
                    </Menu.Item>
                  )}
                  {userStore.canAccess('ils_claim') && (
                    <Menu.Item icon={<FileTextOutlined />} key="ils-page">
                      Заявки на перевозки
                      <Link to="/ils-page" />
                    </Menu.Item>
                  )}{' '}
                  .
                </SubMenu>
              )}

              <Menu.Divider />

              {userStore.canAccess('requests') && (
                <Menu.Item icon={<Icon component={documents} />} key="requests">
                  <span>Справки</span>
                  <Link to="/requests" />
                </Menu.Item>
              )}

              {userStore.canAccess('etran') && (
                <SubMenu icon={<Icon component={documents} />} key="etran" title={'Этран'}>
                  {/*<Menu.Item icon={<Icon component={documents} />} key="etranCreate" title={'Создание накладных'}>
                <Link to="/etran/create-invoice" />
                <span>Создание накладной</span>
              </Menu.Item>*/}
                  <Menu.Item icon={<Icon component={documents} />} key="etranInvoice" title={'Накладные'}>
                    <Link to="/etraninvoice" />
                    <span>Накладные</span>
                  </Menu.Item>
                  <Menu.Item icon={<Icon component={documents} />} key="etranTelegram" title={'Телеграммы'}>
                    <Link to="/etrantelegram" />
                    <span>Телеграммы</span>
                  </Menu.Item>
                  <Menu.Item icon={<Icon component={documents} />} key="etranClaim" title={'Телеграммы'}>
                    <Link to="/etranclaim" />
                    <span>ГУ-12</span>
                  </Menu.Item>
                </SubMenu>
              )}

              {userStore.canAccess([
                'documents/billing',
                'finance-infolog',
                'billing/statistic',
                'billing/orders/wagons',
                'billing/orders/containers',
              ]) && (
                <SubMenu icon={<Icon component={billing} />} key="finances" title={'Финансы'}>
                  {userStore.canAccess('documents/billing') && (
                    <Menu.Item key="bank">
                      <Link to="/bank" />
                      Банк
                    </Menu.Item>
                  )}
                  {userStore.canAccess('documents/billing') && (
                    <Menu.Item key="documents/billing">
                      <Link to="/documents/billing" />
                      Покупки
                    </Menu.Item>
                  )}
                  {userStore.canAccess('documents/billing') && (
                    <Menu.Item key="documents/registry">
                      <Link to="/documents/registry" />
                      Документы
                    </Menu.Item>
                  )}
                  {userStore.canAccess('finance-infolog') && (
                    <Menu.Item key="finance">
                      <span>Инфолог</span>
                      <Link to="/finance-infolog" />
                    </Menu.Item>
                  )}
                  {userStore.canAccess('billing/statistic') && (
                    <Menu.Item icon={<Icon component={documents} />} key="billing/statistic">
                      <Link to="/billing/statistic" />
                      Статистика
                    </Menu.Item>
                  )}
                  {(userStore.canAccess('billing/orders/wagons') || userStore.canAccess('billing/orders/containers')) && (
                    <SubMenu icon={<Icon component={claims} />} key="billing/orders" title="Заказы">
                      {userStore.canAccess('billing/orders/wagons') && (
                        <Menu.Item icon={<Icon component={wagon} />} key="billing/orders/wagons">
                          <Link to="/billing/orders/wagons/active" />
                          Вагоны
                        </Menu.Item>
                      )}
                      {userStore.canAccess('billing/orders/containers') && (
                        <Menu.Item icon={<Icon component={container} />} key="billing/orders/containers">
                          <Link to="/billing/orders/containers/active" />
                          Контейнеры
                        </Menu.Item>
                      )}
                    </SubMenu>
                  )}
                </SubMenu>
              )}
              <Menu.Divider />
              {userStore.userData && userStore.userData.companyId && userStore.canAccess('notifications') && (
                <Menu.Item icon={<NotificationOutlined />} key="notifications">
                  <span>Уведомления</span>
                  <Link to="/notifications" />
                </Menu.Item>
              )}

              <Menu.Item key="messenger">
                <Icon component={messageIcon} />
                <span style={{ paddingRight: 10 }}>
                  Сообщения
                  <Badge count={unreadMessages} style={{ marginLeft: 5 }} />
                </span>
                <Link to="/messenger" />
              </Menu.Item>

              <Menu.Item key="support" className={'support'}>
                <SupportModal {...this.props} />
              </Menu.Item>
            </Menu>
          </ScrolledContainer>
        </div>
        {!(isMobile && collapsed) && <VersionButton width={isMobile ? '100%' : width} collapsed={collapsed} />}
      </Sider>
    );
  }

  private renderMenu(availableMenus: string[]) {
    return menus.map((item, index) => {
      if (availableMenus.includes(`${item.key}`)) {
        return item;
      } else {
        return null;
      }
    });
  }

  private firstLevelUrl(path: string): string[] {
    let result = [];
    let split = path.split('/');
    if (split.length > 0) result.push(split[1]);
    if (split.length > 1) result.push(split[1] + '/' + split[2]);
    if (split.length > 2) result.push(split[1] + '/' + split[2] + '/' + split[3]);
    return result;
  }

  handleExit = async () => {
    await logStore.out();
    logout();
  };
}

export default Sidebar;
