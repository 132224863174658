import { useWatch } from 'antd/es/form/Form';
import { observer } from 'mobx-react';
import { DefaultOptionType } from 'rc-select/lib/Select';
import React, { useEffect, useState } from 'react';

import styles from '@components/fw/Fields/field.module.scss';
import { FieldFloatInput } from '@components/fw/Fields/float-input';
import { FieldFloatInputNumber } from '@components/fw/Fields/float-input-number';
import { FieldFloatSelect } from '@components/fw/Fields/float-select';
import { FieldRangePicker } from '@components/fw/Fields/range-picker';
import { Flex } from '@components/fw/Flex';
import OrganizationFormItem from '@components/routed/ClaimInfo/documents/OrganizationFormItem';

import companyStore from '@stores/companyStore/companyStore';
import dictStore from '@stores/dictStore';
import organizationsStoreService from '@stores/organizationsStore/organizationsStore.service';
import { TemplateOrgContractModel } from '@stores/organizationsStore/organizationsStoreData';
import { SupplierBidRegistryKind, SupplierBidRegistryServiceType, SupplierBidRegistryTeuType } from '@stores/supplierBidRegistryStore';
import userStore from '@stores/userStore';

import { getContractOptions, getCountriesOptions, getExecutorOptions, getExtraServicesOptions } from './basic-tab-basics-fields.lib';
import { BasicTabBasicsFieldsProps } from './basic-tab-basics-fields.types';

export const BasicTabBasicsFields = observer(({ users, form }: BasicTabBasicsFieldsProps) => {
  const [isMounted, setIsMounted] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [_, setIsDisabledTeuTypes] = useState<boolean>(true);
  const [contractList, setContractList] = useState<TemplateOrgContractModel[]>([]);

  const serviceType = useWatch('serviceType', form);
  const organizationContractor = useWatch('organizationId', form);
  const contractId = useWatch('contractId', form);
  const rps = useWatch('rps', form);

  useEffect(() => setIsMounted(true), []);

  useEffect(() => {
    if (!contractId || !rps || !isMounted) return;

    organizationsStoreService.getDowntime(contractId, rps).then((downtime) => {
      if (!downtime) return;

      form.setFields([
        { name: 'doubleOperationNormDays', value: downtime.doubleOperationNormDays },
        { name: 'loadNormDays', value: downtime.loadNormDays },
        { name: 'destNormDays', value: downtime.destNormDays },
      ]);
    });
  }, [contractId, rps]);

  useEffect(() => {
    if (serviceType === SupplierBidRegistryServiceType.wagons || serviceType === SupplierBidRegistryServiceType.expedition) {
      return setIsDisabledTeuTypes(false);
    }
    setIsDisabledTeuTypes(true);
  }, [serviceType]);

  useEffect(() => {
    if (organizationContractor) {
      setIsLoading(true);
      organizationsStoreService
        .getContractList(organizationContractor, 'contract')
        .then((res) => setContractList(res))
        .finally(() => setIsLoading(false));
    }
  }, [organizationContractor]);

  return (
    <>
      <Flex gap="var(--gap) 32px" wrap="wrap">
        <OrganizationFormItem
          onChange={() => {
            form.setFieldValue('contractId', '');
          }}
          placeholder="Организация поставщика"
          float={true}
          primitiveResult={true}
          form={form}
          type="supplier"
          formItemName="organizationId"
          formItemClassName={styles.fieldFlexAuto}
          required={false}
        />
        <FieldFloatSelect
          rules={[{ required: true, message: 'Обязательное поле' }]}
          options={SERVICE_TYPES_OPTIONS}
          name="serviceType"
          placeholder="Тип услуги"
          allowClear
        />
        <OrganizationFormItem
          placeholder="Организация исполнителя"
          float={true}
          primitiveResult={true}
          form={form}
          type="branch"
          formItemName="executorOrganizationId"
          formItemClassName={styles.fieldFlexAuto}
          required={false}
        />
        <FieldFloatSelect
          disabled={!!userStore.userData.companyId}
          widthSearch={true}
          options={getExecutorOptions(users)}
          name="executorId"
          placeholder="Исполнитель"
          allowClear
        />
      </Flex>
      <Flex gap="var(--gap) 32px" wrap="wrap" justify="space-between">
        <FieldFloatSelect
          disabled={!organizationContractor}
          loading={isLoading}
          options={getContractOptions(contractList)}
          filterSort={(option) => (!option.value ? -1 : 1)}
          name="contractId"
          placeholder="№ договора, дата договора"
          allowClear
        />
        <FieldFloatSelect options={REGISTRY_KIND_OPTIONS} name="bidKind" placeholder="Вид ставки" disabled={true} />
        <FieldFloatSelect
          options={TEU_TYPES_OPTIONS}
          name="bidTeuType"
          placeholder="Тип ставки"
          disabled={true}
          rules={[{ required: true, message: 'Обязательное поле' }]}
        />
        <FieldRangePicker
          rules={[{ required: true, message: 'Обязательное поле' }]}
          name="validity"
          placeholder={['Срок действия', '']}
          format="DD.MM.YYYY"
        />
      </Flex>
      <Flex gap="var(--gap) 32px" wrap="wrap" justify="space-between">
        <FieldFloatSelect name={'ndsType'} placeholder={'Тип ндс'} options={companyStore.nds.options} />
        <FieldFloatInputNumber name={'loadNormDays'} placeholder={'Норм. под погрузкой'} title={'Норматив простоя под погрузкой'} />
        <FieldFloatInputNumber name={'destNormDays'} placeholder={'Норм. под выгрузкой'} title={'Норматив простоя под выгрузкой'} />
        <FieldFloatInputNumber
          name={'doubleOperationNormDays'}
          placeholder={'Норм. под сдвоенной операцией'}
          title={'Норматив простоя под сдвоенной операцией'}
        />
      </Flex>
      <Flex gap="var(--gap) 32px" wrap="wrap">
        {serviceType !== SupplierBidRegistryServiceType.wagons && (
          <FieldFloatSelect
            name={'paidCountryCodes'}
            placeholder={'Оплачиваемые страны'}
            mode={'multiple'}
            options={getCountriesOptions(dictStore.countriesDictionary)}
          />
        )}
        <FieldFloatSelect
          name={'extraServices'}
          placeholder={'Доп услуги'}
          mode={'multiple'}
          options={getExtraServicesOptions(dictStore.extraServicesDictionary)}
        />
        <FieldFloatInput name="comment" placeholder="Комментарий" />
      </Flex>
    </>
  );
});

const SERVICE_TYPES_OPTIONS: DefaultOptionType[] = [
  { label: 'Предоставление вагона', value: SupplierBidRegistryServiceType.wagons },
  { label: 'Экспедирование (Оплата жд тарифа)', value: SupplierBidRegistryServiceType.expedition },
  { label: 'ТЭУ(Предоставление вагона с оплатой тарифа)', value: SupplierBidRegistryServiceType.wagons_expedition },
];

const REGISTRY_KIND_OPTIONS: DefaultOptionType[] = [
  {
    label: 'На технический рейс - ставка за перевозку на фиксированном плече от станции А до станции Б',
    value: SupplierBidRegistryKind.itinerary,
  },
  { label: 'Сутки - ставка указывается  за сутки использования вагона', value: SupplierBidRegistryKind.time },
];

const TEU_TYPES_OPTIONS: DefaultOptionType[] = [
  { label: 'За единицу транспорта', value: SupplierBidRegistryTeuType.ps },
  { label: 'за тонну перевозимого груза', value: SupplierBidRegistryTeuType.ton },
];
