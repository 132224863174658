import { ClaimFreightServiceModel, ClaimWagonInfoLimitation } from '../claimsStore/ClaimData';

export interface WagonAutoSelectInfo {
  /// Вагон
  wagonNumber: number;
  /// Модель
  model: string;
  /// Объем кузова (м3)
  volume: number;
  /// Грузоподъемность (т)
  lifting: number;
  /// Срок до планового ремонта (сут)
  nextPlanRepairDays: number;
  /// Тип планового ремонта
  planRepairName: string;
  /// Станция дислокации
  dislStationName: string;
  /// НЕ ДОБАВЛЯТЬ В ТАБЛИЦУ
  destStationCode: string;
  /// Станция назначения
  destStationName: string;
  /// Дорога назначения
  destRoadName: string;
  /// Требует промывки
  needWash: boolean;
  /// Расстояние подсыла (км)
  currentToTargetDistance: number;
  /// Дата планируемого прибытия
  receivePlanDate: Date;
  /// Тариф (руб)
  commonTariff: number;
  isTariffCalculated: boolean;
  latitude: number;
  longitude: number;
  currentFreightCode: string;
}

export type SendModel = {
  sendId?: string;
  claimId: string;
  producerId: string;
  producerName: string;
  sendName: string;
  claimName: string;
  emptyReceiverId: string;
  emptyReceiverName: string;
  freightSenderOkpoId: string;
  gu12: string;
  loadStationCode: string;
  destStationCode: string;
  freightReceiverOkpoId: string;
  emptyTariffIcId: string;
  loadTariffIcId: string;
  wagonTypeCode: number;
  shippingTypeId: string;
  scaleShippingTypeId: number;
  freightEtsngCode: string;
  wagonCount: number;
  freightWeight: number;
  specialConditions: string;
  statusId: string;
  statusName: string;
  executorId: string;
  createDate: Date;
  supplyDate: Date;
  executionDate: Date;
  sendSchedules: SendSupplyScheduleModel[];
  freightGngCode: string;
  tariffTotalPrice?: number;
  tariffTotalDistance?: number;
  wagonAffiliationCode: number;

  //Вспомогательные
  wagonTypeObj?: ClaimWagonInfoLimitation;
  freightObj?: ClaimFreightServiceModel;
};

export const emptySend: SendModel = {
  claimId: null,
  producerId: null,
  producerName: null,
  sendName: null,
  claimName: null,
  emptyReceiverId: null,
  emptyReceiverName: null,
  freightSenderOkpoId: null,
  gu12: null,
  loadStationCode: null,
  destStationCode: null,
  freightReceiverOkpoId: null,
  emptyTariffIcId: null,
  loadTariffIcId: null,
  wagonTypeCode: null,
  shippingTypeId: null,
  scaleShippingTypeId: null,
  freightEtsngCode: null,
  wagonCount: null,
  freightWeight: null,
  specialConditions: null,
  statusId: null,
  statusName: null,
  executorId: null,
  createDate: null,
  supplyDate: null,
  executionDate: null,
  sendSchedules: [],
  freightGngCode: null,
  wagonAffiliationCode: null,
};

export type TariffAttributeJsonModel = {
  isEnabled: number;
  isAvailable: number;
  isChangeable: number;
  isHidden: number;
  attributeGroupCode: number;
  attributeCode: number;
  attributeName: string;
  attributeDescription: string;
  isEmpty?: boolean;
};

export interface TariffCountryDetailModel {
  /// Уникальный идентификатор
  tariffDetailId: string;
  /// Уникальный идентификатор родительской сущности
  tariffResponseId: string;
  /// Код станции импорта
  importStationCode: string;
  /// Наименование станции импорта
  importStationName: string;
  importStationRoadName: string;
  /// Код станции экспорта
  exportStationCode: string;
  /// Наименование станции экспорта
  exportStationName: string;
  exportStationRoadName: string;
  /// Код страны
  countryCode: number;
  /// Наименование страны
  countryName: string;
  rowSerialNumber: number;
  /// Код валюты тарифа
  tariffCurrencyCode: string;
  /// Код валюты государства
  tariffCountryCurrencyCode: string;
  /// Наименование валюты государства
  tariffCountryCurrencyName: string;
  /// Код типа перевозки
  transportationTypeId: number;
  /// Наименование типо перевозки
  transportationTypeName: string;
  /// Код тарифного руководства
  tariffGuidId: string;
  /// Наименование тарифного руководства
  tariffGuidName: string;
  /// Код единицы измерения тарифа
  tariffUnitId: number;
  /// Наименование единицы измерения тарифа
  tariffUnitName: string;
  /// Наименование валюты тарифа
  tariffCurrencyName: string;
  /// Тариф. Стоимость в валюте
  tariffCostCurrency: number;
  /// Тариф. Стоимость в руб.
  tariffCostRu: number;
  /// Тариф. Стоимость в франк.
  tariffCostChf: number;
  /// Тариф. Стоимость в доллар.
  tariffCostUsd: number;
  /// Тариф. Стоимость в валюте государства.
  tariffCostCountry: number;
  ///  Плата-тариф. Стоимость в валюте
  tariffChargeCurrency: number;
  /// Плата-тариф. Стоимость в руб.
  tariffChargeRu: number;
  /// Плата-тариф. Стоимость в франк.
  tariffChargeChf: number;
  /// Плата-тариф. Стоимость в долларар.
  tariffChargeUsd: number;
  /// Плата-тариф. Стоимость в валюте государства
  tariffChargeCountry: number;
  ///  Сборы за тонну. Стоимость в валюте
  chargePerTonCurrency: number;
  ///  Сборы за тонну. Стоимость в руб.
  chargePerTonRu: number;
  /// Сборы за тонну. Стоимость в франк
  chargePerTonChf: number;
  /// Сборы за тонну. Стоимость в доллар
  chargePerTonUsd: number;
  /// Сборы за тонну. Стоимость в валюте государства
  chargePerTonCountry: number;
  /// Сборы за подвижной состав. Стоимость в валюте
  wagonTypeChargeCurrency: number;
  /// Сборы за подвижной состав. Стоимость в руб.
  wagonTypeChargeRu: number;
  ///  Сборы за подвижной состав. Стоимость в франк
  wagonTypeChargeChf: number;
  ///  Сборы за подвижной состав. Стоимость в доллар
  wagonTypeChargeUsd: number;
  ///  Сборы за подвижной состав. Стоимость в валюте государства
  wagonTypeChargeCountry: number;
  /// Плата-сборы.  Стоимость в валюте
  feeChargeCurrency: number;
  /// Плата-сборы. Стоимость в руб.
  feeChargeRu: number;
  /// Плата-сборы. Стоимость в франк
  feeChargeChf: number;
  /// Плата-сборы. Стоимость в доллар
  feeChargeUsd: number;
  /// Плата-сборы. Стоимость в валюте государства
  feeChargeCountry: number;
  ///  Проозная плата. Стоимость в валюте
  freightChargeCurrency: number;
  ///  Проозная плата. Стоимость в руб.
  freightChargeRu: number;
  /// Проозная плата. Стоимость в франк
  freightChargeChf: number;
  /// Проозная плата. Стоимость в доллар
  freightChargeUsd: number;
  freightChargeEur: number;
  /// Проозная плата. Стоимость в валюте государства
  freightChargeCountry: number;
  ///  Проозная плата. Стоимость в валюте c НДС
  freightChargeCurrencyWithVat: number;
  ///  Проозная плата. Стоимость в руб. c НДС
  freightChargeRuWithVat: number;

  freightChargeEurWithVat: number;
  /// Проозная плата. Стоимость в франк c НДС
  freightChargeChfWithVat: number;
  /// Проозная плата. Стоимость в доллар c НДС
  freightChargeUsdWithVat: number;
  /// Проозная плата. Стоимость в валюте государства c НДС
  freightChargeCountryWithVat: number;
  ///  Проозная плата. Стоимость в валюте. НДС
  freightChargeCurrencyVat: number;
  ///  Проозная плата. Стоимость в руб. НДС
  freightChargeRuVat: number;
  /// Проозная плата. Стоимость в франк c НДС
  freightChargeChfVat: number;
  /// Проозная плата. Стоимость в доллар. НДС
  freightChargeUsdVat: number;
  freightChargeEurVat: number;
  /// Проозная плата. Стоимость в валюте государства. НДС
  freightChargeCountryVat: number;
  /// Дистанция
  tariffDistance: number;
  /// Экпедирование в доллар
  expeditorAmountUsd: number;
  expeditorAmountEur: number;
  /// Экспедирование НДС в доллар
  expeditorNdsAmountUsd: number;
  expeditorNdsAmountEur: number;
  expeditorNdsAmountCountry: number;
  expeditorNdsAmountCurrency: number;
  /// Экпедирование в франк
  expeditorAmountChf: number;
  /// Экспедирование НДС в франк
  expeditorNdsAmountChf: number;
  /// Экпедирование в руб
  expeditorAmountRu: number;
  /// Экспедирование НДС в руб
  expeditorNdsAmountRu: number;
  expeditorAmountCountry: number;
  expeditorAmountCurrency: number;

  // Сборы за тонну. Стоимость в валюте ТК
  chargePerTonTk: number;
  /// Экспедирование в валюте ТК
  expeditorAmountTk: number;
  // Экспедирование НДС в валюте ТК
  expeditorNdsAmountTk: number;
  // Плата-сборы. Стоимость в валюте ТК
  feeChargeTk: number;
  // Провозная плата. Стоимость в валюте ТК
  freightChargeTk: number;
  // Провозная плата. Стоимость в валюте ТК. НДС
  freightChargeTkVat: number;
  // Провозная плата. Стоимость в валюте ТК. С НДС
  freightChargeTkWithVat: number;
  // Плата-тариф. Стоимость в валюте ТК
  tariffChargeTk: number;
  // Тариф. Стоимость в валюте ТК
  tariffCostTk: number;
  // Код валюты ТК тарифа
  tariffTkCurrencyCode: string;
  // Наименование валюты ТК тарифа
  tariffTkCurrencyName: string;

  isEmpty: boolean;
  countryDetailData: CountryDetailData;
}

export interface CountryDetailData {
  coefficients: TariffCountryCoefficientJsonModel[];
  questions: TariffQuestionJsonModel[];
  additionalCharges: TariffAdditionalChargeJsonModel[];
}

export interface TariffCountryCoefficientJsonModel {
  isEnabled: number;
  description: string;
  coefficient: number;
  globosoftCountryCode: string;
  globosoftWagonTypeCode: number;
  isEmpty?: boolean;
}

export interface TariffQuestionJsonModel {
  isEnabled?: number;
  globosoftCountryCode: string;
  documentCode?: number;
  documentNumber?: number;
  description: string;
  isEmpty?: boolean;
}

export interface TariffAdditionalChargeJsonModel {
  isEnabled: number;

  /// Kup из xml тарифа
  globosoftWagonTypeCode: number;

  globosoftCountryCode: string;

  /// Код груза (1 - основной п/с, 2-10 - сопровождение, 11-99 - сборная отправка)
  freightCode: number;

  /// Уникальный номер сбора
  globosoftAdditionalChargeId: number;

  /// Ставка сбора
  rate: number;

  /// Валюта сбора
  currencyCode: number;

  /// Единица измерения сбора
  chargeUnit: string;

  /// Расчетная сумма сбора в валюте сбора
  chargeCurrencyAmount: number;

  /// Расчетная сумма сбора в валюте тарифа
  tariffChargeCurrencyAmount: number;

  /// Расчетная сумма сбора франк
  chargeChfAmount: number;

  /// Расчетная сумма сбора доллар
  chargeUsdAmount: number;

  /// Расчетная сумма сбора рубль
  chargeRuAmount: number;

  /// Расчетная сумма сбора евро
  chargeEurAmount: number;

  /// Расчетная сумма сбора валюта страны
  chargeCountryAmount: number;

  /// Расчетная сумма сбора в валюте сбора. Размер ндс
  chargeCurrencyVat: number;

  /// Расчетная сумма сбора в валюте тарифа. Размер ндс
  tariffChargeCurrencyVat: number;

  /// Расчетная сумма сбора франк Размер ндс
  chargeChfVat: number;

  /// Расчетная сумма сбора доллар Размер ндс
  chargeUsdVat: number;

  /// Расчетная сумма сбора рубль Размер ндс
  chargeRuVat: number;

  /// Расчетная сумма сбора евро Размер ндс
  chargeEurVat: number;

  /// Расчетная сумма сбора валюта страны Размер ндс
  chargeCountryVat: number;

  /// Расчетная сумма сбора  с ндс
  chargeCurrencyAmountWithVat: number;

  /// Расчетная сумма сбора  с ндс
  tariffChargeCurrencyAmountWithVat: number;

  /// Расчетная сумма сбора  с ндс
  chargeChfAmountWithVat: number;

  /// Расчетная сумма сбора  с ндс

  chargeUsdAmountWithVat: number;

  /// Расчетная сумма сбора  с ндс
  chargeRuAmountWithVat: number;

  /// Расчетная сумма сбора  с ндс
  chargeEurAmountWithVat: number;

  /// Расчетная сумма сбора  с ндс
  chargeCountryAmountWithVat: number;

  /// Единица измерения для расчетной суммы сбора
  chargeAmountUnit: number;

  /// Описание документа
  description: string;
}

export const TARIFF_FIELDS = [
  { fieldPrefix: 'tariffCost', fieldLastChars: '', name: 'Тариф', calc: true, style: { width: '80px' } },
  { fieldPrefix: 'tariffUnitName', fieldLastChars: '', name: 'Ед.', calc: false, style: { width: '35px' } },
  { fieldPrefix: 'tariffCharge', fieldLastChars: '', name: 'Плата-тариф', calc: true, style: { width: '110px' } },
  { fieldPrefix: 'chargePerTon', fieldLastChars: '', name: 'За тонну', calc: true, style: { width: '70px' } },
  { fieldPrefix: 'wagonTypeCharge', fieldLastChars: '', name: 'За п/с', calc: true, style: { width: '70px' } },
  { fieldPrefix: 'feeCharge', fieldLastChars: '', name: 'Плата-сборы', calc: true, style: { width: '110px' } },
  { fieldPrefix: 'expeditorAmount', fieldLastChars: '', name: 'Эксп', calc: true, style: { width: '90px' } },
  { fieldPrefix: 'expeditorNdsAmount', fieldLastChars: '', name: 'Эксп. НДС', calc: true, style: { width: '90px' } },
  { fieldPrefix: 'freightCharge', fieldLastChars: '', name: 'Провозная плата', calc: true, style: { width: '135px' } },
  { fieldPrefix: 'freightCharge', fieldLastChars: 'Vat', name: 'НДС', calc: true, style: { width: '70px' } },
  { fieldPrefix: 'freightCharge', fieldLastChars: 'WithVat', name: 'с НДС', calc: true, style: { width: '80px' } },
];

export interface Currency {
  id: number;
  name: string;
  filedPostfix: string;
  filedPostfixCode: string;
  variable: boolean;
  nameFiled: string;
  symbol: string;
}

export const CURRENCY_FIELDS: Currency[] = [
  {
    id: 0,
    name: 'Валюта тарифа',
    filedPostfix: 'Currency',
    filedPostfixCode: '0',
    variable: true,
    nameFiled: 'tariffCurrencyName',
    symbol: '',
  },
  {
    id: 1,
    name: 'Швейцарский франк',
    filedPostfix: 'Chf',
    filedPostfixCode: '756',
    variable: false,
    nameFiled: '',
    symbol: '₣',
  },
  { id: 2, name: 'Доллар США', filedPostfix: 'Usd', filedPostfixCode: '840', variable: false, nameFiled: '', symbol: '$' },
  { id: 3, name: 'Евро', filedPostfix: 'Eur', filedPostfixCode: '978', variable: false, nameFiled: '', symbol: '€' },
  {
    id: 4,
    name: 'Российский рубль',
    filedPostfix: 'Ru',
    filedPostfixCode: '810',
    variable: false,
    nameFiled: '',
    symbol: '₽',
  },
  {
    id: 5,
    name: 'Валюта страны',
    filedPostfix: 'Country',
    filedPostfixCode: '9999',
    variable: true,
    nameFiled: 'tariffCountryCurrencyName',
    symbol: '',
  },
  {
    id: 6,
    name: 'Валюта ТК',
    filedPostfix: 'Tk',
    filedPostfixCode: '9998',
    variable: false,
    nameFiled: 'tariffTkCurrencyName',
    symbol: '',
  },
];

export type SendSupplyScheduleModel = {
  scheduleId: string;
  sendId: string;
  supplyDate: string;
  freightWeight: number;
  wagonCount: number;
  userId: string;
  createDate: string;
  wagonNumbers: number[];
  commonTariff: number;
};

export const emptySendSupplyScheduleModel: SendSupplyScheduleModel = {
  scheduleId: null,
  sendId: null,
  supplyDate: null,
  freightWeight: null,
  wagonCount: 0,
  userId: null,
  createDate: null,
  wagonNumbers: [],
  commonTariff: null,
};

export interface TariffAggregationModel {
  loadStationCode: string;
  loadStationName: string;
  destStationCode: string;
  destStationName: string;
  distance: number;
  wagonAffiliationCode: number;
  wagonTypeCode: number;
  wagonTypeName: string;
  errorMessage: string;
  commonParkFreightChargeRuWithVat: number;
  commonParkFreightChargeChfWithVat: number;
  commonParkFreightChargeUsdWithVat: number;
  rentParkFreightChargeRuWithVat: number;
  rentParkFreightChargeChfWithVat: number;
  rentParkFreightChargeUsdWithVat: number;
  freightEtsngCode: string;
  freightEtsngName: string;
  localId: number;
}

export interface SendGeoInfo {
  /// Широта
  latitude: number;
  /// Долгота
  longitude: number;
  /// Радиус
  radius: number;
  //Дней до ремонта
  daysBeforeRepair: number;
}

export interface SendLimitationsModel {
  /// <summary>
  /// срок действия от
  /// </summary>
  validityFrom: any;

  /// <summary>
  /// срок действия до
  /// </summary>
  validityTo: any;

  /// <summary>
  /// Вес груза отправки
  /// </summary>
  freightWeight: number;

  /// <summary>
  /// Кол-во вагонов отправки
  /// </summary>
  wagonCount: number;
}

export interface CompanyWagonItineraryModel {
  itineraryId: string;
  companyId: string;
  wagonId: string;
  currentClientId: string;
  currentClaimId: string;
  currentSendId: string;
  planClientId: string;
  planClaimId: string;
  planSendId: string;
  planLoadStationCode: string;
  planDestStationCode: string;
}
