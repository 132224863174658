import { Collapse, Divider, Table } from 'antd';
import React from 'react';

import AdaptiveCard from '@components/fw/AdaptiveElements/AdaptiveCard';
import { Flex } from '@components/fw/Flex';
import styles from '@components/routed/RegisterOfBetsCreatePage/ui/wagons-service/wagon-services.module.scss';

import { BidRegistryCommercialStore, WagonProvision } from '@stores/bidRegistryCommercialStore';
import { registerOfBetsStore } from '@stores/registerOfBetsStore';

import { COLUMNS } from '../../lib/columns';

export const OwnersTable = () => {
  const { bidRegistry } = registerOfBetsStore;
  const Title = ({ freight }: { freight: string }) => {
    return (
      <Flex vertical justify="center">
        {bidRegistry.rps}
        <Divider style={{ margin: 0 }} />
        {freight}
      </Flex>
    );
  };

  return (
    <>
      {Object.entries(BidRegistryCommercialStore.total.own).map(([freight, wagonProvision]) => {
        return (
          <AdaptiveCard hoverable key={freight} title={<Title freight={freight} />}>
            <Table rowKey={Math.random} bordered pagination={false} columns={COLUMNS} dataSource={wagonProvision as WagonProvision[]} />
          </AdaptiveCard>
        );
      })}
    </>
  );
};
