import { ControlOutlined } from '@ant-design/icons';
import { Button, Tooltip } from 'antd';
import { observer } from 'mobx-react';
import React, { Component } from 'react';

import ScrolledContainer from '@components/fw/ScrolledContainer';
import CurrencyDictionary from '@components/routed/OptionsPage/CurrencyDictionary';

import designStore from '@stores/designStore/designStore';
import optionsStore from '@stores/optionsStore/optionsStore';
import { Currency, TARIFF_FIELDS, TariffCountryDetailModel } from '@stores/sendsStore/sendData';

interface FwProps {
  value: TariffCountryDetailModel[];
  deliveryPeriod?: number;
  showHeader?: boolean;
  showBody?: boolean;
  showTotal?: boolean;

  onOpenCoefficients?(): void;

  onOpenAdditionalCharges?(): void;

  onOpenAdditionalCharges168?(): void;
}

interface ComponentState {
  visible: boolean;
  saveCurrency: { [key: number]: Currency };
}

class TariffDetailViewerShort extends Component<FwProps, ComponentState> {
  constructor(props: FwProps) {
    super(props);

    this.state = {
      visible: false,
      saveCurrency: {},
    };
  }

  get total() {
    if (!this.props.value || !this.props.value.length) {
      return null;
    }
    let result: TariffCountryDetailModel = JSON.parse(JSON.stringify(this.props.value[0]));
    result.countryName = 'Итого';
    for (let i = 1; i < this.props.value.length; i++) {
      const current = this.props.value[i];
      if (!current) {
        continue;
      }
      Object.keys(current).forEach((key) => {
        if (typeof current[key] === 'number') {
          if (!result[key]) {
            result[key] = 0;
          }
          result[key] += current[key];
        }
      });
    }
    return result;
  }

  render() {
    const isMobile = designStore.isMobile;
    const totalTable = {
      gridTemplateColumns: '211px auto',
    };
    const deliveryPeriod = this.props.deliveryPeriod;
    const data = this.props.value;
    const fields = TARIFF_FIELDS;
    const total = this.total;
    const isGroup = optionsStore.request.quantityShippingTypeCode && optionsStore.request.quantityShippingTypeCode != 2;
    const wagonCount = optionsStore.request.wagonCount;
    const freightWeight = optionsStore.request.freightWeight;
    const isGroupAndHasWagons = isGroup && !!wagonCount;
    const showHeader = this.props.showHeader;
    const globalCurrency = optionsStore.selectedCurrency;
    if (!data.length) {
      return <div>Нет данных</div>;
    }

    const fieldsWithActions = {
      tariffCost: () => {
        if (this.props.onOpenCoefficients) {
          this.props.onOpenCoefficients();
        }
      },
      wagonTypeCharge: () => {
        if (this.props.onOpenAdditionalCharges) {
          this.props.onOpenAdditionalCharges();
        }
      },
      chargePerTon: () => {
        if (this.props.onOpenAdditionalCharges168) {
          this.props.onOpenAdditionalCharges168();
        }
      },
    };
    if (optionsStore.request && optionsStore.request.expeditorSettings && optionsStore.request.expeditorSettings.length) {
      fieldsWithActions['expeditorAmount'] = () => {
        optionsStore.showExpedition = true;
      };
    }
    return (
      <div className={'verticalTable'}>
        <ScrolledContainer>
          <div
            className={'tariffCalculationTable'}
            style={{
              gridTemplateColumns: `${
                isMobile ? '153px repeat(' + data.length + ', minmax(auto, 1fr))' : '163px repeat(' + data.length + ', 217px)'
              }`,
            }}>
            {!!showHeader && (
              <div>
                <div>Политика</div>
                <div>Страна</div>
                <div>Тип</div>
                <div>км</div>
                {fields.map((value, index) => {
                  return (
                    <div key={'th' + index}>
                      {Object.keys(fieldsWithActions).includes(value.fieldPrefix + value.fieldLastChars) ? (
                        <Button
                          icon={<ControlOutlined style={{ marginLeft: 4 }} />}
                          onClick={fieldsWithActions[value.fieldPrefix]}
                          size={'small'}
                          type={'link'}>
                          {value.name}
                        </Button>
                      ) : (
                        value.name
                      )}
                    </div>
                  );
                })}
                {freightWeight ? <div>НДС (за 1 тонну)</div> : null}
                {freightWeight ? <div>c НДС (за 1 тонну)</div> : null}
                <div style={{ borderRadius: '0 0 0 5px' }}>Валюта</div>
              </div>
            )}
            {!!this.props.showBody &&
              data.map((value, index) => {
                const currency = this.state.saveCurrency[index] ? this.state.saveCurrency[index] : optionsStore.selectedCurrency;
                return (
                  <div>
                    <div>{value.tariffGuidName}</div>
                    <div>{value.countryName}</div>
                    <div>{value.transportationTypeName}</div>
                    <div>{value.tariffDistance}</div>
                    {fields.map((field, index) => {
                      return (
                        <div key={'td' + index} style={{ textAlign: 'right' }}>
                          {field.calc
                            ? value[field.fieldPrefix + currency.filedPostfix + field.fieldLastChars]?.toLocaleString('ru', {
                                maximumFractionDigits: 2,
                                minimumFractionDigits: 2,
                              })
                            : value[field.fieldPrefix]}
                        </div>
                      );
                    })}
                    {freightWeight ? (
                      <div>
                        {(value['freightCharge' + currency.filedPostfix + 'Vat'] / freightWeight)?.toLocaleString('ru', {
                          maximumFractionDigits: 2,
                          minimumFractionDigits: 2,
                        })}
                      </div>
                    ) : null}
                    {freightWeight ? (
                      <div>
                        {(value['freightCharge' + currency.filedPostfix + 'WithVat'] / freightWeight)?.toLocaleString('ru', {
                          maximumFractionDigits: 2,
                          minimumFractionDigits: 2,
                        })}
                      </div>
                    ) : null}
                    <div style={{ borderRadius: '0 0 5px 0' }}>
                      <CurrencyDictionary
                        value={currency}
                        type={'small'}
                        onChange={(e) => {
                          const saveCurrency = this.state.saveCurrency;
                          saveCurrency[index] = e;
                          this.setState({ saveCurrency });
                        }}
                        countryCurrency={{ name: value.tariffCurrencyName, code: value.tariffCurrencyCode }}
                        tariffCurrency={{ name: value.tariffCountryCurrencyName, code: value.tariffCountryCurrencyCode }}
                      />
                    </div>
                  </div>
                );
              })}
          </div>
        </ScrolledContainer>
        {this.props.showTotal && (
          <div className={'total'}>
            {!globalCurrency.variable && (
              <>
                <div className={'totalTable'} style={totalTable}>
                  <div>
                    <div>
                      <span className={'caption'}>Итого {isGroup ? ' за одну единицу пс.:' : ''}</span>
                    </div>
                    <div>Общее расстояние</div>
                    <div>Срок доставки</div>
                    <div>Провозная плата без НДС</div>
                    <div>НДС</div>
                    <div>с НДС</div>
                  </div>
                  <div>
                    <div> </div>
                    <div>{total.tariffDistance} км</div>
                    <div>{deliveryPeriod ? ' ' + deliveryPeriod + ' дней' : ''}</div>
                    <div className={'totalNumber'}>
                      {total['freightCharge' + globalCurrency.filedPostfix]?.toLocaleString('ru', {
                        maximumFractionDigits: 2,
                        minimumFractionDigits: 2,
                      })}
                      <Tooltip title={globalCurrency.name}>
                        <span>{globalCurrency.symbol}</span>
                      </Tooltip>
                    </div>
                    <div className={'totalNumber'}>
                      {total['freightCharge' + globalCurrency.filedPostfix + 'Vat']?.toLocaleString('ru', {
                        maximumFractionDigits: 2,
                        minimumFractionDigits: 2,
                      })}
                      <Tooltip title={globalCurrency.name}>
                        <span>{globalCurrency.symbol}</span>
                      </Tooltip>
                    </div>
                    <div className={'totalNumber'}>
                      {total['freightCharge' + globalCurrency.filedPostfix + 'WithVat']?.toLocaleString('ru', {
                        maximumFractionDigits: 2,
                        minimumFractionDigits: 2,
                      })}
                      <Tooltip title={globalCurrency.name}>
                        <span>{globalCurrency.symbol}</span>
                      </Tooltip>
                    </div>
                  </div>
                </div>
                {freightWeight ? (
                  <div className={'totalTable'} style={totalTable}>
                    <div>
                      <div>
                        <span className={'caption'}>Итого за одну тонну:</span>
                      </div>
                      <div>Провозная плата без НДС</div>
                      <div>НДС</div>
                      <div>с НДС</div>
                    </div>
                    <div>
                      <div></div>
                      <div className={'totalNumber'}>
                        {(total['freightCharge' + globalCurrency.filedPostfix] / freightWeight)?.toLocaleString('ru', {
                          maximumFractionDigits: 2,
                          minimumFractionDigits: 2,
                        })}
                        <Tooltip title={globalCurrency.name}>
                          <span>{globalCurrency.symbol}</span>
                        </Tooltip>
                      </div>
                      <div className={'totalNumber'}>
                        {(total['freightCharge' + globalCurrency.filedPostfix + 'Vat'] / freightWeight)?.toLocaleString('ru', {
                          maximumFractionDigits: 2,
                          minimumFractionDigits: 2,
                        })}
                        <Tooltip title={globalCurrency.name}>
                          <span>{globalCurrency.symbol}</span>
                        </Tooltip>
                      </div>
                      <div className={'totalNumber'}>
                        {(total['freightCharge' + globalCurrency.filedPostfix + 'WithVat'] / freightWeight)?.toLocaleString('ru', {
                          maximumFractionDigits: 2,
                          minimumFractionDigits: 2,
                        })}
                        <Tooltip title={globalCurrency.name}>
                          <span>{globalCurrency.symbol}</span>
                        </Tooltip>
                      </div>
                    </div>
                  </div>
                ) : null}
                {!!isGroupAndHasWagons && (
                  <div className={'totalTable'} style={totalTable}>
                    <div>
                      <div>
                        <span className={'caption'}>Итого за группу:</span>
                      </div>
                      <div>Провозная плата без НДС</div>
                      <div>НДС</div>
                      <div>с НДС</div>
                    </div>
                    <div>
                      <div></div>
                      <div className={'totalNumber'}>
                        {(total['freightCharge' + globalCurrency.filedPostfix] * wagonCount)?.toLocaleString('ru', {
                          maximumFractionDigits: 2,
                          minimumFractionDigits: 2,
                        })}
                        <Tooltip title={globalCurrency.name}>
                          <span>{globalCurrency.symbol}</span>
                        </Tooltip>
                      </div>
                      <div className={'totalNumber'}>
                        {(total['freightCharge' + globalCurrency.filedPostfix + 'Vat'] * wagonCount)?.toLocaleString('ru', {
                          maximumFractionDigits: 2,
                          minimumFractionDigits: 2,
                        })}
                        <Tooltip title={globalCurrency.name}>
                          <span>{globalCurrency.symbol}</span>
                        </Tooltip>
                      </div>
                      <div className={'totalNumber'}>
                        {(total['freightCharge' + globalCurrency.filedPostfix + 'WithVat'] * wagonCount)?.toLocaleString('ru', {
                          maximumFractionDigits: 2,
                          minimumFractionDigits: 2,
                        })}
                        <Tooltip title={globalCurrency.name}>
                          <span>{globalCurrency.symbol}</span>
                        </Tooltip>
                      </div>
                    </div>
                  </div>
                )}
              </>
            )}
            {globalCurrency.filedPostfix !== 'Ru' && !!globalCurrency.variable && (
              <>
                <div className={'totalTable'} style={totalTable}>
                  <div>
                    <div>
                      <span className={'caption'}>Итого {isGroup ? ' за одну единицу пс.:' : ''}</span>
                    </div>
                    <div>Общее расстояние</div>
                    <div>Срок доставки</div>
                    <div>Провозная плата без НДС</div>
                    <div>НДС</div>
                    <div>с НДС</div>
                  </div>
                  <div>
                    <div style={{ fontWeight: 'bold', textAlign: 'right' }}>{total.tariffDistance}</div>
                    <div>{deliveryPeriod ? 'Срок доставки ' + deliveryPeriod + ' дней' : ''}</div>
                    <div className={'totalNumber'}>
                      {total.freightChargeRu.toLocaleString('ru', { maximumFractionDigits: 2, minimumFractionDigits: 2 })}
                      <Tooltip title={'Российский рубль'}>
                        <span>₽</span>
                      </Tooltip>
                    </div>
                    <div className={'totalNumber'}>
                      {total.freightChargeRuVat.toLocaleString('ru', { maximumFractionDigits: 2, minimumFractionDigits: 2 })}
                      <Tooltip title={'Российский рубль'}>
                        <span>₽</span>
                      </Tooltip>
                    </div>
                    <div className={'totalNumber'}>
                      {total.freightChargeRuWithVat.toLocaleString('ru', {
                        maximumFractionDigits: 2,
                        minimumFractionDigits: 2,
                      })}
                      <Tooltip title={'Российский рубль'}>
                        <span>₽</span>
                      </Tooltip>
                    </div>
                  </div>
                </div>
                {freightWeight ? (
                  <div className={'totalTable'} style={totalTable}>
                    <div>
                      <div>
                        <span className={'caption'}>Итого за одну тонну:</span>
                      </div>
                      <div>Провозная плата без НДС</div>
                      <div>НДС</div>
                      <div>с НДС</div>
                    </div>
                    <div>
                      <div></div>
                      <div className={'totalNumber'}>
                        {(total.freightChargeRu / freightWeight).toLocaleString('ru', {
                          maximumFractionDigits: 2,
                          minimumFractionDigits: 2,
                        })}
                        <Tooltip title={'Российский рубль'}>
                          <span>₽</span>
                        </Tooltip>
                      </div>
                      <div className={'totalNumber'}>
                        {(total.freightChargeRuVat / freightWeight).toLocaleString('ru', {
                          maximumFractionDigits: 2,
                          minimumFractionDigits: 2,
                        })}
                        <Tooltip title={'Российский рубль'}>
                          <span>₽</span>
                        </Tooltip>
                      </div>
                      <div className={'totalNumber'}>
                        {(total.freightChargeRuWithVat / freightWeight).toLocaleString('ru', {
                          maximumFractionDigits: 2,
                          minimumFractionDigits: 2,
                        })}
                        <Tooltip title={'Российский рубль'}>
                          <span>₽</span>
                        </Tooltip>
                      </div>
                    </div>
                  </div>
                ) : null}
                {!!isGroupAndHasWagons && (
                  <div className={'totalTable'} style={totalTable}>
                    <div>
                      <div>
                        <span className={'caption'}>Итого за группу:</span>
                      </div>
                      <div>Провозная плата без НДС</div>
                      <div>НДС</div>
                      <div>с НДС</div>
                    </div>
                    <div>
                      <div style={{ fontWeight: 'bold' }}>
                        {(total.freightChargeRu * wagonCount).toLocaleString('ru', {
                          maximumFractionDigits: 2,
                          minimumFractionDigits: 2,
                        })}
                        <Tooltip title={'Российский рубль'}>
                          <span>₽</span>
                        </Tooltip>
                      </div>
                      <div style={{ fontWeight: 'bold' }}>
                        {(total.freightChargeRuVat * wagonCount).toLocaleString('ru', {
                          maximumFractionDigits: 2,
                          minimumFractionDigits: 2,
                        })}
                        <Tooltip title={'Российский рубль'}>
                          <span>₽</span>
                        </Tooltip>
                      </div>
                      <div style={{ fontWeight: 'bold' }}>
                        {(total.freightChargeRuWithVat * wagonCount).toLocaleString('ru', {
                          maximumFractionDigits: 2,
                          minimumFractionDigits: 2,
                        })}
                        <Tooltip title={'Российский рубль'}>
                          <span>₽</span>
                        </Tooltip>
                      </div>
                    </div>
                  </div>
                )}
              </>
            )}
          </div>
        )}
      </div>
    );
  }
}

export default observer(TariffDetailViewerShort);
