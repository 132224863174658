import { action, computed, makeObservable, observable } from 'mobx';

import tariffFgkBaseStore, { defaultTariffFgkBaseRequest } from '../TariffFgkBase/tariffFgkBaseStore';
import { TariffEscortJsonModel, TariffRequestModel, defaultTariffRequest } from '../historyTariffInfo/historyTariffInfoStore';
import { CURRENCY_FIELDS, Currency } from '../sendsStore/sendData';
import userStore from '../userStore/userStore';
import service from './optionsStore.service';
import { EmptyTariffCalcResponse, TariffCalcResponse, TariffDictionaryModel, resultDataProcess } from './optionsStoreData';

export const currencyNameCropper = (name: string) => {
  if (!name) return '-';
  const find = CURRENCY_FIELDS.find((f) => f.name == name);
  if (find) return find.nameFiled;
  let result = '';
  const split = name.split(' ');
  split.forEach((s) => {
    result += s.substring(0, 1);
  });
  return result.toUpperCase();
};

class OptionsStore {
  @observable isBusy: boolean = false;
  @observable private _dictionaries: TariffDictionaryModel;

  @observable result: TariffCalcResponse;
  @observable request: TariffRequestModel = defaultTariffRequest();

  @observable selectedCurrency: Currency = CURRENCY_FIELDS[4];
  @observable showAttributesModal: boolean = false;

  @observable countryPositions: string[] = [];

  @observable showCoefficient: boolean = false;
  @observable showAdditionalCharges: boolean = false;
  @observable showAdditionalCharges168: boolean = false;

  @observable showExpedition: boolean = false;
  @observable showResult: boolean = false;

  @observable isTariffCalculated: boolean = false;
  @observable toggleStation: boolean = false;

  @observable attributesPopoverVisible: boolean = false;

  constructor() {
    makeObservable(this);
  }

  @action async calcTariff(withExpedition: boolean = false) {
    this.isBusy = true;
    try {
      // если не очистить это поле, то id расчета не семинется при перерасчете из истории
      this.request.globosoftRequestId = null;
      const companyId = userStore.userData.companyId;
      if (withExpedition && companyId) {
        const expedition = await service.tariffExpeditorSettings(companyId, this.request);
        this.request.expeditorSettings = expedition;
        if (!expedition || !expedition.length) {
          await this.calcAndSetNewTariff();
        } else {
          this.showExpedition = true;
        }
      } else {
        await this.calcAndSetNewTariff();
      }
    } finally {
      this.isBusy = false;
    }
  }

  private async calcAndSetNewTariff() {
    const result = await service.checkTariff(this.request);
    this.setResult(result);
    this.showResult = true;
    this.isTariffCalculated = true;
    this.attributesPopoverVisible = true;
  }

  setResult(result: TariffCalcResponse) {
    const parsed = resultDataProcess(result);
    this.result = parsed.result ? parsed.result : EmptyTariffCalcResponse;
    this.countryPositions = parsed.countryPositions;
    this.request.globosoftRequestId = result ? result.globosoftRequestId : null;
  }

  @action reset() {
    this.isBusy = false;
    this.result = null;
    this.request = defaultTariffRequest();
    tariffFgkBaseStore.request = defaultTariffFgkBaseRequest;
    this.showResult = false;
    this.selectedCurrency = CURRENCY_FIELDS[4];
  }

  @action async getTariffDictionary() {
    this.isBusy = true;
    return await service
      .getTariffDictionary()
      .finally(() => (this.isBusy = false))
      .then((result: TariffDictionaryModel) => {
        this._dictionaries = result;
      });
  }

  @action async loadTariffDictionary() {
    if (!this._dictionaries) {
      await this.getTariffDictionary();
    }
  }

  @action setEscort(escort: TariffEscortJsonModel[]) {
    this.request.tariffData.escort = escort;
  }

  @computed get enabledAttributes() {
    if (!this.result || !this.result.tariffDetailData) return [];
    return this.result.tariffDetailData.attributes.filter((a) => a.isEnabled);
  }

  /* Опасность */
  @computed get dangerCategoryDictionary() {
    if (!this._dictionaries) {
      return [];
    } else {
      return [...this._dictionaries.dangerCategoryDictionary];
    }
  }

  /* Скорость */
  @computed get speedTypeDictionary() {
    if (!this._dictionaries) {
      return [];
    } else {
      return [...this._dictionaries.speedTypeDictionary];
    }
  }

  /* Перегрузка */
  @computed get overloadDictionary() {
    if (!this._dictionaries) {
      return [];
    } else {
      return [...this._dictionaries.overloadDictionary];
    }
  }

  /* Негабаритность */

  @computed get oversizeCategoryDictionary() {
    if (!this._dictionaries) {
      return [];
    } else {
      return [...this._dictionaries.oversizeCategoryDictionary];
    }
  }
  //Принадлежность
  @computed get wagonAffiliationTypeDictionary() {
    if (!this._dictionaries) {
      return [];
    } else {
      return [...this._dictionaries.wagonAffiliationTypeDictionary];
    }
  }

  @computed get wagonTypesDictionary() {
    if (!this._dictionaries) {
      return [];
    } else {
      return [...this._dictionaries.wagonTypesDictionary];
    }
  }

  @computed get quantityShippingType() {
    if (!this._dictionaries) {
      return [];
    } else {
      return [...this._dictionaries.quantityShippingType];
    }
  }

  @computed get scaleShippingType() {
    if (!this._dictionaries) {
      return [];
    } else {
      return [...this._dictionaries.scaleShippingType];
    }
  }

  @computed get wagonTypeGroupDictionary() {
    if (!this._dictionaries) {
      return [];
    } else {
      return [...this._dictionaries.wagonTypeGroupDictionary];
    }
  }

  @computed get packagingDictionary() {
    if (!this._dictionaries) {
      return [];
    } else {
      return [...this._dictionaries.packagingDictionary];
    }
  }

  @computed get currencyDictionary() {
    if (!this._dictionaries) {
      return [];
    } else {
      return [...this._dictionaries.currencyDictionary];
    }
  }
  @computed get tariffUnitDictionary() {
    if (!this._dictionaries) {
      return [];
    } else {
      return [...this._dictionaries.tariffUnitDictionary];
    }
  }
}

export default new OptionsStore();
