import Icon, { DeleteOutlined } from '@ant-design/icons';
import { Button, Checkbox, Col, Form, Popover, Row, Select, Spin } from 'antd';
import { FormInstance } from 'antd/es/form';
import { observer } from 'mobx-react';
import moment from 'moment';
import React, { Component } from 'react';

import { ReactComponent as iconQuestion } from '@assets/icons/icon-question.svg';

import AdaptiveCard from '@components/fw/AdaptiveElements/AdaptiveCard';
import AdaptivePageHeader from '@components/fw/AdaptiveElements/AdaptivePageHeader';
import CollapsedToolbar from '@components/fw/CollapsedToolbar/CollapsedToolbar';
import FloatDatepicker from '@components/fw/FloatLabel/FloatDatepicker';
import FloatSelect from '@components/fw/FloatLabel/FloatSelect';
import RemoteSelect from '@components/fw/RemoteSelect';
import ScrolledContainer from '@components/fw/ScrolledContainer';
import CurrencyDictionary from '@components/routed/OptionsPage/CurrencyDictionary';

import tariffFgkBaseStore, {
  TariffFgkBaseResponseModel,
  TariffFgkRequestModel,
  defaultTariffFgkBaseRequest,
} from '@stores/TariffFgkBase/tariffFgkBaseStore';
import designStore from '@stores/designStore/designStore';
import dictService from '@stores/dictStore/dictStore.service';
import optionsStore from '@stores/optionsStore/optionsStore';
import { GLOBAL_DATE_FORMAT } from '@stores/optionsStore/optionsStoreData';

import StationSelector from './StationSelector';
import TariffFgkViewer from './TariffFgkViewer';

interface TariffFgkPageState {
  isBusy: boolean;
  response: TariffFgkBaseResponseModel;
  historyTariffInfoOpen: boolean;
  showResult: boolean;
}
interface Props {}

@observer
class TariffFgkForm extends Component<Props, TariffFgkPageState> {
  formRef: FormInstance;
  data: TariffFgkBaseResponseModel;

  constructor(props: Props) {
    super(props);
    this.state = {
      isBusy: false,
      response: null,
      historyTariffInfoOpen: false,
      showResult: false,
    };
    tariffFgkBaseStore.request = defaultTariffFgkBaseRequest;
  }

  componentDidMount(): void {
    document.title = 'Расчет тарифа ФГК';
  }

  handleFormReset = () => {
    this.setState({
      showResult: false,
      response: null,
    });
    tariffFgkBaseStore.reset();
    this.resetFields();
  };

  onHistorySelect = async (requestId: string) => {
    this.setState({ isBusy: true, historyTariffInfoOpen: false, response: null });
    try {
      const ans = await tariffFgkBaseStore.onHistory(requestId);
      this.setState({ response: ans.response });
      this.resetFields();
    } finally {
      this.setState({ isBusy: false });
    }
  };

  disabledDate = (current) => {
    return current && (current < moment().startOf('day') || current > moment().add(1, 'month').endOf('month'));
  };

  render() {
    const { isBusy, response, showResult } = this.state;

    const request = tariffFgkBaseStore.request;
    const isMobile = designStore.isMobile;
    const calculateDate = this.formRef ? this.formRef.getFieldValue('rateHeadStartDate') : null;

    return (
      <>
        <ScrolledContainer
          style={{
            height: showResult ? 'calc(100vh - var(--header-height))' : 'calc(100vh - var(--header-height) - var(--header-height))',
          }}>
          <Spin spinning={isBusy}>
            {response && (
              <div style={{ display: showResult ? null : 'none' }}>
                <AdaptiveCard
                  bodyStyle={{ padding: isMobile ? '0px' : '16px' }}
                  title={<h1>Результат расчета тарифа ФГК</h1>}
                  actions={[
                    <Button size={'large'} type={'link'} key={'close'} onClick={() => this.setState({ showResult: false })}>
                      Закрыть
                    </Button>,
                  ]}>
                  <TariffFgkViewer response={response} />
                </AdaptiveCard>
              </div>
            )}
            <Form
              layout="vertical"
              ref={(ref) => (this.formRef = ref)}
              name={'tariff'}
              labelCol={{ span: 0 }}
              initialValues={{
                ...request,
              }}
              onFinish={this.handleConfirm}>
              <AdaptiveCard
                extra={
                  isMobile ? (
                    <CollapsedToolbar
                      content={[
                        <Button key={'result'} type={'link'} onClick={() => this.setState({ showResult: true })} disabled={!response}>
                          Показать результат
                        </Button>,
                        <Button key={'submit_btn'} type={'link'} htmlType="submit" form={'tariff'} disabled={isBusy} loading={isBusy}>
                          Рассчитать
                        </Button>,
                      ]}
                    />
                  ) : null
                }
                bodyStyle={{ padding: isMobile ? null : '16px' }}
                title={<h1>Расчет тарифа ФГК</h1>}
                style={{ display: showResult ? 'none' : null }}>
                <Row gutter={{ xs: 0 }}>
                  <Col xs={24} sm={24} md={24} lg={12} xl={12}>
                    <Form.Item name="rateHeadStartDate" rules={[{ required: true, message: 'Необходимо заполнить поле ' }]}>
                      <FloatDatepicker
                        position={isMobile ? 'top' : 'left'}
                        placeholder={'Дата расчёта'}
                        format={GLOBAL_DATE_FORMAT}
                        allowClear={false}
                        disabledDate={this.disabledDate}
                      />
                    </Form.Item>
                  </Col>
                  <Col xs={24} sm={24} md={24} lg={24} xl={12}>
                    <Form.Item name={'rateHeadCurrencyObj'}>
                      <CurrencyDictionary position={isMobile ? 'bottom' : 'right'} />
                    </Form.Item>
                  </Col>
                </Row>

                <StationSelector
                  valueExpr={'stationCodeCheckSum'}
                  disabled={false}
                  form={this.formRef}
                  loadName={'rateRouteStFromName'}
                  destName={'rateRouteStToName'}
                  calculateDate={calculateDate}
                />

                <Row gutter={{ xs: 0 }} style={{ marginTop: 24 }}>
                  <Col xs={24}>
                    <Form.Item rules={[{ required: true, message: 'Необходимо заполнить поле ' }]} name="rateCargoCargo">
                      <RemoteSelect
                        float={true}
                        placeholder={'ЕТСНГ'}
                        valueExpr={'freightEtsngCode'}
                        displayExpr={'fullName'}
                        aValueExpr={null}
                        autoInitDict={true}
                        dataSource={dictService.freightEtsngDict}
                        primitiveResult={true}
                        itemTemplate={(item) => (
                          <div style={{ whiteSpace: 'normal', height: 'auto' }}>
                            <span style={{ color: 'lightGray' }}>{item['freightEtsngCode']} </span>
                            {item['fullName']}
                          </div>
                        )}
                      />
                    </Form.Item>
                  </Col>
                </Row>

                <Row gutter={{ xs: 0 }} style={{ marginTop: 24 }}>
                  <Col xs={24} sm={24} md={24} lg={24} xl={8}>
                    <Form.Item name="rateHeadProp">
                      <FloatSelect
                        position={isMobile ? 'top' : 'left'}
                        placeholder={
                          <span style={{ display: 'inline-block' }}>
                            Род подвижного состава
                            <Popover
                              style={{ width: '250px' }}
                              trigger="hover"
                              content={<div style={{ width: '250px' }}>На текущий момент расчет возможен только для полувагонов</div>}>
                              <Icon component={iconQuestion} />
                            </Popover>
                          </span>
                        }>
                        <Select.Option value={8}>Полувагоны</Select.Option>
                      </FloatSelect>
                    </Form.Item>
                  </Col>
                  <Col xs={24} sm={24} md={24} lg={24} xl={8}>
                    <Form.Item name="rateCargoKindOtp">
                      <FloatSelect placeholder={'Вид отправки'} position={'middle'}>
                        <Select.Option value={0}>1</Select.Option>
                        <Select.Option value={1}>2</Select.Option>
                        <Select.Option value={2}>3-5</Select.Option>
                        <Select.Option value={3}>6-20</Select.Option>
                        <Select.Option value={4}>21 и более</Select.Option>
                        <Select.Option value={20}>отправительский маршрут</Select.Option>
                        <Select.Option value={21}>любая</Select.Option>
                      </FloatSelect>
                    </Form.Item>
                  </Col>
                  <Col xs={24} sm={24} md={24} lg={24} xl={8}>
                    <Form.Item name="rateRoutePayerEmpty">
                      <FloatSelect
                        position={isMobile ? 'bottom' : 'right'}
                        placeholder={
                          <span style={{ display: 'inline-block' }}>
                            Оплата порожнего рейса
                            <Popover
                              style={{ width: '250px' }}
                              trigger="hover"
                              content={
                                <div style={{ width: '250px' }}>На текущий момент расчет возможен только для оплаты за счет ФГК</div>
                              }>
                              <Icon component={iconQuestion} />
                            </Popover>
                          </span>
                        }>
                        <Select.Option value={-1}>За счет ФГК</Select.Option>
                      </FloatSelect>
                    </Form.Item>
                  </Col>
                  <Col xs={24} sm={24} md={24} lg={24} xl={8}>
                    <Form.Item valuePropName={'checked'} label={<span> </span>} name="rateTechInnovation">
                      <Checkbox>Инновационный вагон</Checkbox>
                    </Form.Item>
                  </Col>
                </Row>
              </AdaptiveCard>
            </Form>
          </Spin>
        </ScrolledContainer>
        <AdaptivePageHeader
          style={{ display: showResult ? 'none' : null }}
          extra={[
            <Button
              key={'reset_btn'}
              form={'claim-editor'}
              onClick={this.handleFormReset}
              disabled={isBusy}
              type={'link'}
              size={isMobile ? 'small' : 'middle'}
              style={{ float: 'left' }}>
              Очистить все <DeleteOutlined />
            </Button>,
            <Button
              style={{ marginLeft: 10, marginRight: 10 }}
              key={'result'}
              size={isMobile ? 'small' : 'large'}
              type={'primary'}
              onClick={() => this.setState({ showResult: true })}
              disabled={!response}>
              Показать результат
            </Button>,
            <Button
              key={'submit_btn'}
              type="primary"
              htmlType="submit"
              form={'tariff'}
              disabled={isBusy}
              size={isMobile ? 'small' : 'large'}
              loading={isBusy}>
              Рассчитать
            </Button>,
          ]}
        />
      </>
    );
  }

  resetFields(fields?: string[]) {
    optionsStore.toggleStation = false;
    setTimeout(() => {
      if (this.formRef) {
        this.formRef.resetFields(fields);
      }
      this.forceUpdate();
    }, 100);
  }

  handleConfirm = (event: TariffFgkRequestModel) => {
    event.rateHeadFinDate = event.rateHeadStartDate;
    event.rateAddWarning = true;
    event.rateHeadCurrencyCode = event.rateHeadCurrencyObj ? Number(event.rateHeadCurrencyObj.filedPostfixCode) : 810;
    event.rateCargoCntWag = 1;
    event.rateCargoType = 1;
    event.rateHeadType = -2;
    event.rateHeadUnit = 1;
    event.rateNoFerry = 1;
    event.rateHeadFinDate = moment(event.rateHeadFinDate).toISOString(true);
    event.rateHeadStartDate = moment(event.rateHeadStartDate).toISOString(true);

    if (optionsStore.toggleStation) {
      const load = event.rateRouteStFromName;
      const dest = event.rateRouteStToName;
      event.rateRouteStFromName = dest;
      event.rateRouteStToName = load;
    }

    this.setState({ isBusy: true });
    tariffFgkBaseStore
      .calculate(event)
      .then((r) => this.setState({ response: r, showResult: true }))
      .finally(() => {
        this.setState({ isBusy: false });
      });
  };
}

export default TariffFgkForm;
