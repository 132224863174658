import { Checkbox } from 'antd';
import DataGrid, { Column, FilterPanel, FilterRow, GroupPanel, HeaderFilter, Pager, Paging } from 'devextreme-react/data-grid';
import { observer } from 'mobx-react';
import React, { Component } from 'react';

import { INDEX_COLUMN } from '@components/routed/DispatcherReport/parts/DispetcherReportParts';

import companiesStore from '@stores/companiesStore/companiesStore';

interface ComponentProps {
  value?: string[];
  onChange?(e: string[]): void;
}

interface ComponentState {}

@observer
class CompanyAccesList extends Component<ComponentProps, ComponentState> {
  constructor(props: ComponentProps) {
    super(props);

    this.state = {};
  }

  handleChange = (companyId: string) => {
    const value = this.props.value;
    if (!value) return;
    const index = value.indexOf(companyId);
    if (index !== -1) {
      value.splice(index, 1);
    } else {
      value.push(companyId);
    }
    if (this.props.onChange) {
      this.props.onChange(value);
      this.forceUpdate();
    }
  };

  render() {
    const value = this.props.value;
    const companies = companiesStore.companies;
    return (
      <DataGrid
        allowColumnReordering={true}
        dataSource={companies}
        height={'100%'}
        rowAlternationEnabled={true}
        remoteOperations={false}
        showBorders={true}
        showRowLines={true}
        allowColumnResizing={true}
        columnResizingMode={'widget'}
        columnAutoWidth={true}
        columnMinWidth={20}
        wordWrapEnabled={true}>
        <Paging enabled={true} defaultPageSize={50} />
        <Pager showPageSizeSelector={false} showNavigationButtons={false} showInfo={true} infoText={'Всего: {2}'} />
        <GroupPanel visible={true} emptyPanelText={'Поле группировки'} />
        <HeaderFilter visible={true} />
        <FilterRow visible={true} />
        <FilterPanel visible={true} />
        {INDEX_COLUMN}
        <Column
          allowFixing={true}
          dataField="name"
          caption={'Название компании'}
          dataType="string"
          allowSorting={true}
          allowSearch={true}
          defaultSortOrder={'asc'}
        />
        <Column
          allowFixing={true}
          dataField="managerList"
          dataType="string"
          caption={''}
          allowSorting={false}
          allowSearch={false}
          allowFiltering={false}
          cellRender={(e) => {
            if (e.rowType == 'data') {
              return (
                <Checkbox checked={!!value.find((s) => s === e.data.companyId)} onChange={() => this.handleChange(e.data.companyId)} />
              );
            }
          }}
        />
      </DataGrid>
    );
  }
}

export default CompanyAccesList;
