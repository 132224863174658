import { Select } from 'antd';
import React from 'react';

import FloatSelect from '@components/fw/FloatLabel/FloatSelect';

import { useOkpos } from './okpo-select.api';
import { useOkpoOptions } from './okpo-select.lib';
import { OkpoSelectProps as Props } from './okpo-select.types';

export const OkpoSelect = ({ float, randomId, ...props }: Props) => {
  const { data, loading, setFilter } = useOkpos();
  const options = useOkpoOptions(data);

  return float ? (
    <FloatSelect
      {...props}
      randomId={randomId}
      options={options}
      filterOption={false}
      showSearch={true}
      onSearch={setFilter}
      loading={loading}
    />
  ) : (
    <Select {...props} filterOption={false} options={options} showSearch={true} onSearch={setFilter} loading={loading} />
  );
};
