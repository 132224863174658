import { notification } from 'antd';
import { action, computed, makeObservable, observable } from 'mobx';
import qs from 'qs';

import DispatcherReport from '@components/routed/DispatcherReport';

import { RowsData, SelectedRemark } from '@stores/dispatcherStore/dispatcherStore.types';

import { showErrorNotify, showSuccessNotify } from '@utils/notify';

import companyStore from '../companyStore/companyStore';
import designStore from '../designStore/designStore';
import { Container } from '../trackingStore/container/ContainerTypes';
import Wagon from '../trackingStore/wagon/WagonTypes';
import userStore from '../userStore/userStore';
import { CustomReportDetailsModel, InfoBlockItem, WagonItineraryModel, WagonOperationModel, checkIgnoreCase } from './DispatcherData';
import service from './dispatcherStore.service';
import dispatcherService from './dispatcherStore.service';

class DispatcherStore {
  @observable isBusy: boolean = false;
  @observable isBusyReportList: boolean = false;
  @observable public _reportlist: CustomReportDetailsModel[] = [];
  @observable public infogroupList: any[] = [];
  @observable public infoblocksList: InfoBlockItem[] = [];
  @observable public isInfoBlockBusy: boolean = false;
  @observable public avlbGroups: string[] = [];
  @observable public disableUpdateOnNextCall = false;
  @observable wagonItinerary: WagonItineraryModel[];
  @observable wagonOperation: WagonOperationModel[];
  @observable marksAndCatalogsOpen: boolean = false;
  @observable operationsOpen: boolean = false;
  @observable addRegistryOpen: boolean = false;
  @observable repairsModalOpen: boolean = false;

  @observable isRemarkModalOpen: boolean = false;
  @observable selectedRemarkList: SelectedRemark[] = [];

  @observable filteredWagons: Wagon[] = [];
  @observable filteredContainers: Container[] = [];
  @observable numbersFilterModalVisible: boolean = false;
  @observable jsonFieldsMode: 'all' | 'available' = 'all';

  @observable currentReport: CustomReportDetailsModel = null;

  private tmpLoadOptions: any;
  private tmpReportData: any;

  @observable selectedRepairId: any[] = [];
  @observable selected: any[] = [];
  @observable selectedRowsData: RowsData[] = [];

  @observable itineraryModalType: 'plan' | 'current' | null = null;

  constructor() {
    makeObservable(this);
  }

  @action async getReportList() {
    this.isBusyReportList = true;
    this.isBusy = true;
    return service
      .getReportList()
      .finally(() => ((this.isBusyReportList = false), (this.isBusy = false)))
      .then((result: CustomReportDetailsModel[]) => {
        this._reportlist = result;
        this.avlbGroups = [];
        this._reportlist
          .filter((it) => !it.isDefault)
          .forEach((r) => {
            if (!this.avlbGroups.find((g) => g == r.groupName)) {
              this.avlbGroups.push(r.groupName);
            }
          });
      });
  }

  @action async loadReportMetadata(reportId: string): Promise<CustomReportDetailsModel> {
    return await dispatcherService.getReportListByID(reportId).then((ans) => {
      this.currentReport = ans;
      return ans;
    });
  }

  @action async updateNotesAndCatalogs(type: 'wagons' | 'containers') {
    if (type == 'wagons') {
      let ans = await dispatcherService.wagonInfo(this.selected as number[]);
      ans.forEach((a) => {
        let find = this.tmpReportData.data.find((d) => d.wagonnumber == a.number);
        if (find) {
          find.wagonnotes1 = JSON.stringify(a.notes1);
          find.wagonnotes2 = JSON.stringify(a.notes2);
          find.wagonnotes3 = JSON.stringify(a.notes3);
          find.wagoncatalogs = JSON.stringify(a.catalogs);
        }
      });
    } else {
      let ans = await dispatcherService.containerInfo(this.selected as string[]);
      ans.forEach((a) => {
        let find = this.tmpReportData.data.find((d) => d.containernumber == a.number);
        if (find) {
          find.containernotes1 = JSON.stringify(a.notes1);
          find.containernotes2 = JSON.stringify(a.notes2);
          find.containernotes3 = JSON.stringify(a.notes3);
          find.containercatalogs = JSON.stringify(a.catalogs);
        }
      });
    }
    this.disableUpdateOnNextCall = true;
  }

  @action async dataReportList(id: string, loadOptions: any) {
    if (this.filteredWagons && this.filteredWagons.length) {
      let wagonFilter = ['w.wagon_number', 'in', '(' + this.filteredWagons.map((w) => w.number).join(',') + ')'];
      if (loadOptions.filter) {
        loadOptions.filter = [wagonFilter, 'and', loadOptions.filter];
      } else {
        loadOptions.filter = wagonFilter;
      }
      let wagonSort = {
        selector: 'wagonnumber',
        valuesOrder: `[${this.filteredWagons.map((w) => w.number).join(',')}]`,
        desc: false,
      };
      if (loadOptions.sort) {
        loadOptions.sort.push(wagonSort);
      } else {
        loadOptions.sort = [wagonSort];
      }
    }

    if (this.filteredContainers && this.filteredContainers.length) {
      let containerFilter = ['c.container_number', 'in', '(' + this.filteredContainers.map((c) => "'" + c.number + "'").join(',') + ')'];
      if (loadOptions.filter) {
        loadOptions.filter = [containerFilter, 'and', loadOptions.filter];
      } else {
        loadOptions.filter = containerFilter;
      }
      let containerSort = {
        selector: 'containernumber',
        valuesOrder: `[${this.filteredContainers.map((c) => "'" + c.number + "'").join(',')}]::varchar[]`,
        desc: false,
      };
      if (loadOptions.sort) {
        loadOptions.sort.push(containerSort);
      } else {
        loadOptions.sort = [containerSort];
      }
    }

    const excludedIgnorecase = this.currentReport?.fields
      ?.filter((f) => ['number', 'integer', 'decimal'].includes(f.filterType))
      ?.map((f) => f.name);
    checkIgnoreCase(loadOptions.filter, excludedIgnorecase);

    this.tmpLoadOptions = loadOptions;
    if (!this.disableUpdateOnNextCall) {
      this.tmpReportData = await service.dataReportList(id, loadOptions);
    }
    this.disableUpdateOnNextCall = false;
    return this.tmpReportData;
  }

  @action async getAllIdsForLastRequest(id: string, primaryKey: string, dataGrid) {
    const loadOptions = this.tmpLoadOptions;
    let hasGroup = false;
    for (let i = 0; i < dataGrid.columnCount(); i++) {
      if (dataGrid.columnOption(i, 'groupIndex') > -1) {
        hasGroup = true;
        break;
      }
    }
    if (hasGroup) {
      showErrorNotify('Настройки отчета не подходят', 'Уберите группировку');
      return [];
    }
    loadOptions.isLoadingAll = true;
    return service
      .dataReportList(id, loadOptions)
      .then((ans) => {
        return Array.from(new Set(ans.data.map((d) => d[primaryKey])));
      })
      .finally(() => {});
  }

  @action setUpdateReloadOnNextCall() {
    this.disableUpdateOnNextCall = true;
  }

  @computed get reportList() {
    if (!this._reportlist) {
      return [];
    }
    return this._reportlist.filter((it) => !it.isDefault);
  }

  @computed get defaultReportList() {
    if (!this._reportlist) {
      return [];
    }
    return this._reportlist.filter((it) => it.isDefault);
  }

  /* сохранение отчета */
  @action async saveReportList(data: CustomReportDetailsModel) {
    return await service.saveReportList(data);
  }

  /* создание отчета */
  @action async createReportList(data: CustomReportDetailsModel) {
    return await service.createReportList(data);
  }

  @action async loadInfoGroupList() {
    service.infoGroupList().then((ans) => {
      this.infogroupList = ans;
    });
  }

  @action async deleteReportList(id) {
    return await service.deleteReportList(id);
  }

  @computed
  get defaultSelected() {
    let result = [];
    this.infoblocksList.forEach((f) => {
      if (f.isService) {
        result = result.concat(f.fields);
      }
    });
    return result;
  }

  @action async loadInfoBlocksList(id: number) {
    this.isInfoBlockBusy = true;
    try {
      this.infoblocksList = await service.infoBlockList(id);
    } finally {
      this.isInfoBlockBusy = false;
    }
  }
  @action clearInfoblock() {
    this.infoblocksList = [];
  }

  @action setSelected(data) {
    this.selected = data;
    if (!data.length) this.selectedRepairId = [];
  }

  @action setSelectedRepairId(data: any[]) {
    this.selectedRepairId = data;
  }

  @action setSelectedRowsData(data) {
    this.selectedRowsData = data;
  }

  @action gotoItineraries(type: 'wagons' | 'containers') {
    const url = `/app#/dispatcher/itineraries/${type}?${qs.stringify({ w: this.selected })}`;
    designStore.adaptiveGoto(url);
  }

  @action gotoHold(type: 'wagons' | 'containers') {
    const url = `/app#/dispatcher/hold/${type}?${qs.stringify({ w: this.selected })}`;
    designStore.adaptiveGoto(url);
  }

  @action gotoReport(id: number, type: number, filter?: number) {
    const url = `/app#/dispatcher/reports/${id}/${type}/${filter ? filter : 'null'}?${qs.stringify({ w: this.selected })}`;
    designStore.adaptiveGoto(url);
  }

  @action gotoTranspose(id: string, type: string) {
    const url = `/app#/dispatcher/reports/${id}/${type}/null?${qs.stringify({ w: this.selected })}&transpose=true`;
    designStore.adaptiveGoto(url);
  }

  @action gotoMap(type: string) {
    const url = `/app#/map?${qs.stringify({ w: this.selected })}&type=${type}`;
    designStore.adaptiveGoto(url);
  }

  @action gotoInfo(type: string) {
    const url = `/app#/info?${qs.stringify({ w: this.selected })}&type=${type}`;
    designStore.adaptiveGoto(url);
  }

  @action copySelectedToClipboard() {
    const textArea = document.createElement('textarea');
    textArea.style.position = 'fixed';
    textArea.style.top = '0';
    textArea.style.left = '0';
    textArea.style.width = '2em';
    textArea.style.height = '2em';
    textArea.style.padding = '0';
    textArea.style.border = 'none';
    textArea.style.outline = 'none';
    textArea.style.boxShadow = 'none';
    textArea.style.background = 'transparent';
    textArea.value = this.selected.join(' ');
    document.body.appendChild(textArea);
    textArea.focus();
    textArea.select();

    try {
      const successful = document.execCommand('copy');
      const msg = successful ? 'successful' : 'unsuccessful';
    } catch (err) {
      console.log('Oops, unable to copy');
    }
    document.body.removeChild(textArea);
  }

  @action copyAllToClipboard(id: string, primaryKey: string, dataGrid) {
    this.getAllIdsForLastRequest(id, primaryKey, dataGrid).then((ids) => {
      const textArea = document.createElement('textarea');
      textArea.style.position = 'fixed';
      textArea.style.top = '0';
      textArea.style.left = '0';
      textArea.style.width = '2em';
      textArea.style.height = '2em';
      textArea.style.padding = '0';
      textArea.style.border = 'none';
      textArea.style.outline = 'none';
      textArea.style.boxShadow = 'none';
      textArea.style.background = 'transparent';
      textArea.value = ids.join(' ');
      document.body.appendChild(textArea);
      textArea.focus();
      textArea.select();

      try {
        const successful = document.execCommand('copy');
        const msg = successful ? 'successful' : 'unsuccessful';
        showSuccessNotify('', 'Скопировано номеров: ' + ids.length);
      } catch (err) {
        console.log('Oops, unable to copy');
      }
      document.body.removeChild(textArea);
    });
  }

  @action setMarksAndCatalogsOpen(data: boolean) {
    this.marksAndCatalogsOpen = data;
  }

  @action setOperationsOpen(data: boolean) {
    this.operationsOpen = data;
  }

  @action setAddRegistryOpen(data: boolean) {
    this.addRegistryOpen = data;
  }

  @action closeItineraryModal() {
    this.itineraryModalType = null;
  }

  @action openItineraryModal(type: 'plan' | 'current') {
    this.itineraryModalType = type;
  }

  @action setRepairsModalOpen(data: boolean) {
    this.repairsModalOpen = data;
  }

  @action setIsRemarkModalOpen(data: boolean) {
    this.isRemarkModalOpen = data;
  }

  @action async updateRemark(type: 'container' | 'wagon', values: { remark1?: string; remark2?: string }) {
    this.isBusy = true;
    try {
      await service.updateRemark(type, this.selected, values);
      notification.success({ message: 'Примечания успешно сохранены' });
      this.setIsRemarkModalOpen(false);
      await this.getReportList();
    } catch (e) {
      notification.error({ message: 'Ошибка сохранения примечания' });
    } finally {
      this.isBusy = false;
    }
  }

  @action async getRemarks(type: 'container' | 'wagon') {
    this.isBusy = true;
    try {
      this.selectedRemarkList = await service.getRemarks(type, this.selected);
    } catch (e) {
      notification.error({ message: 'Ошибка получения списка примечаний' });
    } finally {
      this.isBusy = false;
    }
  }

  @computed get selectedRemark(): SelectedRemark {
    return this.selectedRemarkList.flat().reduce(
      (prev, current, index) => {
        if (index === 0) {
          prev.remark1 = current.remark1;
          prev.remark2 = current.remark2;
        }
        if (prev.remark1 !== current.remark1) {
          prev.remark1 = null;
        }
        if (prev.remark2 !== current.remark2) {
          prev.remark2 = null;
        }
        return prev;
      },
      { remark1: null, remark2: null },
    );
  }

  @action toCountry(countryCode: number) {
    service.toCountry(countryCode, this.selected).then(() => {
      showSuccessNotify('Изменение страны', 'Изменение страны дислокации завершено успешно');
    });
  }

  handleContextMenuPreparing = (
    e,
    type,
    currentReportId: string,
    currentReportGroupId: string,
    primaryKey: string,
    component: DispatcherReport,
  ) => {
    if (e.target === 'content') {
      if (e.row && e.row.rowType === 'data') {
        const row = e.row.data;
        if (this.selected.length < 2) {
          this.selected = type == 'wagons' ? [row.wagonnumber] : [row.containernumber];
          if (row.repairid) {
            this.selectedRepairId = [{ repairid: row.repairid, wagonnumber: this.selected[0] }];
          }
        }
        if (!e.items) e.items = [];

        if (userStore.canAccess('dispatcher/loadregistry')) {
          e.items.push({
            text: 'Добавить в реестр погрузки',
            beginGroup: true,
            onItemClick: () => {
              this.setAddRegistryOpen(true);
            },
          });
          e.items.push({
            text: 'Не показывать клиента РП',
            onItemClick: () => {
              dispatcherService.cleanLoadRegistryClient(this.selected).then(() => {
                showSuccessNotify('Клиент РП скрыт в отчетах');
                component.reload();
              });
            },
          });
        }
        if (this.selected.length) {
          e.items.push({
            text: 'Примечания',
            onItemClick: (event) => {
              this.setIsRemarkModalOpen(true);
            },
          });
        }

        e.items.push({
          text: 'Копировать выбранные номера',
          beginGroup: true,
          onItemClick: () => {
            this.copySelectedToClipboard();
          },
        });
        e.items.push({
          text: 'Копировать все номера',
          beginGroup: false,
          onItemClick: () => {
            this.copyAllToClipboard(currentReportId, primaryKey, e.component);
          },
        });

        e.items.push({
          text: 'Фильтр по списку номеров',
          icon: this.filteredContainers.length || this.filteredWagons.length ? 'edit' : null,
          beginGroup: false,
          onItemClick: () => {
            this.numbersFilterModalVisible = true;
          },
        });

        e.items.push({
          text: 'Метки и подразделения',
          beginGroup: true,
          onItemClick: () => {
            this.setMarksAndCatalogsOpen(true);
          },
        });

        e.items.push({
          text: 'Режим поиска данных',
          beginGroup: false,
          items: [
            {
              text: 'Все данные',
              icon: this.jsonFieldsMode === 'all' ? 'check' : null,
              beginGroup: false,
              onItemClick: () => {
                this.jsonFieldsMode = 'all';
                component.forceUpdate();
              },
            },
            {
              text: 'Доступные в отчете',
              icon: this.jsonFieldsMode === 'available' ? 'check' : null,
              beginGroup: false,
              onItemClick: () => {
                this.jsonFieldsMode = 'available';
                component.forceUpdate();
              },
            },
          ],
        });

        e.items.push({
          text: 'Рейсы',
          beginGroup: true,
          onItemClick: () => {
            this.gotoItineraries(type);
          },
        });
        e.items.push({
          text: 'Простои по операциям',
          onItemClick: () => {
            this.gotoHold(type);
          },
        });

        e.items.push({
          text: 'История операций',
          onItemClick: () => {
            this.setOperationsOpen(true);
          },
        });

        e.items.push({
          text: 'Транспонировать выделенные',
          onItemClick: () => {
            this.gotoTranspose(currentReportId, currentReportGroupId);
          },
        });
        this.defaultReportList
          .filter((r) => (type == 'wagons' && [1, 3].includes(r.groupId)) || (type == 'containers' && r.groupId == 2))
          .forEach((r) => {
            e.items.push({
              text: r.name,
              onItemClick: () => {
                this.gotoReport(r.id, r.groupId);
              },
            });
          });

        const myReports = this.reportList.filter(
          (r) => (type == 'wagons' && [1, 3].includes(r.groupId)) || (type == 'containers' && r.groupId == 2),
        );
        if (myReports.length) {
          e.items.push({
            text: 'Мои отчеты',
            items: myReports.map((r) => {
              return {
                text: r.name,
                onItemClick: () => {
                  this.gotoReport(r.id, r.groupId);
                },
              };
            }),
          });
        }

        const repairIds = this.selectedRepairId;
        if (repairIds.length) {
          e.items.push({
            text: 'Документы ремонтов',
            beginGroup: true,
            onItemClick: () => {
              this.setRepairsModalOpen(true);
            },
          });
        }
        e.items.push({
          text: 'Показать на карте',
          beginGroup: true,
          onItemClick: () => {
            this.gotoMap(type);
          },
        });
        e.items.push({
          text: 'Подробная информация',
          beginGroup: false,
          onItemClick: () => {
            this.gotoInfo(type.replace('s', ''));
          },
        });

        if (type == 'wagons' && ['tech', 'admin', 'manager'].includes(userStore.userData.roleCode)) {
          e.items.push({
            text: 'Установить страну дислокации',
            items: companyStore.countries.map((c) => {
              return {
                text: c.fullName,
                onItemClick: () => {
                  this.toCountry(c.code);
                },
              };
            }),
          });
        }

        if (type == 'wagons') {
          e.items.push({
            text: 'Заявкa/Отправкa',
            items: [
              {
                text: 'Текущая',
                onItemClick: () => {
                  this.openItineraryModal('current');
                },
              },
              {
                text: 'Плановая',
                onItemClick: () => {
                  this.openItineraryModal('plan');
                },
              },
            ],
          });
        }
      }
    }
  };
}

export default new DispatcherStore();
