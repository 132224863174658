import { DefaultOptionType } from 'rc-select/lib/Select';
import { useMemo } from 'react';

import { OkpoModel } from '@stores/organizationsStore/organizationsStoreData';

export const useOkpoOptions = (data: OkpoModel[]): DefaultOptionType[] => {
  return useMemo(() => {
    return data.map(({ okpoId, shortName }) => ({ value: okpoId, label: shortName }));
  }, [data]);
};
