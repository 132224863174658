import { Layout } from 'antd';
import React from 'react';

import { dataSource } from '../../lib/data-source';
import { TransportRegisterHeader as Header } from '../header';
import { TransportRegisterTable as Table } from '../table';

export const TransportRegisterPage = () => {
  return (
    <>
      <Header dataSource={dataSource} />
      <Layout.Content style={{ height: HEIGHT }}>
        <Table dataSource={dataSource} />
      </Layout.Content>
    </>
  );
};

const HEIGHT = 'calc(100vh - var(--header-height))';
