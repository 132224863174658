import { CompanyCatalogModel } from '../../companyCatalogStore';
import { TrackingActiveBase, TrackingHistoryBase } from '../trackingStoreBase';

export class Wagon {
  public isvalid: boolean;
  public isontrack: boolean;
  public loading: boolean;
  constructor(public number: string) {
    this.isvalid = this.checkNumber(this.number);
  }

  private checkNumber(wnumber: string): boolean {
    // Проверка на содержание нецифровых символов и длины
    if (wnumber.length != 8 || wnumber.match(/\D+/g) != null) return false;
    // Проверка контрольного числа
    let addition = 0;
    let mask = 2;
    let num = 0;

    while (num < 7) {
      let digit = +wnumber[num] * mask;
      addition += (digit % 10) + Math.floor(digit / 10);

      mask = (mask * 2) % 3;
      num++;
    }

    addition %= 10;

    let myControlValue = addition == 0 ? 0 : 10 - addition;
    let controlValue = +wnumber[7];

    return myControlValue == controlValue;
  }
}

export interface ActiveWagon extends TrackingActiveBase {
  /// Вагон
  wagonNumber: number;
}

export interface HistoryWagon extends TrackingHistoryBase {
  /// Вагон
  wagonNumber: number;
}
export interface WagonAddRequest {
  numbers: number[];
  catalogs?: CompanyCatalogModel[];
  countryId: number;
  trackingDayCount?: number;
}

export interface WagonRemoveRequest {
  numbers: number[];
}
export default Wagon;
