import CustomStore from 'devextreme/data/custom_store';
import DataSource from 'devextreme/data/data_source';
import { action, makeObservable, observable } from 'mobx';
import moment from 'moment';

import { StationModel } from '../dictStore/dictStoreData';
import optionsStore from '../optionsStore/optionsStore';
import { TariffCalcResponse } from '../optionsStore/optionsStoreData';
import { TemplateOrgExpeditionExtParam } from '../organizationsStore/organizationsStoreData';
import {
  TariffAdditionalChargeJsonModel,
  TariffAttributeJsonModel,
  TariffCountryCoefficientJsonModel,
  TariffQuestionJsonModel,
} from '../sendsStore/sendData';
import service from './historyTariffInfoStore.service';

export type TariffRequestModel = {
  /// <summary>
  /// Идентификатор запроса
  /// </summary>
  tariffRequestId: string | null;

  globosoftRequestId: string;
  saveTariff: boolean;

  /// <summary>
  /// Идентификатор автора запроса рассчета тарифа
  /// </summary>
  userId: string | null;

  /// <summary>
  /// Дата рассчета (Период действия)
  /// </summary>
  calculateDate: any | null;

  /// <summary>
  /// Станция отправления
  /// </summary>
  loadStationCode: string | any | null;

  /// <summary>
  /// Станция назначения
  /// </summary>
  destStationCode: string | null;

  /// <summary>
  /// код етснг груза
  /// </summary>
  freightEtsngCode: string;

  /// <summary>
  /// код груза гнг из справочника RTS
  /// </summary>
  freightGngCode: string;

  /// <summary>
  /// Группа подвижного состава
  /// </summary>
  wagonTypeGroupCode: number | null;

  /// <summary>
  /// Код подвижного состава
  /// </summary>
  wagonTypeCode: number | null;

  /// <summary>
  ///  Признак порожнего (0 - груженый, 1 - порожний)
  /// </summary>
  empty: number | null;

  emptyBool: boolean;

  /// <summary>
  /// Опасность
  /// </summary>
  dangerCategoryCode: number | null;

  /// <summary>
  /// Негабаритность
  /// </summary>
  oversizeCategoryCode: string | null;

  /// <summary>
  /// перегрузка
  /// </summary>
  overloadCategoryCode: string | null;

  /// <summary>
  /// упаковка
  /// </summary>
  packagingCode: number | null;

  /// <summary>
  /// скорость
  /// </summary>
  speedTypeCode: number | null;

  /// <summary>
  /// Кол-во в отправке
  /// </summary>
  wagonCount: number | null;

  /// <summary>
  /// Грузоподъемность
  /// </summary>
  freightCapacity: number | null;

  /// <summary>
  /// Реальный вес
  /// </summary>
  freightWeight: number | null;

  /// <summary>
  /// Вид отправки (Повагонная)
  /// </summary>
  quantityShippingTypeCode: number | null;

  /// <summary>
  /// Вид отправки (одиночная)
  /// </summary>
  scaleShippingTypeCode: number | null;

  /// <summary>
  /// Принадлежность
  /// </summary>
  wagonAffiliationTypeCode: number | null;

  //Указание Количество п/с в сцепке/рефсекци
  //По аналогии с кол-вом в отправке
  couplerWagonCount: number;

  //Указание кол-ва осей
  axesCount: number;

  tariffData: TariffData | null;
  globosoftCountryCode: string;
  gngOrEtsng: 'etsng' | 'gng' | null;
  oversizePoints: string | null;
  commonWeight: number | null;
  fgk: boolean;
  /// Выбранные экспедиторские настройки тарифа
  enabledExpeditorSettingIds?: string[];
  /// Все экспедиторские настройки тарифа
  expeditorSettings?: TemplateOrgExpeditionExtParam[];

  collectiveShipmentFreightList?: CollectiveShipmentFreight[];
  borderStations?: StationModel[];

  calculateEmptyReturn?: boolean;

  /// Стартовый вес груза
  weightStepperStart?: number;
  /// Шаг изменения веса
  weightStepperStep?: number;
  /// Конечный вес груза
  weightStepperEnd?: number;

  groupDetail?: TariffRequestWagonGroupDetails[];
};

export interface CollectiveShipmentFreight {
  etsngCode: string;
  gngCode: string;
  weight: number;
}

export interface TariffRequestWagonGroupDetails {
  freightWeight: number;
  freightCapacity: number;
}

export const defaultTariffRequest = (): TariffRequestModel => {
  return {
    tariffData: {
      borderStationCodes: [],
      escort: [],
      attributes: [],
      coefficients: [],
      questions: [],
      additionalCharges: [],
    },
    tariffRequestId: null,
    userId: null,
    calculateDate: moment(),
    loadStationCode: null,
    destStationCode: null,
    freightEtsngCode: null,
    freightGngCode: null,
    wagonTypeGroupCode: 1,
    wagonTypeCode: 1,
    empty: null,
    dangerCategoryCode: null,
    oversizeCategoryCode: null,
    overloadCategoryCode: null,
    packagingCode: null,
    speedTypeCode: 1,
    wagonCount: 1,
    freightCapacity: 70,
    freightWeight: null,
    quantityShippingTypeCode: 0,
    scaleShippingTypeCode: 2,
    wagonAffiliationTypeCode: 1,
    couplerWagonCount: 1,
    axesCount: 4,
    globosoftCountryCode: '01',
    emptyBool: false,
    globosoftRequestId: null,
    saveTariff: false,
    gngOrEtsng: null,
    oversizePoints: null,
    commonWeight: null,
    fgk: false,
  };
};

export interface TariffEscortJsonModel {
  /// Тип сопровождения
  type: string;

  /// Код принадлежности
  affiliationCode: number;

  /// Код страны
  countryCode: string;

  /// Кол-во осей
  axelCount: number;
}

export interface TariffData {
  /// станции погран переходов
  borderStationCodes: StationModel[];
  escort: TariffEscortJsonModel[];

  attributes: TariffAttributeJsonModel[];
  coefficients: TariffCountryCoefficientJsonModel[];
  questions: TariffQuestionJsonModel[];
  additionalCharges: TariffAdditionalChargeJsonModel[];
}

export type TariffHistoryModel = {
  request: TariffRequestModel;
  response: TariffCalcResponse;
};

class HistoryTariffInfoStore {
  @observable userRequestHistory: DataSource = new DataSource({
    store: new CustomStore({
      key: 'usertariffrequestid',
      load: function (loadOptions: any) {
        return service
          .getUserRequestHistory(loadOptions)
          .then((data) => {
            data.data.forEach((d) => {
              if (d.items && d.items.length) {
                d.count = d.items.length;
              }
            });
            return data;
          })
          .catch(() => {
            throw 'Data Loading Error';
          });
      },
      remove: (key) => {
        return service.deleteHistoryTariffInfo(key);
      },
      update: (key, values) => {
        return Promise.all([]);
      },
    }),
  });

  @observable selected: any[] = [];

  constructor() {
    makeObservable(this);
  }

  @action setSelected(selected: any[]) {
    this.selected = selected;
  }

  @action async getHistoryTariffInfo(tariffRequestId: string): Promise<TariffHistoryModel> {
    let res = await service.getHistoryTariffInfo(tariffRequestId);
    optionsStore.setResult(res.response);
    res.response = optionsStore.result;
    optionsStore.isTariffCalculated = false;
    return res;
  }

  @action async deleteHistoryTariffInfo(tariffRequestId: string): Promise<TariffHistoryModel> {
    return await service.deleteHistoryTariffInfo(tariffRequestId);
  }

  @action async addToArchive(ids: string[]): Promise<void> {
    return await service.addToArchive(ids);
  }
}

export default new HistoryTariffInfoStore();
