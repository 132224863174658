import {
  BidRegistryKind,
  BidRegistryServiceType,
  BidRegistryTeuType,
} from '@components/routed/RegisterOfBetsCreatePage/ui/basic-tab-basics-fields/basic-tab-basics-fields.types';

export type BidRegistryDto = {
  /**
   * Идентификатор ставки
   */
  bidRegistryId?: string;

  /**
   * Статус (устанавливается пользователем через отдельный ендпоинт)
   */
  state: BidRegistryState;

  /**
   * Компания - владелец ставки
   */
  companyId: string;

  /**
   * Организация - Клиент
   */
  organizationId: string;

  /**
   * Ссылка на договор (необязательное поле)
   */
  contractId?: string;

  /**
   * Услуга. Предоставление вагона / Предоставление вагона с оплатой тарифа
   */
  serviceType: BidRegistryServiceType;

  /**
   * Вид ставки На технический рейс (ТР) / Сутки
   * На технический рейс - ставка за перевозку на фиксированном плече от станции А до станции Б
   * Сутки - ставка указывается за сутки использования вагона
   */
  bidKind: BidRegistryKind;

  /**
   * Тип ставки За единицу транспорта / за тонну перевозимого груза Выпадающий список (доступен для типа услуг -предоставление вагона и ТЭУ)
   */
  bidTeuType?: BidRegistryTeuType;

  /**
   * Период действия ставки ОТ Дата В рамках диапазона дат действия договора ( если выбран договор)
   */
  dateBegin: Date;

  /**
   * Период действия ставки ДО Дата В рамках диапазона дат действия договора ( если выбран договор)
   */
  dateEnd: Date;

  /**
   * Наименование РПС Наименование РПС из справочника Выбор РПС возможен из допустимых значений РПС , указанных в доп.соглашении к договору при наличии договора. Возможна ручная корректировка поля.
   */
  rps: string;

  /**
   * Обобщенные характеристики из справочника Необязательное поле. Необходимо использовать один справочник с Заявками ИЛСИ
   */
  wagonProps: string;

  /**
   * Модель вагона Выбор из справочника моделей вагонов Необязательное поле. Множественный выбор. Необходимо использовать один справочник с Заявками ИЛСИ
   */
  wagonModels: string[];

  /**
   * Объем кузова ОТ - ДО Числовые поля, одна цифра после запятой Необязательное поле. Аналогично блоку Заявки на перевозки
   */
  wagonVolumeMax: number;
  wagonVolumeMin: number;

  /**
   * Грузоподъемность ОТ - ДО Целочисленные поля Необязательное поле. Аналогично блоку Заявки на перевозки
   */
  wagonLoadMax: number;
  wagonLoadMin: number;

  /**
   * Негабаритность Код негабаритности Н6638 (указаны максимально допустимые значения в последовательности Н + нижняя негабаритность(6) + боковая негабартиность(6) + верхняя негабаритность (3) + сверхнегабаритность (8))
   */
  oversize: string;

  /**
   * Станция отправления (ТР) Выбор из справочника станций Одна станция
   */
  loadStationCode: number;

  /**
   * Станция / назначения (ТР) Выбор из справочника станций Множественный выбор
   */
  destStationCodes: number[];

  /**
   * Также как в приложении Простои
   */
  conditionDowntime: string;
  downtimeCalculation: string;
  loadNormDays: number;
  destNormDays: number;
  doubleOperationNormDays: number;
  downtimePrice: number;
  created: Date;
  creatorId: string;

  // Для расчета тарифа
  wagonGroupCode: number;
  wagonTypeCode: number;
  freightWeight?: number;
  wagonCount?: number;
  volume?: number;
  isHalfWagonInnovative?: boolean;
  affiliationCode?: number;

  freightList: BidRegistryEtsngGngDbModel[];
  currencyCode: string;
} & { validity: [Date, Date] };

type BidRegistryEtsngGngDbModel = {
  bidRegistryId?: string;
  etsng: string;
  gng: string;
};

export enum BidRegistryState {
  draft = 'draft',
  onagr = 'onagr',
  agr = 'agr',
}

export type CurrentCurrency = {
  code: string;
  name: string;
  shortName: string;
};
