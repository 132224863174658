import { Collapse, Modal } from 'antd';
import { observer } from 'mobx-react';
import React from 'react';

import { TotalModalProps } from '@components/routed/RegisterOfBetsCreatePage/ui/total-modal/total-modal.types';
import { CommissionTable } from '@components/routed/RegisterOfBetsCreatePage/ui/total-modal/ui/commission-table';
import styles from '@components/routed/RegisterOfBetsCreatePage/ui/wagons-service/wagon-services.module.scss';

import { OwnersTable } from './ui/owners-table';
import { RentsTable } from './ui/rents-table';

export const TotalModal = observer(({ isExpedition, isWagonsExpedition, isWagons, ...modalProps }: TotalModalProps) => {
  return (
    <Modal title="Итог" okText="Сохранить" {...modalProps} width="auto">
      {isWagons && <WagonsTotal />}
      {isExpedition && <ExpeditionsTotal />}
      {isWagonsExpedition && (
        <>
          <ExpeditionsTotal />
        </>
      )}
    </Modal>
  );
});

const WagonsTotal = () => (
  <Collapse>
    <Collapse.Panel className={styles.infologCollapse} key="owner" header="Ставка за собственный ПС">
      <OwnersTable />
    </Collapse.Panel>
    <Collapse.Panel className={styles.infologCollapse} key="rent" header="Ставка за привлеченный ПС">
      <RentsTable />
    </Collapse.Panel>
  </Collapse>
);

const ExpeditionsTotal = () => (
  <Collapse>
    <Collapse.Panel className={styles.infologCollapse} key="expedition" header="Экспедиции">
      <CommissionTable />
    </Collapse.Panel>
  </Collapse>
);
