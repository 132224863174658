import { Form } from 'antd';
import React from 'react';

import { FieldInputNumber } from '@components/fw/Fields/input-number';

import companyStore from '@stores/companyStore/companyStore';
import { supplierBidRegistryStore } from '@stores/supplierBidRegistryStore';

export const renderPrice = (freightEtsngName: string, _: any, index: number) => {
  const name = [freightEtsngName, index, 'priceWithoutNds'] as any;
  return <FieldInputNumber min={0} name={name} />;
};

export const renderNds = (freightEtsngName: string, _: any, index: number) => {
  const name = [freightEtsngName, index, 'priceWithoutNds'] as any;
  return (
    <Form.Item dependencies={[name]}>
      {({ getFieldValue }) => {
        const ndsType = supplierBidRegistryStore.supplierBidRegistry.ndsType;
        const price = getFieldValue(name);
        const result = companyStore.nds.getNds(ndsType, price);
        return <>{!!result ? toLocalString(result) : '0'}</>;
      }}
    </Form.Item>
  );
};

export const renderPriceWithNds = (freightEtsngName: string, _: any, index: number) => {
  const name = [freightEtsngName, index, 'priceWithoutNds'] as any;
  return (
    <Form.Item dependencies={[name]}>
      {({ getFieldValue }) => {
        const ndsType = supplierBidRegistryStore.supplierBidRegistry.ndsType;
        const price = getFieldValue(name);
        const result = companyStore.nds.getPriceWithNds(ndsType, price);
        return <>{!!result ? toLocalString(result) : price ?? 0}</>;
      }}
    </Form.Item>
  );
};

const toLocalString = (value: number) => value.toLocaleString('ru', { maximumFractionDigits: 2 });
