import { Form } from 'antd';
import React from 'react';

import FloatInputNumber from '@components/fw/FloatLabel/FloatInputNumber';

import companyStore from '@stores/companyStore/companyStore';

export const PriceNds = ({ name }: { name: number }) => {
  return (
    <Form.Item
      noStyle
      dependencies={[
        ['extraServices', name, 'price'],
        ['extraServices', name, 'ndsType'],
      ]}>
      {({ getFieldValue }) => <FloatInputNumber value={getNds(name, getFieldValue)} disabled placeholder="НДС" />}
    </Form.Item>
  );
};

const getNds = (name: number, getFieldValue: any) => {
  const price = getFieldValue(['extraServices', name, 'price']);
  const ndsType = getFieldValue(['extraServices', name, 'ndsType']);

  return (
    companyStore.nds.getNds(ndsType, price)?.toLocaleString('ru', {
      maximumFractionDigits: 2,
    }) ?? 0
  );
};
