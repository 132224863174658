import { Layout } from 'antd';
import { observer } from 'mobx-react';
import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';

import AdaptiveCard from '@components/fw/AdaptiveElements/AdaptiveCard';
import { PageType } from '@components/routed/RegisterOfBetsCreatePage/register-of-bets-create-page.types';

import companyStore from '@stores/companyStore/companyStore';
import dictStore from '@stores/dictStore';
import optionsStore from '@stores/optionsStore/optionsStore';
import organizationsStore from '@stores/organizationsStore/organizationsStore';
import { registerOfBetsStore } from '@stores/registerOfBetsStore';
import userStore from '@stores/userStore';
import usersStore from '@stores/usersStore/usersStore';

import styles from './register-of-bets-create-pate.module.scss';
import { RegisterOfBetsCreateHeader as Header } from './ui/header';
import { RegisterOfBetsCreateTabs as Tabs } from './ui/tabs';

export const RegisterOfBetsCreatePage = observer(() => {
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const { pathname } = useLocation();
  const [_, PAGE_TYPE, bidId] = pathname.split('/').filter(Boolean);
  const isEdit = PAGE_TYPE === PageType.EDIT;

  useEffect(() => {
    setIsLoading(true);

    const additionalPromises = isEdit ? [dictStore.loadCurrencyList(), registerOfBetsStore.getBidRegistryById(bidId)] : null;

    Promise.all([
      usersStore.getUsers({ notRoles: ['org'] }),
      organizationsStore.getUserOrganizations(),
      optionsStore.getTariffDictionary(),
      additionalPromises,
    ])
      .then(() => {
        companyStore.getNdsTypes(isEdit ? registerOfBetsStore.bidRegistry.companyId : userStore?.userData?.companyId);
        registerOfBetsStore.setCurrency(registerOfBetsStore.bidRegistry?.currencyCode);
      })
      .finally(() => setIsLoading(false));

    return registerOfBetsStore.clearBidRegistry;
  }, []);

  return (
    <>
      <Header />
      <Layout.Content style={{ height: HEIGHT }}>
        <AdaptiveCard loading={isLoading} className={styles.turnOffAlignment}>
          <Tabs pageType={PAGE_TYPE as PageType} />
        </AdaptiveCard>
      </Layout.Content>
    </>
  );
});

const HEIGHT = 'calc(100vh - var(--header-height))';
