import { action, computed, makeObservable, observable } from 'mobx';
import moment from 'moment';

import logo from '@assets/logos/logoMax.svg';
import logoGtp from '@assets/logos/logoMaxGtp.svg';
import logoIls from '@assets/logos/logoMaxIls.svg';
import logoItk from '@assets/logos/logoMaxItk.svg';
import logoMin from '@assets/logos/logoMin.svg';
import logoMinGtp from '@assets/logos/logoMinGtp.svg';
import logoMinIls from '@assets/logos/logoMinIls.svg';
import logoMinItk from '@assets/logos/logoMinItk.svg';

const MIN_HEIGHT = 500;
export type LOGO_TYPE = 'INFOLOG' | 'GTP' | 'ITK' | 'ILS';

class DesignStore {
  @observable private _windowInnerHeight: number = window.innerHeight;
  @observable private _windowInnerWidth: number = window.innerWidth;
  @observable sidebarCollapsed: boolean = false;
  @observable width: number = window.innerWidth;
  @observable isMobile: boolean = window.innerHeight > window.innerWidth;
  @observable isCompactReports: boolean = false;
  @observable isFormattedNameCells: boolean = false;
  @observable height: number = window.innerHeight;
  @observable logoType: LOGO_TYPE = 'INFOLOG';
  colors = ['gold', 'lime', 'green', 'cyan'];
  constructor() {
    makeObservable(this);
    this.isCompactReports = localStorage.getItem('is-compact-reports') === 'true';
    this.isFormattedNameCells = localStorage.getItem('is-formatted-name-cells') === 'true';
    this.isMobile = localStorage.getItem('is-mobile') === 'true';
    this.sidebarCollapsed = localStorage.getItem('sidebar-collapsed') === 'true';

    document.documentElement.style.setProperty('--reports-height', `${this.isCompactReports ? 27 : 36}px`);
    document.title = 'Инфолог';
    let link = document.querySelector("link[rel~='icon']");
    if (!link) {
      link = document.createElement('link');
      //@ts-ignore
      link.rel = 'icon';
      document.getElementsByTagName('head')[0].appendChild(link);
    }
    if (window.location.href.includes('gruzitransport.pro')) {
      this.logoType = 'GTP';
      document.title = 'Lagit.pro';
      //@ts-ignore
      link.type = 'image/svg+xml';
      //@ts-ignore
      link.href = '/app/favicon/faviconGtp.svg';
    }
    if (window.location.href.includes('infotranscom.ru')) {
      this.logoType = 'ITK';
      document.title = 'ИТК';
      //@ts-ignore
      link.type = 'image/svg+xml';
      //@ts-ignore
      link.href = '/app/favicon/faviconItk.svg';
    }
    if (window.location.href.includes('transport.ilsi.pro') || window.location.href.includes('is.ilsi.pro')) {
      this.logoType = 'ILS';
      document.title = 'ИЛС';
      //@ts-ignore
      link.type = 'image/svg+xml';
      //@ts-ignore
      link.href = '/app/favicon/faviconIls.svg';
    }

    this.toggleSidebar = this.toggleSidebar.bind(this);
    this.setMobile = this.setMobile.bind(this);
    this.updateWindowDimensions = this.updateWindowDimensions.bind(this);
    this.setCompactReports = this.setCompactReports.bind(this);
  }

  @action adaptiveGoto(url: string) {
    if (this.isMobile) {
      window.location.href = url;
    } else {
      const win = window.open();
      setTimeout(() => {
        win.location.href = url;
      }, 100);
    }
  }

  get logoMax() {
    switch (this.logoType) {
      case 'GTP':
        return logoGtp;
      case 'INFOLOG':
        return logo;
      case 'ITK':
        return logoItk;
      case 'ILS':
        return logoIls;
    }
  }

  get logoMin() {
    switch (this.logoType) {
      case 'GTP':
        return logoMinGtp;
      case 'INFOLOG':
        return logoMin;
      case 'ITK':
        return logoMinItk;
      case 'ILS':
        return logoMinIls;
    }
  }

  @action updateWindowDimensions() {
    this.width = window.innerWidth;
    this.height = window.innerHeight;
    let vh = window.innerHeight * 0.01;
    document.documentElement.style.setProperty('--vh', `${vh}px`);
  }

  @action changeHeight(height: number) {
    this._windowInnerHeight = height;
  }

  @action setSidebar(data: boolean) {
    this.sidebarCollapsed = data;
    localStorage.setItem('sidebar-collapsed', `${data}`);
  }

  @action toggleSidebar() {
    this.setSidebar(!this.sidebarCollapsed);
  }

  @computed get windowInnerWidth() {
    return this._windowInnerWidth;
  }

  @computed get windowInnerHeight() {
    if (this._windowInnerHeight > MIN_HEIGHT) {
      return this._windowInnerHeight;
    } else {
      return MIN_HEIGHT;
    }
  }

  @action setCompactReports(value: boolean) {
    this.isCompactReports = value;
    localStorage.setItem('is-compact-reports', `${this.isCompactReports}`);
    document.documentElement.style.setProperty('--reports-height', `${this.isCompactReports ? 27 : 36}px`);
  }

  @action setFormattedNameCells(value: boolean) {
    this.isFormattedNameCells = value;
    localStorage.setItem('is-formatted-name-cells', `${this.isFormattedNameCells}`);
  }

  @action setMobile(e: boolean) {
    if (e || window.innerHeight > window.innerWidth) {
      this.isMobile = true;
      localStorage.setItem('is-mobile', `${this.isMobile}`);
      return true;
    } else {
      this.isMobile = false;
      localStorage.setItem('is-mobile', `${this.isMobile}`);
      return false;
    }
  }

  @computed
  get toolbarWidth() {
    return this.sidebarCollapsed && this.isMobile
      ? 'calc(100vw - 4rem)'
      : !this.sidebarCollapsed && !this.isMobile
        ? 'calc(100vw - 19rem)'
        : !this.sidebarCollapsed && this.isMobile
          ? 'calc(100vw - 21rem)'
          : this.sidebarCollapsed && !this.isMobile
            ? 'calc(100vw - 22rem)'
            : 'calc(100vw - 50rem)';
  }

  @computed get greeting() {
    const time = moment().hour();
    if (time > 5 && time <= 11) {
      return 'Доброе утро';
    } else if (time > 11 && time <= 16) {
      return 'Добрый день';
    } else if (time > 16 && time <= 23) {
      return 'Добрый вечер';
    } else if (time <= 5) {
      return 'Доброй ночи';
    } else {
      return 'Здравствуйте';
    }
  }
}

export default new DesignStore();
