import { DefaultOptionType } from 'rc-select/lib/Select';

export interface CompanySmtpSettingsServiceModel {
  senderName: string;
  login: string;
  password: string;
  host: string;
  port: number;
  isUseSystemSmtp: boolean;
}

export type CountryModel = {
  code: number;
  fullName: string;
  iso3166: number;
};

export type WagonOrContainer = 'wagon' | 'container';

export enum CompanyNdsType {
  without = 'without',
  nds0 = 'nds0',
  nds10 = 'nds10',
  nds12 = 'nds12',
  nds14 = 'nds14',
  nds15 = 'nds15',
  nds18 = 'nds18',
  nds20 = 'nds20',
  nds21 = 'nds21',
}

export type CompanyNdsTypeOption = DefaultOptionType & { value: CompanyNdsType };

export class CompanyNds {
  allTypes: CompanyNdsType[] = [];
  allOptions: CompanyNdsTypeOption[] = [];

  types: CompanyNdsType[] = [];
  options: CompanyNdsTypeOption[] = [];

  labels: Record<CompanyNdsType, string> = {} as Record<CompanyNdsType, string>;

  private _multiplier: Record<CompanyNdsType, number> = {} as Record<CompanyNdsType, number>;

  constructor() {
    for (const [key, ndsType] of Object.entries(CompanyNdsType)) {
      const parts = key.split('nds');
      this.labels[ndsType] = ndsType === CompanyNdsType.without ? 'Без НДС' : `${parts[1]}%`;
      this._multiplier[ndsType] = ndsType === CompanyNdsType.without ? 0 : +(+parts[1] / 100).toFixed(2);

      this.allTypes.push(ndsType);
      this.allOptions.push({ label: this.labels[ndsType], value: ndsType });
    }
  }

  set = (ndsTypes: CompanyNdsType[]) => {
    this.types = ndsTypes;
    this.options = ndsTypes.map((ndsType) => ({ label: this.labels[ndsType], value: ndsType }));
  };

  getNds = (ndsType: CompanyNdsType | undefined, price: number | undefined) => {
    if (!price || !ndsType) return;
    return price * this._multiplier[ndsType];
  };

  getPriceWithNds = (ndsType: CompanyNdsType | undefined, price: number | undefined) => {
    if (!price || !ndsType) return price;
    return price + price * this._multiplier[ndsType];
  };
}
