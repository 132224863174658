import { Spin } from 'antd';
import DataGrid, { Column, GroupPanel, HeaderFilter, StateStoring } from 'devextreme-react/data-grid';
import moment from 'moment';
import React, { Component } from 'react';

import CatalogDxCell from '@components/fw/DxCellTemplates/CatalogDxCell';

import companyCatalogStore from '@stores/companyCatalogStore/companyCatalogStore';
import { GLOBAL_DATE_FORMAT } from '@stores/optionsStore/optionsStoreData';
import trackingContainerStore from '@stores/trackingStore/container/trackingContainerStore';
import { TrackingActiveBase } from '@stores/trackingStore/trackingStoreBase';
import trackingWagonStore from '@stores/trackingStore/wagon/trackingWagonStore';
import userStore from '@stores/userStore/userStore';

interface TrackingActiveTableProps {
  value: TrackingActiveBase[];
  isBusy: boolean;
  type: 'wagon' | 'container';
  onSelectionChanged?(event): void;
}

interface TrackingActiveTableState {}
function testCatalog(rowData) {
  if (!rowData.catalogs) return null;
  return rowData.catalogs.map((d) => d.id);
}

class TrackingActiveTable extends Component<TrackingActiveTableProps, TrackingActiveTableState> {
  dataGrid: DataGrid;
  componentDidMount(): void {
    if (userStore.userData.companyId) {
      companyCatalogStore.getCompanyCatalogs(userStore.userData.companyId);
    }
    trackingContainerStore.clearSelected();
    trackingWagonStore.clearSelected();
  }

  render() {
    const data = this.props.value;
    const isBusy = this.props.isBusy;
    const showCompany = !userStore.isOrgOrUser;
    const height = 'calc(100vh - var(--padding))';

    return (
      <Spin spinning={isBusy}>
        <DataGrid
          height={height}
          allowColumnReordering={true}
          dataSource={data}
          ref={(ref) => (this.dataGrid = ref)}
          loadPanel={{ enabled: false }}
          allowColumnResizing={true}
          columnResizingMode={'widget'}
          columnAutoWidth={true}
          showBorders={true}
          showColumnLines={true}
          columnMinWidth={30}
          showRowLines={true}
          rowAlternationEnabled={true}
          onSelectionChanged={this.handleSelectionChanged}
          onRowDblClick={this.handleRowDoubleClick}
          onContextMenuPreparing={this.handleContextMenuPreparing}
          selection={{ mode: 'multiple' }}>
          <StateStoring enabled={true} type="localStorage" storageKey={userStore.userData.userId + 'trackingActiveTable'} />
          <GroupPanel visible={true} />
          <HeaderFilter visible={true} allowSearch={true} />
          <Column allowFixing={true} dataField={'number'} caption={'Номер'} />
          <Column allowFixing={true} dataField="country" caption={'Страна'} />
          <Column allowFixing={true} dataField="road" caption={'Дорога'} />
          {showCompany && <Column allowFixing={true} dataField="company" caption={'Компания'} groupIndex={0} />}
          <Column allowFixing={true} dataField="creator" caption={'Постановщик'} />
          <Column allowFixing={true} dataField="date" caption={'Дата создания'} dataType={'date'} />
          <Column
            allowFixing={true}
            dataField="catalogs"
            caption={'Подразделения'}
            headerFilter={{
              dataSource: companyCatalogStore.companyCatalogs.map((x) => {
                return { value: x.id, text: x.title };
              }),
            }}
            calculateFilterExpression={(value, selectedFilterOperations, target) => {
              if (target === 'headerFilter') {
                return [testCatalog, 'contains', value];
              }
            }}
            cellRender={CatalogDxCell}
          />
          <Column
            allowFixing={true}
            dataField="trackingExpirationDate"
            caption={'Слежение до'}
            dataType={'date'}
            customizeText={(e) => {
              if (e.value) return moment(e.value).format(GLOBAL_DATE_FORMAT);
              else return '∞';
            }}
          />
        </DataGrid>
      </Spin>
    );
  }
  handleSelectionChanged = ({ selectedRowsData }: any) => {
    const data = selectedRowsData;
    if (this.props.onSelectionChanged) this.props.onSelectionChanged(data);
  };

  handleRowDoubleClick = () => {};

  handleContextMenuPreparing = () => {};
}
export default TrackingActiveTable;
