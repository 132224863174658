import PivotGrid, { Export, FieldChooser } from 'devextreme-react/pivot-grid';
import { dxElement } from 'devextreme/core/element';
import { exportPivotGrid } from 'devextreme/excel_exporter';
import dxPivotGrid from 'devextreme/ui/pivot_grid';
import PivotGridDataSource from 'devextreme/ui/pivot_grid/data_source';
import { saveAs } from 'file-saver';
import React, { Component } from 'react';
import { RouteComponentProps } from 'react-router';

import AdaptiveCard from '@components/fw/AdaptiveElements/AdaptiveCard';

import { WagonBros } from '@stores/dispatcherStore/BrosData';
import wagonOperationService from '@stores/dispatcherStore/wagonOperation.service';

import './BrosPage.less';

interface State {
  data: WagonBros[];
  loading: boolean;
}

class BrosPage extends Component<RouteComponentProps, State> {
  ExcelJs: any;
  constructor(props: RouteComponentProps) {
    super(props);
    import('exceljs').then((e) => {
      this.ExcelJs = e;
    });
    this.state = {
      data: [],
      loading: false,
    };
  }

  componentDidMount() {
    document.title = 'Брошенные';
    this.loadData();
  }

  loadData = () => {
    this.setState({ loading: true });
    wagonOperationService
      .wagonGetBros()
      .then((ans) => {
        this.setState({ data: ans });
      })
      .finally(() => this.setState({ loading: false }));
  };
  isDataCell(cell) {
    return cell.area === 'data' && cell.rowType === 'D' && cell.columnType === 'D';
  }

  isTotalCell(cell) {
    return (
      cell.type === 'T' ||
      cell.type === 'GT' ||
      cell.rowType === 'T' ||
      cell.rowType === 'GT' ||
      cell.columnType === 'T' ||
      cell.columnType === 'GT'
    );
  }

  onExporting = (e: { component?: dxPivotGrid; element?: dxElement; model?: any; fileName?: string; cancel?: boolean }) => {
    const workbook = new this.ExcelJs.Workbook();
    const worksheet = workbook.addWorksheet('Sales');
    e.cancel = true;

    exportPivotGrid({
      component: e.component,
      worksheet,
      customizeCell: ({ pivotCell, excelCell }) => {
        if (this.isTotalCell(pivotCell)) {
          Object.assign(excelCell, this.getExcelCellFormat({ fill: 'F2F2F2', font: '3F3F3F', bold: true }));
        }

        const borderStyle = { style: 'thin', color: { argb: 'FF7E7E7E' } };
        excelCell.border = {
          bottom: borderStyle,
          left: borderStyle,
          right: borderStyle,
          top: borderStyle,
        };
      },
    }).then(() => {
      workbook.xlsx.writeBuffer().then((buffer) => {
        saveAs(new Blob([buffer], { type: 'application/octet-stream' }), 'Брошенные.xlsx');
      });
    });
  };

  getExcelCellFormat({ fill, font, bold }) {
    return {
      fill: { type: 'pattern', pattern: 'solid', fgColor: { argb: fill } },
      font: { color: { argb: font }, bold },
    };
  }

  render() {
    const dataSource = new PivotGridDataSource({
      fields: [
        {
          caption: 'Дорога дислокации',
          width: 150,
          dataField: 'dislRoadName',
          area: 'row',
          expanded: true,
          wordWrapEnabled: false,
          //sortBySummaryField: 'Total',
        },
        {
          caption: 'Станция дислокации',
          dataField: 'dislStationName',
          width: 150,
          area: 'row',
          wordWrapEnabled: false,
          expanded: true,
        },
        {
          caption: 'Индекс',
          dataField: 'trainIndex',
          width: 150,
          area: 'row',
          wordWrapEnabled: false,
          expanded: true,
        },
        {
          caption: 'Дорога назначения',
          dataField: 'destRoadName',
          width: 150,
          area: 'row',
          wordWrapEnabled: false,
          expanded: true,
        },
        {
          caption: 'Станция назначения',
          dataField: 'destStationName',
          width: 150,
          area: 'row',
          wordWrapEnabled: false,
          expanded: true,
        },
        {
          caption: 'Распорядитель',
          dataField: 'operatorName',
          width: 150,
          area: 'row',
          wordWrapEnabled: false,
          expanded: false,
        },
        {
          caption: 'Вагон',
          dataField: 'wagonNumber',
          width: 150,
          area: 'row',
          wordWrapEnabled: false,
          expanded: true,
        },
        {
          caption: 'Итог',
          dataField: 'wagonNumber',
          dataType: 'number',
          summaryType: 'count',
          area: 'data',
        },
      ],
      store: this.state.data,
    });
    return (
      <AdaptiveCard title={'Брошенные'} loading={this.state.loading}>
        <PivotGrid
          id="pivotgrid"
          dataSource={dataSource}
          allowSortingBySummary={true}
          allowFiltering={true}
          showBorders={true}
          showColumnTotals={true}
          showColumnGrandTotals={true}
          showRowTotals={true}
          showRowGrandTotals={true}
          onExporting={this.onExporting}>
          <FieldChooser enabled={false}></FieldChooser>
          <Export enabled={true} />
        </PivotGrid>
      </AdaptiveCard>
    );
  }
}

export default BrosPage;
