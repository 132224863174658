import { Observer } from 'mobx-react';
import React from 'react';

import { FieldCheckbox } from '@components/fw/Fields/checkbox';
import { FieldInputNumber } from '@components/fw/Fields/input-number';
import { FieldSelect } from '@components/fw/Fields/select';

import { BidRegistryCommercialStore } from '@stores/bidRegistryCommercialStore';
import { expeditionGroupName } from '@stores/bidRegistryCommercialStore/bidRegistryCommercialStore.lib';
import { TariffCountryRangeInfo } from '@stores/claimsStore/ClaimData';
import companyStore from '@stores/companyStore/companyStore';

export const renderIsActive = (_, { freightEtsngCode, countryCode, loadStationCode, destStationCode }: TariffCountryRangeInfo) => {
  const name = `${expeditionGroupName}-isActive-${freightEtsngCode}-${loadStationCode}-${destStationCode}-${countryCode}`;
  return (
    <FieldCheckbox
      onChange={(value) => BidRegistryCommercialStore.changeFieldValueFlatRollingStocks(name, value.target.checked)}
      name={name}
    />
  );
};

export const renderCommission = (_, { freightEtsngCode, countryCode, loadStationCode, destStationCode }: TariffCountryRangeInfo) => {
  return (
    <Observer>
      {() => {
        const isActiveName = `${expeditionGroupName}-isActive-${freightEtsngCode}-${loadStationCode}-${destStationCode}-${countryCode}`;
        const isActive = BidRegistryCommercialStore.flatCountryPaidTree[isActiveName];
        const name = `${expeditionGroupName}-commission-${freightEtsngCode}-${loadStationCode}-${destStationCode}-${countryCode}`;

        return (
          <FieldInputNumber
            rules={[{ required: isActive, message: 'Укажите ставку' }]}
            onChange={(value) => BidRegistryCommercialStore.changeFieldValueFlatCountryPaidTree(name, value)}
            name={name}
            formatter={(value) => `${Number(value.toString().replace(' ', '')).toLocaleString('ru')}`}
            step={100}
            min={0}
            decimalSeparator={'.'}
          />
        );
      }}
    </Observer>
  );
};

export const renderNdsType = (_, { freightEtsngCode, countryCode, loadStationCode, destStationCode }: TariffCountryRangeInfo) => {
  return (
    <Observer>
      {() => {
        const isActiveName = `${expeditionGroupName}-isActive-${freightEtsngCode}-${loadStationCode}-${destStationCode}-${countryCode}`;
        const isActive = BidRegistryCommercialStore.flatCountryPaidTree[isActiveName];
        const name = `${expeditionGroupName}-ndsType-${freightEtsngCode}-${loadStationCode}-${destStationCode}-${countryCode}`;

        return (
          <FieldSelect
            rules={[{ required: isActive, message: 'Укажите тип НДС' }]}
            name={name}
            options={companyStore.nds.options}
            style={NDS_SELECT_STYLE}
            onChange={(value) => BidRegistryCommercialStore.changeFieldValueFlatCountryPaidTree(name, value)}
          />
        );
      }}
    </Observer>
  );
};

export const renderNds = (_, { freightEtsngCode, countryCode, loadStationCode, destStationCode }: TariffCountryRangeInfo) => {
  return (
    <Observer>
      {() => {
        const commissionKey = `${expeditionGroupName}-commission-${freightEtsngCode}-${loadStationCode}-${destStationCode}-${countryCode}`;
        const ndsTypeKey = `${expeditionGroupName}-ndsType-${freightEtsngCode}-${loadStationCode}-${destStationCode}-${countryCode}`;
        const priceWithoutNds = BidRegistryCommercialStore.flatCountryPaidTree[commissionKey];
        const ndsType = BidRegistryCommercialStore.flatCountryPaidTree[ndsTypeKey];
        const result = companyStore.nds.getNds(ndsType, priceWithoutNds);

        return <>{!!result ? toLocalString(result) : '0'}</>;
      }}
    </Observer>
  );
};

export const renderCommissionWithNds = (_, { freightEtsngCode, countryCode, loadStationCode, destStationCode }: TariffCountryRangeInfo) => {
  return (
    <Observer>
      {() => {
        const commissionKey = `${expeditionGroupName}-commission-${freightEtsngCode}-${loadStationCode}-${destStationCode}-${countryCode}`;
        const ndsTypeKey = `${expeditionGroupName}-ndsType-${freightEtsngCode}-${loadStationCode}-${destStationCode}-${countryCode}`;
        const priceWithoutNds = BidRegistryCommercialStore.flatCountryPaidTree[commissionKey];
        const ndsType = BidRegistryCommercialStore.flatCountryPaidTree[ndsTypeKey];
        const result = companyStore.nds.getPriceWithNds(ndsType, priceWithoutNds);

        return <>{!!result ? toLocalString(result) : '0'}</>;
      }}
    </Observer>
  );
};

const NDS_SELECT_STYLE = { width: 100 };

const toLocalString = (value: number) => value.toLocaleString('ru', { maximumFractionDigits: 2 });
